


































































































































































































































































































































































































































































































import Fragment from "../../Fragment.vue";
import ApiPaginator from "./ApiPaginator.vue";
import IconButton from "../../IconButton.vue";
import Tooltip from "../../Tooltip.vue";

import Vue, { PropType } from "vue";
import ApiSearch, { ApiSearchColumn } from "../models/ApiSearch";
import { getAltRow, getHeaderClasses, getFieldClasses } from "@/utils/classes";
import { PaginatedPager } from "@/models/Pager";
import { FilteringModel, SortingModel, SortOrder } from "@/services/filtering";

import ServerSideSearchApi from "../services/ServerSideSearchApi";
import { debounce } from "debounce";
import eventBus from "@/services/eventBus";
import AllCheckUncheck from "@/components/AllCheckUncheck.vue";
import DateInput from "@/components/DateInput.vue";
import { groupBy } from "@/utils/array";

interface Data {
  /** Local API instance */
  api: ServerSideSearchApi;
  /** CurrentPage, PageSize, SearchText, Filter, Sorting container */
  apiSearch: ApiSearch;
  /** Displayed items */
  items: object[];
  /** Total item count */
  totalItems: number;
  /** Displayed columns mapped as "ApiSearchColumn" */
  localColumns: ApiSearchColumn[];
  /** Dispaly in Table (or List) view - @default true */
  isTableView: boolean;
  /** Display filters - @default false */
  isFilterVisible: boolean;

  loading: boolean;
  eventListenersAdded: boolean;
  hasFiltering: boolean;
  hasSorting: boolean;
}

interface Methods {
  showItem(item: any): boolean;
  refreshClick(): void;
  resetFilterClick(): void;
  resetSortClick(): void;

  /** Load items */
  changeHandler(): Promise<void>;
  pagerChanged(pager: PaginatedPager): void;

  getFieldOrder(field: string): SortOrder;
  toggleSort(field: string): void;

  getHeaderClasses(column: ApiSearchColumn): string;
  getFieldClasses(column: ApiSearchColumn): string;
  getAltRow(i: number): string;
  toggleExtraFilterVisibility(): void;
  addFilterWIdthEventListeners(): void;
}

interface Computed {
  hasCustomTable: boolean;
  hasCustomTableBody: boolean;
  hasDetails: boolean;
  hasListView: boolean;
  hasFilters: boolean;
  hasViews: boolean;
  hasNoItems: boolean;
  hasHeaders: boolean;
  hasComponentNextToCreateButton: boolean;
  hasBottom: boolean;
  hasGrouping: boolean;
  grouppedRows: any[];
}

interface Props {
  /** Page title */
  title: string;

  //! Search API
  /** API path */
  url: string;
  /** Selectable page sizes - @default [10,20,50,100] */
  baseSizes: number[];
  /** Debounce delay - @default 0 */
  delay: number;

  hideOnEmpty: boolean;

  openDetailsByDefault: boolean;

  //! Top Buttons
  /** Display "Create" button with the given title */
  create: string;
  /** Display "Remove Filter" button - @default true */
  resetFilter: boolean;
  /** Display "Remove Sort" button - @default true */
  resetSort: boolean;
  /** Display "Search Box" - @default true */
  search: boolean;
  /** Displayed Placeholder inside the Search Box @default "Keresés" */
  searchPlaceholder: string;
  /** Display "Filter" button - @default false
   * @todo passed props
   * @todo default view */
  showFilter: boolean;
  /** Display "List/Table view" buttons - @default false
   * @todo passed props
   * @todo default view */
  multiView: boolean;
  /** Display "Refresh" button - @default true */
  refresh: boolean;
  /** Display divider - @default true */
  showDivider: boolean;
  /** Display extra date picker - @default false */
  showExtraDateFilter: boolean;

  //! Table
  /** Raw columns - @default [] */
  columns: ApiSearchColumn[];
  /** List should be filterable - @default true */
  filterable: boolean;
  /** List should be sortable - @default true */
  sortable: boolean;
  /** Same width for every col - @default false */
  fixedTable: boolean;
  /** Maximum column width for every col - @default 200 */
  maxColWidth: number;
  /** Replace "details" prop name - @default "details" */
  detailsField: string;
  /** Display the whole content on hover - @default false */
  displayTitles: boolean;
  hasPager: boolean;
  exclusiveItems: number[];
  /** Extra filters passed through as a prop */
  extraFilter: FilteringModel | undefined;
  multiselect: boolean;
  /** Open all details */
  openDetails: boolean;
  /** Set alternative backgorund to tr and th elements */
  altBackground: boolean;
  /** It doesn't load autamaticaly when page open or searchText is changed */
  autoLoad: boolean;
  /** Extra object passed through as a prop */
  apiSearchExtraFilter: any;
  //refresh event
  refreshEvent: string;
  /** For setting the unique eventbus name **/
  name: string;
  /** Filter field grows with text input */
  filterFieldAutoGrow: boolean;
  /** Insert details as it is, instead of wrapping in a tr-td */
  detailsRaw: boolean;
  /** Progress icon overlay instead of removing rows and displaying the progress icon */
  overlayProgressIcon: boolean;

  /** Groupping column */
  groupColumn: string | null;
  searchTextOnEnter: boolean;
  autoHideFilterSortButtons: boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    Fragment,
    ApiPaginator,
    IconButton,
    Tooltip,
    AllCheckUncheck,
    DateInput,
  },

  props: {
    refreshEvent: String,
    apiSearchExtraFilter: {},
    autoLoad: { type: Boolean, default: true },
    altBackground: { type: Boolean, default: false },
    openDetails: { type: Boolean, default: false },
    searchTextOnEnter: { type: Boolean, default: false },
    autoHideFilterSortButtons: { type: Boolean, default: false },

    title: String,

    hideOnEmpty: { type: Boolean, default: false },

    url: String,
    baseSizes: {
      type: Array as () => number[],
      default: () => [10, 20, 50, 100],
    },
    delay: { type: Number, default: 0 },

    openDetailsByDefault: { type: Boolean, default: false },

    create: String,
    resetFilter: { type: Boolean, default: true },
    resetSort: { type: Boolean, default: true },
    search: { type: Boolean, default: true },
    searchPlaceholder: { type: String, default: "Keresés" },
    showFilter: { type: Boolean, default: false },
    multiView: { type: Boolean, default: false },
    refresh: { type: Boolean, default: true },
    showDivider: { type: Boolean, default: true },
    showExtraDateFilter: { type: Boolean, default: false },
    filterFieldAutoGrow: { type: Boolean, default: true },
    detailsRaw: { type: Boolean, default: false },
    overlayProgressIcon: { type: Boolean, default: false },
    groupColumn: { type: String, default: null },

    columns: {
      type: Array as () => ApiSearchColumn[],
      default: () => new Array<ApiSearchColumn>(),
    },

    filterable: { type: Boolean, default: true },
    multiselect: { type: Boolean, default: false },
    sortable: { type: Boolean, default: true },
    fixedTable: { type: Boolean, default: false },
    extraFilter: { type: Object as () => FilteringModel, default: undefined },
    maxColWidth: { type: Number, default: 200 },
    detailsField: { type: String, default: "details" },
    displayTitles: { type: Boolean, default: false },
    hasPager: { type: Boolean, default: true },
    name: { type: String, default: "api-search-page" },
    exclusiveItems: {
      type: Array as () => number[],
      default: () => {
        return [];
      },
    },
  },

  data: (): Data => ({
    api: new ServerSideSearchApi(),
    apiSearch: new ApiSearch(),
    items: [],
    totalItems: 0,
    localColumns: [],
    isTableView: true,
    isFilterVisible: false,
    loading: false,
    eventListenersAdded: false,
    hasFiltering: false,
    hasSorting: false,
  }),

  async beforeMount() {
    /** Create new ApiSearchColumn from params */
    this.localColumns = this.$props.columns.map((c: unknown) => new ApiSearchColumn(c as Partial<ApiSearchColumn>));

    // TODO: default filter support
    let filter = this.$props.filterable
      ? new FilteringModel().values(
          this.localColumns
            .filter((x) => x.filterable && !x.multiselect)
            .map((x) => ({ field: x.field ?? "" }))
            .filter((x) => !!x.field)
        )
      : undefined;

    const extraFilterCols = this.localColumns.filter((x) => x.extraFilterValue);
    extraFilterCols.forEach((e) => {
      if (filter) {
        filter = filter.value(e.field ?? "", e.extraFilterValue ?? "");
      }
    });

    this.localColumns
      .filter((x) => x.multiselect)
      .forEach((e) => {
        if (filter) {
          filter = filter.multiSelect(
            e.multiselectField ?? "",
            e.multiselectOptions ?? [],
            typeof e.multiselectSelect === "number" && e.multiselectSelect > 1
              ? [e.multiselectSelect]
              : (e.multiselectSelect?.constructor === Array ? e.multiselectSelect : e.multiselectOptions?.map((x) => x.value)) 
          );
        }
      });

    this.localColumns
      .filter((x) => x.range)
      .forEach((e) => {
        if (filter) {
          filter = filter.range(e.field ?? "");
        }
        // console.log(e);
      });

    // TODO: default filter support
    const sort = this.sortable
      ? new SortingModel().capitalize().fields(
          this.localColumns
            .filter((x) => x.sortable)
            .map((x) => ({ field: x.field ?? "", text: x.title ?? "" }))
            .filter((x) => !!x.field && !!x.text)
        )
      : undefined;

    const old = this.apiSearch;
    /** Init ApiSearch */
    this.apiSearch = new ApiSearch({
      pageSize: this.$props.hasPager ? this.$props.baseSizes[0] : 1000,
      filter: filter,
      extraFilter: old?.extraFilter ?? this.apiSearchExtraFilter,
      sort: sort,
      searchText: "",
    });

    /** Load first page, then apply debounce() */
    if (this.$props.url != "") {
      await this.changeHandler(); // api call to the backend
    }

    this.changeHandler = debounce(this.changeHandler, this.$props.delay);

    eventBus.$on(`${this.$props.name}:set-items`, (items) => {
      this.items = items;
    });

    eventBus.$on(`${this.$props.name}:reload`, () => {
      this.changeHandler();
    });

    if (this.refreshEvent)
      eventBus.$on(this.refreshEvent, () => {
        this.changeHandler();
      });

    eventBus.$on(`${this.$props.name}:close-details`, () => {
      this.items.forEach((item: unknown) => {
        Object.defineProperty(item, this.$props.detailsField, {
          value: false,
          writable: true,
          enumerable: true,
          configurable: true,
        });
      });
    });
  },

  watch: {
    columns() {
      /** Create new ApiSearchColumn from params */
      this.localColumns = this.$props.columns.map((c: unknown) => new ApiSearchColumn(c as Partial<ApiSearchColumn>));

      // TODO: default filter support
      let filter = this.$props.filterable
        ? new FilteringModel().values(
            this.localColumns
              .filter((x) => x.filterable && !x.multiselect)
              .map((x) => ({ field: x.field ?? "" }))
              .filter((x) => !!x.field)
          )
        : undefined;

      const extraFilterCols = this.localColumns.filter((x) => x.extraFilterValue);
      extraFilterCols.forEach((e) => {
        if (filter) {
          filter = filter.value(e.field ?? "", e.extraFilterValue ?? "");
        }
      });

      this.localColumns
        .filter((x) => x.multiselect)
        .forEach((e) => {
          if (filter) {
            filter = filter.multiSelect(
              e.multiselectField ?? "",
              e.multiselectOptions ?? [],
              e.multiselectOptions?.map((x) => x.value)
            );
          }
        });

      // TODO: default filter support
      const sort = this.sortable
        ? new SortingModel().capitalize().fields(
            this.localColumns
              .filter((x) => x.sortable)
              .map((x) => ({ field: x.field ?? "", text: x.title ?? "" }))
              .filter((x) => !!x.field && !!x.text)
          )
        : undefined;

      const old = this.apiSearch;
      /** Init ApiSearch */
      this.apiSearch = new ApiSearch({
        pageSize: this.$props.hasPager ? this.$props.baseSizes[0] : 1000,
        filter: filter,
        extraFilter: old?.extraFilter ?? this.apiSearchExtraFilter,
        sort: sort,
        searchText: "",
        stopFilterLoad: true,
        stopSortLoad: true,
      });
    },
    exclusiveItems() {
      this.changeHandler();
    },
    /** Load items on Filter change */
    "apiSearch.searchText": {
      deep: true,
      handler(_: FilteringModel | undefined, old: FilteringModel | undefined) {
        // block refresh while it's undefined
        if (!old) return;
        if (!this.autoLoad) return;
        if (this.searchTextOnEnter) return;
        this.changeHandler();
      },
    },

    /** Load items on Filter change */
    "apiSearch.filter": {
      deep: true,
      handler(_: FilteringModel | undefined, old: FilteringModel | undefined) {
        // block refresh while it's undefined
        if (!old) return;
        if (this.apiSearch.stopFilterLoad) {
          this.apiSearch.stopFilterLoad = false;
          return;
        }
        this.changeHandler();
      },
    },

    /** Load items Sort change */
    "apiSearch.sort": {
      deep: true,
      handler(_: SortingModel | undefined, old: SortingModel | undefined) {
        // block refresh while it's undefined
        if (!old) return;
        if (this.apiSearch.stopSortLoad) {
          this.apiSearch.stopSortLoad = false;
          return;
        }
        this.changeHandler();
      },
    },
  },

  created() {
    eventBus.$on(`${this.$props.name}:get-items`, () => {
      this.$emit("items", this.items);
    });
    eventBus.$on(`${this.$props.name}:set-items`, (items) => {
      this.items = items;
    });

    eventBus.$on(`${this.$props.name}:reload`, () => {
      this.changeHandler();
    });

    eventBus.$on(`${this.$props.name}:close-details`, () => {
      this.items.forEach((item: unknown) => {
        Object.defineProperty(item, this.$props.detailsField, {
          value: false,
          writable: true,
          enumerable: true,
          configurable: true
        });
      });
    });
  },

  methods: {
    addFilterWIdthEventListeners(){
      if (this.filterFieldAutoGrow && !this.eventListenersAdded){

      this.eventListenersAdded = true;
      const inputs = document.querySelectorAll('.filter-field input');

      inputs.forEach(i => {
        let defaultWidth = 0; 
        const element = (i as any);

        const dummySpan = document.createElement('span');
        dummySpan.style.position = 'absolute';
        dummySpan.style.left = '-99999999px';
        dummySpan.style.fontSize = '12px';
        dummySpan.classList.add('maxWidth');
        element.parentNode.appendChild(dummySpan);

        i.addEventListener('input', () => {
          if (defaultWidth == 0){
            defaultWidth = i.clientWidth;
          }

          dummySpan.innerHTML = element.value.replace(/\s/g, '&nbsp;');
          const newWidth = dummySpan.offsetWidth;

          if (newWidth > defaultWidth){
            element.style.width = newWidth + 'px';
          }
        });
      });
    }
    },

    showItem(item: any) {
      if (this.$props.exclusiveItems.length < 0 || (item?.id ?? false)) {
        return true;
      } else {
        if (this.$props.exclusiveItems.includes(Number(item.id))) {
          return false;
        } else return true;
      }
    },

    refreshClick(): void {
      eventBus.$emit("api-pager:set-page", 1);
      this.changeHandler();
    },

    resetFilterClick(): void {
      this.apiSearch.resetFiltering();
      this.$emit("reset-filtering");
    },

    resetSortClick(): void {
      this.apiSearch.resetSorting();
      this.$emit("reset-sorting");
    },

    async changeHandler(): Promise<void> {
      this.$emit("before-refresh");
      //! Can be cancelled
      const apiSearch = eventBus.$emit("refreshing", this.apiSearch);
      console.log("refreshing");
      this.loading = true;

      this.hasFiltering = false;
      if (this.apiSearch.filter){
        if (this.apiSearch.searchText){
          this.hasFiltering = true;
        }

        for (const [key, value] of Object.entries(this.apiSearch.filter?.filters)) {
          if(value.hasValue()){
            this.hasFiltering = true;
          }
        }
      }

      this.hasSorting = false;
      if (this.apiSearch.sort){
        this.hasSorting = this.apiSearch.sort.definitions.filter(x => x.order != "None").length > 0;
      }

      try {
        const result = await this.api.post(this.$props.url, this.apiSearch);
        // console.log(result);
        if (result) {
          this.totalItems = result.totalCount;
          this.items = this.hasDetails
            ? result.currentPage.map((i) => {
                // Add custom detailsField prop
                Object.defineProperty(i, this.$props.detailsField, {
                  value: this.$props.openDetailsByDefault,
                  writable: true,
                  enumerable: true,
                  configurable: true,
                });

                return i;
              })
            : result.currentPage;
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      } finally {
        console.log(this.apiSearch);
        this.loading = false;
      }

      this.$emit("itemCount", this.items?.length ?? 0);
      this.$emit("items", this.items);
      eventBus.$emit("refreshed", this.apiSearch);

      this.addFilterWIdthEventListeners();
    },

    pagerChanged(pager: PaginatedPager): void {
      if (
        // block double API calls from: search change -> pager update
        pager.totalItems === 0 ||
        (this.apiSearch.currentPage === pager.currentPage && this.apiSearch.pageSize === pager.pageSize)
      )
        return;

      this.apiSearch.currentPage = pager.currentPage;
      this.apiSearch.pageSize = pager.pageSize;
      this.changeHandler();
    },

    getFieldOrder(field: string): SortOrder {
      return this.apiSearch.sort?.definitions.find((x) => x.field === field)?.order ?? SortOrder.None;
    },

    toggleSort(field: string): void {
      this.apiSearch.sort?.toggle(field);
    },

    getHeaderClasses,
    getFieldClasses,
    getAltRow,

    toggleExtraFilterVisibility() {
      this.isFilterVisible = !this.isFilterVisible;
      this.$emit("efilter-toggle", this.isFilterVisible);
    },
  },

  computed: {
    hasGrouping(): boolean {
      return this.$props.groupColumn != null && this.$props.groupColumn != '';
    },

    grouppedRows(): any[] {
      const ret: any[] = [];

      const groupped = groupBy(this.items, this.$props.groupColumn);
      for (const group in groupped) {
        ret.push(Vue.observable({expanded: true, group: group, items: groupped[group]}))
      }

      console.log(ret);

      return ret;
    },

    hasNoItems(): boolean {
      return (this.items?.length ?? 0) < 1;
    },

    hasCustomTable(): boolean {
      return !!this.$scopedSlots.customTable || !!this.$slots.customTable;
    },

    hasCustomTableBody(): boolean {
      return !!this.$scopedSlots.customTableBody || !!this.$slots.customTableBody;
    },

    hasDetails(): boolean {
      return !!this.$scopedSlots.details || !!this.$slots.details;
    },

    hasListView(): boolean {
      return !!this.$scopedSlots.listView || !!this.$slots.listView;
    },

    hasFilters(): boolean {
      return !!this.$scopedSlots.filters || !!this.$slots.filters;
    },

    hasViews(): boolean {
      return !!this.$scopedSlots.views || !!this.$slots.views;
    },

    hasHeaders(): boolean {
      return !!this.$scopedSlots.headers || !!this.$slots.headers;
    },

    hasComponentNextToCreateButton(): boolean {
      return !!this.$scopedSlots.componentNextToCreateButton || !!this.$slots.componentNextToCreateButton;
    },

    hasBottom(): boolean {
      return !!this.$scopedSlots.bottom || !!this.$slots.bottom;
    },
  },
});
