










































































































































































































































































































































































































































import Vue from "vue";
import { ConfirmationMixin } from "@/mixins/confirmation";

import itemStore, { InvoiceItemStore } from "../../invoiceItems/services/store";
import flowStore, { FlowStatus, InvoiceFlowStore } from "../services/InvoiceFlowStore";
import {
  IncomingInvoiceValidator,
  IncomingInvoiceWarningValidator,
} from "../../../services/validators/incomingInvoice";

import InvoiceItemSearch from "../../invoiceItems/views/InvoiceItemSearch.vue";
import InvoiceItemsTable from "../../invoiceItems/views/InvoiceItemsTable.vue";
import permStore, { InvoicePermissions, PermissionStore } from "@/services/permissions";
import configuration, { AppConfiguration } from "@/models/Configuration";
import eventBus from "@/services/eventBus";

import FileBrowser from "@/modules/excel-test/components/FileBrowser.vue";
import { InvoiceItem, InvoiceItemType, InvoiceRelationType } from "@/modules/invoiceItems/models";
import AcceptorsView from "./AcceptorsView.vue";
import ExcelFileBrowser from "@/modules/excel-test/components/ExcelFileBrowser.vue";
import { Taxes } from "@/modules/generalIngoingInvoice/models/TaxAndVatItem";
import DpaymView from "../components/DpaymView.vue";
import SetIncorrectInvoice from "../components/SetIncorrectInvoice.vue";
import DebitsTable from "../../debitPayment/views/DebitsTable.vue";
import store, { DebitPaymentStore } from "../../debitPayment/services/DebitPaymentStore";
import SupplierComplaints from "@/modules/invoiceItems/components/SupplierComplaints.vue";
import StoreInPaymentDates from "../components/StoreInPaymentDates.vue";
import DifferingDeadlineWarnings from "@/modules/invoiceItems/components/DifferingDeadlineWarnings.vue";
import InvoiceItemDifferences from "./InvoiceItemDifferences.vue";
import { SupplierComplaintDetails, supplierComplaintDetailsDefaultProps } from "@/modules/invoiceItems/models/index";
import { isIntrastatPartner } from "@/modules/generalIngoingInvoice/models/InvoiceDetails";

import ItemsTypeSwitch from "@/modules/canNet/views/ItemsTypeSwitch.vue";
import CarPostCalcView from "../../postCalc/views/CarPostCalcView.vue";
import Confirmation from "../../../components/Confirmation.vue";
import http, { handleApiResponse } from "@/services/http";
import AccountingWindow from "../components/AccountingWindow.vue";
import ApiResponse from "@/models/ApiResponse";
import IncomingInvoiceAccItem from "../models/IncomingInvoiceAccItem";
import InvoiceTemplateCreator from "../components/InvoiceTemplateCreator.vue";
import InvoiceImportItem from "@/modules/generalIngoingInvoice/models/InvoiceImportItem";

const controlPermissionGranted = (store: InvoiceFlowStore) => {
  const isRequestDelegated = store.acceptState.controllRequests.some((x) => x.isFFReq && x.current && !x.accepted) || (permStore.flowPermissions.includes("IncomingInvoice:FulFillControlling") && permStore.isAllInOneRecorder);
  console.log(permStore.isAllInOneRecorder);
  return isRequestDelegated;
};

const acceptPermissionGranted = (store: InvoiceFlowStore) => {
  const isRequestDelegated = store.acceptState.approvalRequests.some((x) => x.isFFReq && x.current && !x.accepted) || (permStore.flowPermissions.includes("IncomingInvoice:FulFillAccepting") && permStore.isAllInOneRecorder);
  return isRequestDelegated;
};

const savePermissionGranted = (store: InvoiceFlowStore) => {
  return controlPermissionGranted(store) || acceptPermissionGranted(store);
};

interface Data {
  configuration: AppConfiguration;
  store: DebitPaymentStore;
  permStore: PermissionStore;
  itemStore: InvoiceItemStore;
  flowStore: InvoiceFlowStore;
  filterShow: boolean;
  filterShowExcel: boolean;
  delegateVisible: boolean;
  sendBackVisible: boolean;
  downPayVisible: boolean;
  notesEnabled: boolean;

  saveConfirmed: boolean;
  showSaveConfirmation: boolean;
  differencesVisible: boolean;

  dpaymWindowTitle: string;
  isSaving: boolean;
  carPostCalcViewVisible: boolean;
  
  showAccounting: boolean;
  showIntrastatRectingWarning: boolean;

  vatPeriodValidationError: string;
  templateVisible: boolean;
  isNavItemsVisible: boolean;
}

interface Methods {
  delegateToToleranceApprover(): void;
  sendBack(): void;
  createRoundingItem(): void;
  update(): void;
  approve(): void;
  controlled(): void;
  toggleInverseVatSummary(): void;
  setDifferencesVisible(e: boolean): void;
  toleranceDelegate(): void;
  setDownPayVisibleWithTargetItem(e: InvoiceItem): void;
  setDownPayVisibleWithOutTargetItem(): void;
  closeDownPayVisible(): void;
  setSaving(value: boolean): void;
  showCarPostCalcView(): void;
  generalRectItemNotFilledMessage(): string;
  generalRectItemDatesNotFilledMessage(): string;
  itemValueDiffersFromSupplierComplaintValue(item: any): boolean;
  itemValueDiffersFromSupplierComplaintValueForRectify(item: any): boolean;
  newValueForRectify(item: InvoiceItem): number;
  confirmationTextForRectify(item: InvoiceItem): string;
  checkDebit(): void;
  checkDebitForRectify(): void;
  preAccount(): void;
  intrastatRectingApprove(): void;
  eventEmitter(): void;
  next(): void;
}

interface Computed {
  stockToleranceViolated: string;
  validationError: string;
  validationWarning: string;
  canControl: boolean;
  hasControlPermission: boolean;
  canAccept: boolean;
  canSave: boolean;
  canValidatedAccept: boolean;
  canWarningValidatedAccept: boolean;
  isPageDisabled: boolean;
  isIntrastatNeeded: boolean;
  generalRectItemNotFilled: boolean;
  generalRectItemVatEqualsZero: boolean;
  generalRectItemDatesNotFilled: boolean;
  isRectIntrastat: boolean;
  vatPeriodValidation: boolean;
  isNavItems: boolean;
  navItems: InvoiceImportItem[];
  isNext: boolean;
}

export default ConfirmationMixin.extend<Data, Methods, Computed, {}>({
  components: {
    InvoiceItemSearch,
    InvoiceItemsTable,
    FileBrowser,
    AcceptorsView,
    ExcelFileBrowser,
    DpaymView,
    SetIncorrectInvoice,
    DebitsTable,
    //SupplierComplaints,
    // StoreInPaymentDates,
    DifferingDeadlineWarnings,
    InvoiceItemDifferences,
    ItemsTypeSwitch,
    CarPostCalcView,
    Confirmation,
    AccountingWindow,
    InvoiceTemplateCreator,
  },

  props: {
    invoiceID: { type: Number },
    supplierID: { type: Number },
    defaultTaxID: { type: Number },
    currency: { type: String },
  },

  data: () => ({
    configuration,
    store,
    permStore,
    itemStore,
    flowStore,
    filterShowExcel: false,
    filterShow: false,
    delegateVisible: false,
    sendBackVisible: false,
    downPayVisible: false,
    notesEnabled: false,

    saveConfirmed: false,
    showSaveConfirmation: false,
    differencesVisible: false,

    dpaymWindowTitle: "",
    isSaving: false,
    carPostCalcViewVisible: false,
    debitPaymentsToChange: [{ ...supplierComplaintDetailsDefaultProps }, 1],

    showAccounting: false,
    showIntrastatRectingWarning: false,
    vatPeriodValidationError: "",
    templateVisible: false,
    isNavItemsVisible: false,
  }),

  mixins: [ConfirmationMixin],

  async beforeMount() {
    window.scrollTo(0, 0);
    if (this.flowStore.users.length == 0) await this.flowStore.loadUsersWithTechRecord();
  },

  async mounted() {
    this.vatPeriodValidationError = await http.get<ApiResponse<string>>(`general-incoming-invoice/vat-period-validation/${Number(this.$route.params.invoiceId)}`).then(handleApiResponse);
  },

  computed: {
    isNext() {
      return this.flowStore.flowStatusRelations.length > 3;
    },

    navItems(){
        const itemIDs = this.itemStore.itemList.items.map(x => x.invoiceItemID);
        return this.flowStore.navItems.filter(x => !itemIDs.some(y => y == x.invoiceItemId));
    },

    vatPeriodValidation(){
      if (this.vatPeriodValidationError != "") {
        return true;
      }
      return false;
    },

    isRectIntrastat(){
      return this.flowStore.invoice.rectify && this.itemStore.itemList.items.some(x=>x.hasExistingIntrastatData)
    },

    isIntrastatNeeded(): boolean {
      const items = [...(this.itemStore.itemList.items as InvoiceItem[])];
      const isIntr =
        isIntrastatPartner(this.flowStore.invoiceDetails) &&
        items.filter((x) => (!x.isRoundingItem && x.hasStockDivision) || !x.isService).length > 0;
      return isIntr;
    },

    stockToleranceViolated() {
      if (this.flowStore.canApproveToleranceDifference) return "";
      if (this.flowStore.invoice.rectify || this.flowStore.invoice.dpaym) return "";

      const item = this.itemStore.itemList.items.find((x) =>
        x.isPriceToleranceViolated(configuration.sysParams.geniFulFillTolerance)
      );

      if (item) {
        return "Tolerancia szintet meghaladó eltérés, kérem kattintson a Tolerancia szint delegálás gombra.";
      }

      return "";
    },

    validationError() {
      const validator = new IncomingInvoiceValidator(this.flowStore.invoice, this.itemStore.itemList);
      return validator.validateItems();
    },

    validationWarning() {
      const validator = new IncomingInvoiceWarningValidator(this.flowStore.invoice, this.itemStore.itemList);
      return validator.validate();
    },

    canControl() {
      return controlPermissionGranted(this.flowStore);
    },

    hasControlPermission() {
      return this.permStore.hasInvoice([InvoicePermissions.FulFillControlling]);
    },

    canAccept() {
      return acceptPermissionGranted(this.flowStore);
    },

    canSave() {
      return savePermissionGranted(this.flowStore);
    },

    canValidatedAccept() {
      if (this.validationError) {
        return false;
      }

      //! if (this.permStore.hasFulFillAcceptingSuperuser()) {
      //!   return true;
      //! }

      if (this.flowStore.canApproveToleranceDifference) {
        return true;
      }

      // if (this.stockToleranceViolated) {
      //   return false;
      // }

      return acceptPermissionGranted(this.flowStore);
    },

    canWarningValidatedAccept() {
      return this.validationWarning.length < 1;
    },

    isPageDisabled() {
      return (
        this.isSaving ||
        !this.flowStore.isEditingEnabled ||
        !this.permStore.hasStockRelationRecording() ||
        !this.flowStore.flowStatusRelations.some((r) => r.statusId == FlowStatus.FinancialRecording && !r.deleted) ||
        this.flowStore.flowStatusRelations.some((r) => r.statusId == FlowStatus.StockRelationRecording && !r.deleted)
      );
    },

    generalRectItemDatesNotFilled() {
      const items = this.itemStore.itemList.items.filter((x) => x.isGeneralRectItem);
      if (
        items.every(
          (x) =>
            x.generalRectItemDetails.rectInvDate != "" &&
            x.generalRectItemDetails.rectInvDate != null &&
            x.generalRectItemDetails.rectShipDate != "" &&
            x.generalRectItemDetails.rectShipDate != null
        )
      ) {
        return false;
      }
      return true;
    },

    generalRectItemNotFilled() {
      if (this.flowStore.invoice.rectify) {
        if (this.itemStore.hasItems && this.itemStore.itemList.items.some((x) => x.isGeneralRectItem)) {
          const items = this.itemStore.itemList.items.filter((x) => x.isGeneralRectItem);
          if (
            this.flowStore.invoice.rectVatDate != null &&
            items.every(
              (x) =>
                x.generalRectItemDetails.rectInvNo != "" &&
                x.generalRectItemDetails.rectNetto != 0 &&
                (x.generalRectItemDetails.rectVat != null || x.generalRectItemDetails.rectVat != undefined)
            )
          ) {
            return false;
          }
          return true;
        }
      }
      return false;
    },

    generalRectItemVatEqualsZero() {
      if (this.flowStore.invoice.rectify) {
        if (this.itemStore.hasItems && this.itemStore.itemList.items.some((x) => x.isGeneralRectItem)) {
          const items = this.itemStore.itemList.items.filter((x) => x.isGeneralRectItem);
          if (this.flowStore.invoice.rectVatDate != null && items.some((x) => x.generalRectItemDetails.rectVat == 0)) {
            return true;
          }
          return false;
        }
      }
      return false;
    },

    isNavItems() {
        return this.flowStore.navItems.length > 0;
    },
  },

  methods: {
    next() {
      this.$router.push(`/general-incoming-invoice/flow/edit/${this.flowStore.invoice.id}/5`);
    },

    intrastatRectingApprove(){
      this.showIntrastatRectingWarning = !this.showIntrastatRectingWarning;
    },

    async preAccount(){
      this.showAccounting = !this.showAccounting;
      
      if(!this.showAccounting) return;


      const invoiceID = this.flowStore.invoice.id;
      this.flowStore.accountItems = await http.post<ApiResponse<IncomingInvoiceAccItem[]>>(`accounting/${invoiceID}/test-pre-accounting`)
      .then(handleApiResponse);
    },

    async checkDebit(){
      const loopItems = this.itemStore.itemList.items.filter((x) => x.isInSupplierComplaint && x.rectifiedType == "N");
      const handlerItems: any[] = [];
      if (loopItems.length > 0) {
        await Promise.all(loopItems.map(async (i) => {
          if (this.itemValueDiffersFromSupplierComplaintValue(i)) {
            const confirmed = await this.confirm(`${i.itemName} - A számlázott érték (${i.nettoValue} ${this.flowStore.invoice.invCurrency}) eltér a reklamációban elvárttól (${(i.supplierComplaintDetails.originalNetto - i.supplierComplaintDetails.debitAmount)} ${this.flowStore.invoice.invCurrency}), a kettő különbségére szeretne újabb terhelési értesítőt generálni? (${i.nettoValue - (i.supplierComplaintDetails.originalNetto - i.supplierComplaintDetails.debitAmount)} ${this.flowStore.invoice.invCurrency})`);
            const h = {
              itemId: i.invoiceItemID,
              debitId: i.supplierComplaintDetails.debitId,
              newValue: i.nettoValue - (i.supplierComplaintDetails.originalNetto - i.supplierComplaintDetails.debitAmount),
              generateNew: confirmed,
            }
            handlerItems.push(h);
          }
        }));
        if (handlerItems.length > 0) {
          await this.flowStore.generateNewDebitItem(handlerItems);
        }
      }
    },

    itemValueDiffersFromSupplierComplaintValue(i: any) {
      if (i.supplierComplaintDetails.debitAmount != 0 && i.nettoValue > (i.supplierComplaintDetails.originalNetto - i.supplierComplaintDetails.debitAmount)) {
        return true;
      }
      return false;
    },

    async checkDebitForRectify(){
      console.log("cdfr");
      const loopItems = this.itemStore.itemList.items.filter((x: any) => x.rectifiedType == "R" && x.supplierComplaintDetailsForRectify != null);
      console.log(loopItems);
      
      const handlerItems: any[] = [];
      if (loopItems.length > 0) {
        await Promise.all(loopItems.map(async (i: any) => {
          if (this.itemValueDiffersFromSupplierComplaintValueForRectify(i)) {
            const confirmed = await this.confirm(this.confirmationTextForRectify(i));
            const h = {
              itemId: i.invoiceItemID,
              debitId: i.supplierComplaintDetailsForRectify.debitId,
              newValue: this.newValueForRectify(i), //i.nettoValue - (i.supplierComplaintDetailsForRectify.originalNetto - i.supplierComplaintDetailsForRectify.debitAmount),
              generateNew: confirmed,
            }
            handlerItems.push(h);
          }
        }));
        if (handlerItems.length > 0) {
          await this.flowStore.generateNewDebitItem(handlerItems);
        }
      }
    },

    confirmationTextForRectify(i: InvoiceItem){
      if (configuration.onlyRectItem.enabled) {
        return `${i.itemName} - Az elvárt helyesbítés a reklamáció alapján ${i.supplierComplaintDetailsForRectify.debitAmount} ${this.flowStore.invoice.invCurrency}, de csak ${Math.abs(i.nettoValue)} ${this.flowStore.invoice.invCurrency} lett a számlán rögzítve. Szeretne új terhelési értesítőt generálni az eltérésre (${i.supplierComplaintDetailsForRectify.debitAmount - Math.abs(i.nettoValue)} ${this.flowStore.invoice.invCurrency})?`;
      }
      return `${i.itemName} - Az elvárt helyesbítés a reklamáció alapján ${i.supplierComplaintDetailsForRectify.debitAmount} ${this.flowStore.invoice.invCurrency}, de csak ${i.supplierComplaintDetailsForRectify.originalNetto - i.nettoValue} ${this.flowStore.invoice.invCurrency} lett a számlán rögzítve. Szeretne új terhelési értesítőt generálni az eltérésre (${i.supplierComplaintDetailsForRectify.debitAmount - (i.supplierComplaintDetailsForRectify.originalNetto - i.nettoValue)} ${this.flowStore.invoice.invCurrency})?`;
    },

    itemValueDiffersFromSupplierComplaintValueForRectify(i: any) {
      if (configuration.onlyRectItem.enabled) {
        if (i.supplierComplaintDetailsForRectify.debitAmount != 0 && ((i.nettoValue) + Math.abs(this.itemStore.itemList.items.find((x) => x.rectifiedType == "S" && x.invoiceItemID == (i.invoiceItemID-1))?.originalNettoValue ?? 0) > Math.abs(i.supplierComplaintDetailsForRectify.originalNetto - i.supplierComplaintDetailsForRectify.debitAmount))) {
          return true;
        }
      }
      else {
        if (i.supplierComplaintDetailsForRectify.debitAmount != 0 && i.nettoValue > (i.supplierComplaintDetailsForRectify.originalNetto - i.supplierComplaintDetailsForRectify.debitAmount)) {
          return true;
        }
      }
      return false;
    },

    newValueForRectify(i: InvoiceItem){
      if (configuration.onlyRectItem.enabled) {
        return (i.supplierComplaintDetailsForRectify.debitAmount - Math.abs(i.nettoValue));
      }
      else {
        return (i.nettoValue - (i.supplierComplaintDetailsForRectify.originalNetto - i.supplierComplaintDetailsForRectify.debitAmount));
      }
    },

    showCarPostCalcView() {
      this.carPostCalcViewVisible = !this.carPostCalcViewVisible;
    },

    generalRectItemNotFilledMessage() {
      if (this.generalRectItemNotFilled) {
        return "Általános helyesbítő adatok hiányoznak!";
      }
      return "";
    },

    generalRectItemDatesNotFilledMessage() {
      const items = this.itemStore.itemList.items.filter((x) => x.isGeneralRectItem);
      let msg = "Eredeti számla dátumok hiányoznak!";
      if (
        items.some((x) => x.generalRectItemDetails.rectInvDate == "" || x.generalRectItemDetails.rectInvDate == null)
      ) {
        msg = "Eredeti számla kelte nem lett megadva!";
      } else if (
        items.some((x) => x.generalRectItemDetails.rectShipDate == "" || x.generalRectItemDetails.rectShipDate == null)
      ) {
        msg = "Eredeti számla teljesítés dátuma nem lett megadva!";
      }
      return msg;
    },

    setSaving(value: boolean) {
      this.isSaving = value;
    },

    closeDownPayVisible() {
      this.downPayVisible = false;
    },

    setDownPayVisibleWithTargetItem(e: InvoiceItem) {
      this.flowStore.downPayTargetItem = e.invoiceItemID;
      this.downPayVisible = true;
      this.dpaymWindowTitle = `Tétel szintű előlegszámlák a(z) ${e.itemName} tételhez`;

      this.$nextTick(function() {
        eventBus.$emit("InvoiceGroupDimensionCreating", e.invoiceItemID);
      });
    },

    setDownPayVisibleWithOutTargetItem() {
      if (this.downPayVisible) {
        this.downPayVisible = false;
        return;
      }
      this.flowStore.downPayTargetItem = 0;
      this.downPayVisible = true;
      this.dpaymWindowTitle = "Minden előlegszámla";

      this.$nextTick(function() {
        eventBus.$emit("InvoiceGroupDimensionCreating", 0);
      });
    },

    async toleranceDelegate() {
      if (!this.canAccept || this.isPageDisabled || !this.stockToleranceViolated) return;
      await this.itemStore.save();

      // delegálás az eredeti jóváhagyónak és utalványozónak
      const delegated = await this.flowStore.toleranceDelegate();
      if (delegated) {
        this.$invoiceTaskUpdateHub.send("InvoiceTaskUpdated");
        await this.flowStore.loadAcceptState();
      }
    },

    setDifferencesVisible(e: boolean) {
      this.differencesVisible = e;
    },

    async delegateToToleranceApprover() {
      if (!this.canAccept || this.isPageDisabled || !this.stockToleranceViolated) return;
      await this.itemStore.save();

      // delegálás a megfelelő jóváhagyónak
      const delegated = await this.flowStore.delegateToToleranceApprover();
      if (delegated) {
        this.$invoiceTaskUpdateHub.send("InvoiceTaskUpdated");
        await this.flowStore.loadAcceptState();
      }
    },

    async sendBack() {
      this.sendBackVisible = this.delegateVisible = false;
      await this.flowStore.deleteFlowStatusRelation(FlowStatus.FinancialRecording);
      await this.flowStore.loadAcceptState();
      this.$invoiceTaskUpdateHub.send("InvoiceTaskUpdated");
      // this.$router.push(`/invoice-flow-task`);
    },

    createRoundingItem() {
      const prevRoundingItem = this.itemStore.itemList.items.find((x) => x.productName == InvoiceItemType.Rounding);
      if (prevRoundingItem) this.itemStore.itemList.remove(prevRoundingItem);

      const headNetto = this.flowStore.invoice.currNetto;
      const headTotal = this.flowStore.invoice.currTotal;
      const itemsNetto = !configuration.onlyRectItem.enabled
        ? this.itemStore.itemList.netto
        : this.itemStore.itemList.items
            .filter((i) => i.rectifiedType != "S")
            .reduce((acc, i) => (i.dpaymItem ? acc - i.nettoValue : acc + i.nettoValue), 0.0);
      const itemsBrutto = !configuration.onlyRectItem.enabled
        ? this.itemStore.itemList.brutto
        : this.itemStore.itemList.items
            .filter((i) => i.rectifiedType != "S")
            .reduce((acc, i) => (i.dpaymItem ? acc - i.bruttoValue : acc + i.bruttoValue), 0.0);
      
      const netto = headNetto - itemsNetto;
      const brutto = headTotal - itemsBrutto;
      
      if (headTotal === itemsBrutto && headNetto === itemsNetto) return;
      if (Math.abs(brutto) > configuration.sysParams.geniMaxRound || Math.abs(netto) > configuration.sysParams.geniMaxRound) {
        eventBus.$emit("warn", "A számla fej és a tételek Bruttó különbsége meghaladja a maximum kerekítési értéket.");
        return;
      }

      this.itemStore.addRoundingItem(netto, brutto, Taxes.TVHK);
    },

    async update() {
      this.isSaving = true;
      eventBus.$emit("invoice-items:saving", true);
      try {
        //await this.eventEmitter();
        //await this.flowStore.updateCarRelationItems();

        await this.flowStore.updateFlow(true, true);
        await this.itemStore.save();
        this.$emit("refresh-flow-history");
        const invoiceId = Number(this.$route.params.invoiceId) || 0;
        await this.flowStore.loadInvoiceWithoutBusy(invoiceId);
      } catch (error) {
        console.log(error);
      } finally {
        this.isSaving = false;
        eventBus.$emit("invoice-items:saving", false);
      }
    },

    async eventEmitter(){
      this.itemStore.itemList.items.forEach(element => {
        setTimeout(() => {
          eventBus.$emit(`AutoSaveCarRelations${element.invoiceItemID}`);
        }, 100);
      })
    },

    async approve() {
      this.isSaving = true;

      //áfa időszak
      const vatReturn = await this.flowStore.checkVatReturn(this.flowStore.invoice.vatDate, this.flowStore.invoice.id);
      if (vatReturn == -2) {
        eventBus.$emit("warn", "A számla olyan időszakba esik, amelyre már van megnyitva áfa bevallás, az áfa bevallást ezért frissítenie kell!");
      }
      else if (vatReturn == -1) {
        const confirmed = await this.confirm("A számla lezárt ÁFA időszakot érint, folytatja?");
        if (!confirmed) {
          this.isSaving = false;
          return;
        }
      }
      else if(vatReturn == 1) {
        this.flowStore.invoice.latecomer = true;
      }
      else if(vatReturn == 0) {
        this.flowStore.invoice.latecomer = false;
      }

      //van-e nyitott előlegszámla?
     
      const checkDpaym = await flowStore.checkDpaym(this.flowStore.invoice.id, this.flowStore.invoice.partner);
      if (!this.flowStore.invoice.rectify && !this.flowStore.invoice.dpaym && !this.flowStore.invoice.dpaymIncl && checkDpaym) {
        const confirmed = await this.confirm("A számla partneréhez tartozik nyitott előlegszámla! Biztos készre állítja a számlát?");
        if (!confirmed) {
          this.isSaving = false;
          return;
        }
      }

      //terhelési értesítő eltérés ellenőrzés - értékhelyesbítés nem számlázott stockra
      await this.checkDebit();

      //terhelési értesítő eltérés ellenőrzés - megállapodás értékhelyesbítés + helyesbítő számla bejelölve
      await this.checkDebitForRectify();

      //általános helyesbítő tétel 0 ÁFA ellenőrzés
      if (this.generalRectItemVatEqualsZero) {
        const confirmed = await this.confirm(
          `Az eredeti számla áfa tartalma valóban 0 ${this.flowStore.invoice.invCurrency} volt? Szeretné ezzel az értékkel lezárni a teljesítés igazolást?`
        );
        if (!confirmed) {
          this.isSaving = false;
          return;
        }
      }
      //await this.eventEmitter();
      //await this.flowStore.updateCarRelationItems();
      
      if (this.isNavItems){
        const itemIDs = this.itemStore.itemList.items.map(x => x.invoiceItemID);
        const noRelNavItems = this.flowStore.navItems.filter(x => !itemIDs.some(y => y == x.invoiceItemId) || x.invoiceItemId == 1);

        if (noRelNavItems.length > 0){
          const confirmed = await this.confirm(
          `Nincs minden NAV-ból importált tétel hozzárendelve számla tételhez. Folytatja?`
          );
          if (!confirmed) {
            this.isSaving = false;
            return;
          }
        }
      }

      await this.flowStore.updateFlow(true, true);
      eventBus.$emit("invoice-items:saving", true);
      try {
        if (!this.saveConfirmed && this.isIntrastatNeeded) {
          this.showSaveConfirmation = true;
          return;
        }

        await this.itemStore.save();
        await this.flowStore
          .updateFulFillAcceptRequestStatus(2)
          .then(async () => {
            const invoiceId = Number(this.$route.params.invoiceId);
            await this.flowStore.loadFlowStatusRelations(invoiceId);

            const autoApproved = this.flowStore.flowStatusRelations.some(
              (x) => x.statusId == FlowStatus.StockRelationRecording
            );
            //this.itemStore.updateInvGroupDimensions(Number(this.$route.params.invoiceId));
            if (autoApproved) return;

            this.$emit("update");
          })
          .then(() => this.$invoiceTaskUpdateHub.send("InvoiceTaskUpdated"));

        if (this.configuration.sysParams.isKanriEnabled) {
          await this.flowStore.kanriSync(Number(this.$route.params.invoiceId));
        }

        await this.flowStore.closeGpartsOrder();
        if (this.flowStore.invoice.letterOfCredit > 1) {
          await this.flowStore.generateViriForLetterOfCredit();
        }
        this.sendBackVisible = this.delegateVisible = false;
        this.$emit("refresh-flow-history");
      } finally {
        this.isSaving = false;
        eventBus.$emit("invoice-items:saving", false);
        this.$nextTick(() => (this.$refs.doneButton as any).$el.focus());
      }
    },

    async controlled() {
      await this.itemStore.save();
      await this.flowStore
        .updateFulFillControlRequestStatus(2)
        .then(() => this.$invoiceTaskUpdateHub.send("InvoiceTaskUpdated"));
      this.sendBackVisible = this.delegateVisible = false;
    },

    toggleInverseVatSummary() {
      eventBus.$emit("toggle-inv-vat-summary");
    },
  },
});
