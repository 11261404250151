















































































































































































































import Vue from "vue";
import debounce from "debounce";
import store, { PartnerStore } from "../services/PartnerStore";
import { ComboBox } from "@progress/kendo-vue-dropdowns";
import PartnerApi from "../services/PartnerApi";
import Partner, { PartnerResponse } from "../models/Partner";
import createPagerText from "@/utils/pagerText";
import configuration, { loadConfiguration } from "@/models/Configuration";
import type { AppConfiguration } from "@/models/Configuration";
import cuid from "cuid";

const focusNextElement = (el: Element) => {
  const universe = document.querySelectorAll("input, select, textarea");
  const list = Array.prototype.filter.call(universe, function(item) {
    return item.tabIndex >= "0";
  });
  const index = list.indexOf(el);
  const element = list[index + 1] || list[0];
  element.focus();
};

interface Data {
  store: PartnerStore;
  partners: Partner[];
  partnersCount: number;
  item: any;
  currentPageNumber: number;
  currentPageSize: number;
  filter: string;
  firstPageNumberButtonVisible: boolean;
  firstPageNumberButtonValue: number;
  secondPageNumberButtonVisible: boolean;
  secondPageNumberButtonValue: number;
  thirdPageNumberButtonVisible: boolean;
  thirdPageNumberButtonValue: number;
  fourthPageNumberButtonVisible: boolean;
  fourthPageNumberButtonValue: number;
  fifthPageNumberButtonVisible: boolean;
  fifthPageNumberButtonValue: number;
  observer: any;
  opened: boolean;
  tempSelected: any;
  configuration: AppConfiguration;
  cuid: string;
}

interface Methods {
  openNewPartnerInNewPage(): void;
  load(pageNumber: number, setPageNumbers?: boolean): void;
  loadPartners(pageNumber: number, setPageNumbers?: boolean): void;
  loadPartnersByName(
    partnerName: string,
    pageNumber: number,
    setPageNumbers?: boolean
  ): void;
  handlePartnerResponse(result: PartnerResponse, setPageNumbers: boolean): void;
  onClose(): void;
  searchChanged(event: any): void;
  searchClick(item: object): void;
  setCurrentPageNumber(pageNumber: number): void;
  setCurrentPageSize(pageSize: number): void;
  decrementPageNumber(): void;
  incrementPageNumber(): void;
  setPageNumbers(): void;
  setPartner(): void;
  afterClose(e: any): void;
}

interface Props {
  partnerId: number;
  isCustomsOffice: boolean;
  // Don't load if a firstPage is already loaded
  firstPage: any;
  // Get all/general Partners, not limited to GENI
  all: boolean;
  // limited to overPayments
  overSummary: boolean;
}

export default Vue.extend<Data, Methods, {}, Props>({
  props: {
    partnerId: Number,
    isCustomsOffice: Boolean,
    firstPage: Object,
    all: { type: Boolean, default: false },
    overSummary: { type: Boolean, default: false },
  },

  components: { "kendo-combobox": ComboBox },

  data: () => ({
    store,
    partners: [],
    partnersCount: 0,
    item: "",
    currentPageNumber: 0,
    currentPageSize: 10,
    filter: "",
    firstPageNumberButtonVisible: true,
    firstPageNumberButtonValue: 1,
    secondPageNumberButtonVisible: true,
    secondPageNumberButtonValue: 2,
    thirdPageNumberButtonVisible: true,
    thirdPageNumberButtonValue: 3,
    fourthPageNumberButtonVisible: true,
    fourthPageNumberButtonValue: 4,
    fifthPageNumberButtonVisible: true,
    fifthPageNumberButtonValue: 5,
    observer: null,
    opened: false,
    tempSelected: "",
    configuration,
    cuid: cuid()
  }),

  created() {
    this.load = debounce(this.load, 400);
  },

  mounted() {
    const wrap = document.getElementById(this.cuid)?.parentElement?.parentElement;
    wrap?.addEventListener("focusin", () => {
      this.opened = true;
    });

    wrap?.addEventListener("focusout", () => {
      this.opened = false;
      wrap?.classList.remove("k-state-focused");
      //focusNextElement(document.activeElement ?? new Element());
    });

    const select = wrap?.getElementsByClassName(
      "k-select"
    )[0] as HTMLSpanElement;
    select.addEventListener("click", () => {
      this.opened = !this.opened;
    });

    const trs = document.getElementById(this.cuid)?.getElementsByClassName("tr-r") as HTMLCollectionOf<
      HTMLDivElement
    >;
    for (let i = 0; i < trs.length; i++) {
      const tr = trs[i];
      tr.addEventListener("click", () => {
        setTimeout(() => {
          this.opened = false;
          document.body.focus();
          this.item = this.tempSelected;
        }, 1000);
      });
    }
  },

  async beforeMount() {
    if(!configuration.isLoaded){
      loadConfiguration();
    }
    if (this.firstPage) {
      this.partners = this.firstPage.partners;
      this.partnersCount = this.firstPage.count;
      this.currentPageNumber = 1;
    }

    if (this.partnerId && this.partnerId > 1) {
      await PartnerApi.get(this.partnerId).then((partner) => {
        if (this.partners.length > 0) {
          if (this.partners.length < this.currentPageSize) {
            this.partners.push(partner);
          } else {
            this.partners[0] = partner;
          }
        } else {
          this.partners.push(partner);
        }

        this.item = partner;
      });
    }

    if (this.item) {
      this.currentPageNumber = 1;
    } else if (!this.firstPage) {
      this.loadPartners(1, true);
    }
  },

  watch: {
    currentPageNumber() {
      this.setPageNumbers();
    },

    partnerId() {
      this.setPartner();
    },
  },

  computed: {
    items() {
      return this.partners;
    },

    footerText() {
      return createPagerText(
        this.partnersCount,
        this.currentPageNumber,
        this.currentPageSize
      );
    },

    maxPageNumber() {
      return Math.ceil(this.partnersCount / this.currentPageSize);
    },
  },

  methods: {
    afterClose(e) {
      setTimeout(() => {
        if (e?.value ?? null !== null) this.item = this.tempSelected;
        else {
          this.opened = true;
          this.item = { id: 1, name: "-" };
          this.$emit("update:partnerId", this.item.id);
          this.$emit("value", this.item);
        }
      }, 10);
    },

    openNewPartnerInNewPage() {
      this.$router.push(
        this.$route.fullPath.split("/")[0] + "/partners/create"
      );
    },

    load(pageNumber, setPageNumbers = false) {
      if (this.filter) {
        this.loadPartnersByName(this.filter, pageNumber, setPageNumbers);
      } else {
        this.loadPartners(pageNumber, setPageNumbers);
      }
    },

    async loadPartners(pageNumber, setPageNumbers = false) {
      if (this.$props.overSummary) {
        await PartnerApi.getAllOverSummary(
          pageNumber,
          this.currentPageSize,
          this.isCustomsOffice
        ).then((result) => this.handlePartnerResponse(result, setPageNumbers));
        return;
      }

      if (this.all) {
        await PartnerApi.getAllGeneral(
          pageNumber,
          this.currentPageSize,
          this.isCustomsOffice
        ).then((result) => this.handlePartnerResponse(result, setPageNumbers));
        return;
      }

      await PartnerApi.getAll(
        pageNumber,
        this.currentPageSize,
        this.isCustomsOffice
      ).then((result) => this.handlePartnerResponse(result, setPageNumbers));
    },

    async loadPartnersByName(partnerName, pageNumber, setPageNumbers = false) {
      if (this.$props.overSummary) {
        await PartnerApi.getAllByNameOverSummary(
          partnerName,
          pageNumber,
          this.currentPageSize,
          this.isCustomsOffice
        ).then((result) => this.handlePartnerResponse(result, setPageNumbers));
        return;
      }

      if (this.all) {
        await PartnerApi.getAllByNameGeneral(
          partnerName,
          pageNumber,
          this.currentPageSize,
          this.isCustomsOffice
        ).then((result) => this.handlePartnerResponse(result, setPageNumbers));
        return;
      }

      await PartnerApi.getAllByName(
        partnerName,
        pageNumber,
        this.currentPageSize,
        this.isCustomsOffice
      ).then((result) => this.handlePartnerResponse(result, setPageNumbers));
    },

    handlePartnerResponse(result, setPageNumbers) {
      this.partners = result.partners;
      this.partnersCount = result.count;

      if (setPageNumbers) {
        this.currentPageNumber = 1;
        this.setPageNumbers();
      }
    },

    setPartner() {
      let partnerId = this.partnerId;
      if(partnerId < 1)
        partnerId = 1

      this.item = this.partners.find((x) => x.id == partnerId);
      if (this.item) return;

      PartnerApi.get(partnerId).then((partner) => {
        if (this.partners.length > 0) {
          if (this.partners.length < this.currentPageSize) {
            this.partners.push(partner);
          } else {
            this.partners[0] = partner;
          }
        } else {
          this.partners.push(partner);
        }

        this.item = partner;
      });
    },

    onClose() {
      if (this.observer) {
        this.observer.disconnect();
        this.observer = null;
      }
    },

    searchChanged(event) {
      this.filter = event.filter.value;
      this.load(1, true);
    },

    async searchClick(item: object) {
      this.item = item;
      if (item) {
        this.tempSelected = item;
      }

      this.$emit("update:partnerId", this.item.id);
      this.$emit("value", this.item);

      focusNextElement(document.activeElement??new Element());

      this.opened = false;
    },

    setCurrentPageNumber(pageNumber) {
      if (this.currentPageNumber == pageNumber) return;

      this.currentPageNumber = pageNumber;

      this.load(this.currentPageNumber);
    },

    setCurrentPageSize(pageSize) {
      if (this.currentPageSize == pageSize) return;

      this.currentPageSize = pageSize;

      this.load(1, true);
    },

    decrementPageNumber() {
      if (this.currentPageNumber == 1) return;

      this.currentPageNumber--;

      this.load(this.currentPageNumber);
    },

    incrementPageNumber() {
      const maxPageNumber = Math.ceil(
        this.partnersCount / this.currentPageSize
      );
      if (this.currentPageNumber == maxPageNumber) return;

      this.currentPageNumber++;

      this.load(this.currentPageNumber);
    },

    setPageNumbers() {
      if (this.currentPageNumber == 0) return;

      const maxPageNumber = Math.ceil(
        this.partnersCount / this.currentPageSize
      );
      if (maxPageNumber <= 5) {
        this.firstPageNumberButtonVisible = false;
        this.secondPageNumberButtonVisible = false;
        this.thirdPageNumberButtonVisible = false;
        this.fourthPageNumberButtonVisible = false;
        this.fifthPageNumberButtonVisible = false;

        if (maxPageNumber >= 1) {
          this.firstPageNumberButtonVisible = true;
          this.firstPageNumberButtonValue = 1;
        }

        if (maxPageNumber >= 2) {
          this.secondPageNumberButtonVisible = true;
          this.secondPageNumberButtonValue = 2;
        }

        if (maxPageNumber >= 3) {
          this.thirdPageNumberButtonVisible = true;
          this.thirdPageNumberButtonValue = 3;
        }

        if (maxPageNumber >= 4) {
          this.fourthPageNumberButtonVisible = true;
          this.fourthPageNumberButtonValue = 4;
        }

        if (maxPageNumber == 5) {
          this.fifthPageNumberButtonVisible = true;
          this.fifthPageNumberButtonValue = 5;
        }
      } else {
        this.firstPageNumberButtonVisible = true;
        this.secondPageNumberButtonVisible = true;
        this.thirdPageNumberButtonVisible = true;
        this.fourthPageNumberButtonVisible = true;
        this.fifthPageNumberButtonVisible = true;

        if (maxPageNumber - this.currentPageNumber < 5) {
          this.firstPageNumberButtonValue = maxPageNumber - 4;
          this.secondPageNumberButtonValue = maxPageNumber - 3;
          this.thirdPageNumberButtonValue = maxPageNumber - 2;
          this.fourthPageNumberButtonValue = maxPageNumber - 1;
          this.fifthPageNumberButtonValue = maxPageNumber;
        } else {
          if (this.currentPageNumber < 5) {
            this.firstPageNumberButtonValue = 1;
            this.secondPageNumberButtonValue = 2;
            this.thirdPageNumberButtonValue = 3;
            this.fourthPageNumberButtonValue = 4;
            this.fifthPageNumberButtonValue = 5;
          } else {
            this.firstPageNumberButtonValue = this.currentPageNumber - 2;
            this.secondPageNumberButtonValue = this.currentPageNumber - 1;
            this.thirdPageNumberButtonValue = this.currentPageNumber;
            this.fourthPageNumberButtonValue = this.currentPageNumber + 1;
            this.fifthPageNumberButtonValue = this.currentPageNumber + 2;
          }
        }
      }
    },
  },
});
