
































































import StoreInPaymentDate from "@/modules/invoiceFlow/models/StoreInPaymentDate";
import flowStore, { InvoiceFlowStore } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import dayjs from "dayjs";
import Vue from "vue";

interface Data {
  flowStore: InvoiceFlowStore;
  partnerPayTerm: string;
}

export default Vue.extend({
  data: (): Data => ({
    flowStore,
    partnerPayTerm: "",
  }),

  beforeMount() {
    this.flowStore.storeInPaymentDates = [];
  },

  computed: {
    differentFromPartnerDeadline(): boolean {
      return (
        dayjs(this.flowStore.invoice.payTerm).format("YYYY-MM-DD") !=
        dayjs(this.flowStore.invoiceDetails.partnerPaymentDeadlineDate).format("YYYY-MM-DD")
      );
    },

    items(): StoreInPaymentDate[] {
      return this.flowStore.storeInPaymentDates.filter(
        (x) => x.payModeWasChanged && dayjs(x.payTerm).format("YYYY-MM-DD") != dayjs(this.flowStore.invoice.payTerm).format("YYYY-MM-DD")
      );
    },
  },
});
