import BankDocument from "../models/BankDocument";
import BankStatementEntity, {
  bankStatementDefaultProps,
} from "../models/BankStatement";
import BankStatementApi from "./BankStatementApi";
import busyIndication from "../../../utils/busyIndication";
import BankStatus, {
  bankStatusDefaultProps,
} from "@/modules/bankStatement/models/BankStatus";
import AccountType from "../../accountType/models/AccountType";
import AccountTypeApi from "../../accountType/services/AccountTypeApi";
import BankAccountStatus from "../models/BankAccountStatus";
import AccountSubtype from "../../accountType/models/AccountSubtype";
import PartBank from "@/modules/cashPoint/models/PartBank";
import BankStatementPict from "../models/BankStatementPict";
import BankInView from "@/modules/bankInView/models/BankInView";
import BankOutView from "@/modules/bankOutView/models/BankOutView";
import eventBus from "@/services/eventBus";
import BankRemitItem from "@/modules/bankRemit/models/BankRemitItem";
import { handleApiResponse } from "@/services/http";
import CashStatementApi from "../../cashStatement/services/api";
import AccCode from "@/modules/invoiceFlow/models/AccCode";
import BankInOverViewDTO from "@/modules/bankExtract/models/BankInOverViewDTO";
import BankOutOverViewDTO from "@/modules/bankExtract/models/BankOutOverViewDTO";
import base64toUInt8Array from "@/services/fileConvert";
import AccountingError from "../models/AccountingError";
import BankStatementSummary from "../models/BankStatementSummary";

export class BankStatementStore {
  bankStatements: BankStatementEntity[] = [];
  bankStatement: BankStatementEntity = { ...bankStatementDefaultProps };
  bankStatementsCount = 0;
  step = 1;

  accountingCodes: AccCode[] = [];

  bankStatementPict: BankStatementPict[] = [];

  bankDocuments: BankDocument[] = [];
  bankDocument: BankDocument = new BankDocument();

  bankStatuses: BankStatus[] = [];
  bankStatus: BankStatus | undefined = { ...bankStatusDefaultProps };

  partBanks: PartBank[] = [];

  accountTypes: AccountType[] = [];

  bankAccountStatuses: BankAccountStatus[] = [];

  allAccountSubtypes: AccountSubtype[] = [];

  accountSubtypes: AccountSubtype[] = [];

  bankNames: string[] = [];

  notoPdfUint8Array: Uint8Array[] = new Array<Uint8Array>();

  accountingErrors: AccountingError[] = [];

  bankStatementSummary = new BankStatementSummary();

  async loadBankStatementSummary(){
    this.bankStatementSummary = await BankStatementApi.getSummary(this.bankStatement.id);
  }

  async loadAccountingErrors(){
    if (this.bankStatement.id > 1){
      this.accountingErrors = await BankStatementApi.loadAccountingErrors(this.bankStatement.id);
    }
  }

  async closeBankStatement(updateBalance = false){
    await BankStatementApi.closeBankStatement(this.bankStatement.id, updateBalance);
    await this.loadBankStatement(this.bankStatement.id);
  }

  async deleteInvoicePayment(bankDocID: number, prefix: string, invoice: number){
    await BankStatementApi.deleteInvoicePayment(bankDocID, prefix, invoice).then((x) => {
      if (x) {
        eventBus.$emit("success", "Sikeres számla összerendelés törlés!");
      } else
        eventBus.$emit("error", "Sikertelen számla összerendelés törlés");
      return x;
    });
  }

  async paidBankDocument(bankDocID: number){
    await BankStatementApi.paidBankDocument(bankDocID).then((x) => {
      if (x) {
        eventBus.$emit("success", "Sikeres fizetés!");
        this.notoPdfUint8Array = x.map((y) => base64toUInt8Array(y));
      } else
        eventBus.$emit("error", "Sikertelen fizetés!");
      return x;
    });
  }

  @busyIndication()
  async wrongBankDocument(bankDocID: number){
    await BankStatementApi.wrongBankDocument(bankDocID).then((x) => {
      if (x) {
        eventBus.$emit("success", "Sikeres rontottra állítás!");
      } else
        eventBus.$emit("error", "Sikertelen  rontottra állítás!");
      return x;
    });
  }

  async loadAccountingCodes(statement: number) {
    this.accountingCodes = await CashStatementApi.getAccountingCodes(
      statement, true
    );
  }

  async loadBankStatements(pageNumber: number, pageSize: number) {
    const result = await BankStatementApi.getAll(pageNumber, pageSize);
    this.bankStatements = result.bankStatements.map((i) => ({
      ...i,
      selected: false,
    }));
    this.bankStatementsCount = result.count;
  }

  async loadBankStatementsWithFilter(
    filterId: number,
    pageNumber: number,
    pageSize: number
  ) {
    const result = await BankStatementApi.getAllWithFilter(
      filterId,
      pageNumber,
      pageSize
    );
    this.bankStatements = result.bankStatements.map((i) => ({
      ...i,
      selected: false,
    }));
    this.bankStatementsCount = result.count;
  }

  async generateBankInOverPayments(
    bankStatementId: number,
    bankDocId: number,
    items: BankInOverViewDTO[]
  ) {
    await BankStatementApi.generateBankInOverPayments(
      bankStatementId,
      bankDocId,
      items
    ).then((x) => {
      if (x) {
        eventBus.$emit("success", "Fizetési tétel sikeresen legenerálva!");
      } else
        eventBus.$emit("error", "Nem sikerült a fizetési tétel generálása!");
      return x;
    });
  }

  async generateBankOutOverPayments(
    bankStatementId: number,
    bankDocId: number,
    items: BankOutOverViewDTO[]
  ) {
    await BankStatementApi.generateBankOutOverPayments(
      bankStatementId,
      bankDocId,
      items
    ).then((x) => {
      if (x) {
        eventBus.$emit("success", "Fizetési tétel sikeresen legenerálva!");
      } else
        eventBus.$emit("error", "Nem sikerült a fizetési tétel generálása!");
      return x;
    });
  }

  async generateBankInPayments(
    bankStatementId: number,
    bankDocId: number,
    items: BankInView[]
  ) {
    await BankStatementApi.generateBankInPayments(
      bankStatementId,
      bankDocId,
      items
    ).then((x) => {
      if (x) {
        eventBus.$emit("success", "Fizetési tétel sikeresen legenerálva!");
      } else
        eventBus.$emit("error", "Nem sikerült a fizetési tétel generálása!");
      return x;
    });
  }

  async generateBankOutPayments(
    bankStatementId: number,
    bankDocId: number,
    items: BankOutView[]
  ) {
    await BankStatementApi.generateBankOutPayments(
      bankStatementId,
      bankDocId,
      items
    ).then((x) => {
      if (x) {
        eventBus.$emit("success", "Fizetési tétel sikeresen legenerálva!");
      } else
        eventBus.$emit("error", "Nem sikerült a fizetési tétel generálása!");
      return x;
    });
  }

  @busyIndication()
  async loadBankStatementsByName(
    bankStatementName: string,
    filterId: number,
    pageNumber: number,
    pageSize: number
  ) {
    const result = await BankStatementApi.getAllByName(
      bankStatementName,
      filterId,
      pageNumber,
      pageSize
    );
    this.bankStatements = result.bankStatements.map((i) => ({
      ...i,
      selected: false,
    }));
    this.bankStatementsCount = result.count;
  }

  @busyIndication()
  async loadBankStatementsByNameWithoutFilter(
    bankStatementName: string,
    pageNumber: number,
    pageSize: number
  ) {
    const result = await BankStatementApi.getAllByNameWithoutFilter(
      bankStatementName,
      pageNumber,
      pageSize
    );
    this.bankStatements = result.bankStatements.map((i) => ({
      ...i,
      selected: false,
    }));
    this.bankStatementsCount = result.count;
  }

  @busyIndication()
  async loadBankStatement(bankStatementId: number) {
    this.bankStatement =
      bankStatementId > 1
        ? await BankStatementApi.get(bankStatementId)
        : { ...bankStatementDefaultProps };

    console.log("loadBankStatement")
    console.log(this.bankStatement)
  }

  async loadBankDocumentsByStatementId(bankStatementId: number) {
    this.bankDocuments = (
      await BankStatementApi.getDocumentsByStatementId(bankStatementId)
    ).map((i) => ({ ...i, selected: false, paymAndOver: false }));
  }

  async loadBankStatuses() {
    this.bankStatuses = await BankStatementApi.getBankStatuses();
  }

  async payRemitItemsAsStatementItems(
    statement: number,
    items: BankRemitItem[]
  ) {
    return await BankStatementApi.createPaidBankStatementItems(
      statement,
      items
    );
  }

  //@busyIndication()
  async loadBankStatementItemById(
    bankStatementId: number,
    bankStatementItemId: number
  ) {
    this.bankDocument =
      bankStatementItemId > 1
        ? await BankStatementApi.getBankStatementItemById(
            bankStatementId,
            bankStatementItemId
          )
        : new BankDocument();
    this.bankDocument.selected = false;
  }

  setSelected() {
    this.bankDocument = { ...this.bankDocument };
    if (!this.bankDocument.selected) {
      this.bankDocument.selected = true;
    } else {
      this.bankDocument.selected = false;
    }
  }

  setPaymAndOver() {
    this.bankDocument = { ...this.bankDocument };
    if (!this.bankDocument.paymAndOver) {
      this.bankDocument.paymAndOver = true;
    } else {
      this.bankDocument.paymAndOver = false;
    }
  }

  async loadAccountTypes() {
    this.accountTypes = await AccountTypeApi.getAll();
  }

  async loadBankAccountStatuses() {
    this.bankAccountStatuses = await BankStatementApi.getBankAccountStatuses();
  }

  async loadPartBanks() {
    this.partBanks = await BankStatementApi.getPartBanks();
  }

  async loadOwnPartBanks(partBank: number) {
    this.partBanks = await BankStatementApi.getOwnPartBanks(partBank);
  }

  async loadAccountSubtypes() {
    this.allAccountSubtypes = await BankStatementApi.getAccountSubtypes();
    this.setAccountSubtypes();
  }

  setAccountSubtypes() 
  {
    if (this.bankDocument.bankStatementItem.accountType) 
    {
      this.accountSubtypes = this.allAccountSubtypes.filter(
        (x) => x.type === this.bankDocument.bankStatementItem.accountType || x.type === 1);
    }
    else
      this.accountSubtypes = this.allAccountSubtypes.filter((x) => x.type === 1);
  }

  async save() {
    //this.bankStatement = item;

    if (this.bankStatement.id < 2) {
      return Number(await this.createBankStatement());
    } else {
      await this.updateBankStatement();
    }
  }

  @busyIndication()
  private async updateBankStatement() {
    try {
      await BankStatementApi.update(this.bankStatement);
    } catch (error) {
      console.log(error);
    }
  }

  @busyIndication()
  private async createBankStatement() {
    try {
      const returnId = Number(
        await BankStatementApi.create(this.bankStatement)
      );
      return returnId;
    } catch (error) {
      console.log(error);
    }
  }

  async saveItem(isOver: boolean) {
    this.bankDocument.bankStatementItem.extNo = this.bankStatement.id;
    if (this.bankDocument.bankStatementItem.id === 0) {
      await this.createItem(isOver);
    } else {
      await this.updateItem(isOver);
    }
  }

  @busyIndication()
  private async updateItem(isOver: boolean) {
    try {
      //console.log(this.bankDocument);
      await BankStatementApi.updateBankStatementItem(this.bankDocument.bankStatementItem, isOver);
    } catch (error) {
      console.log(error);
    }
  }

  @busyIndication()
  private async createItem(isOver: boolean) {
    try {
      //console.log(this.bankDocument);
      await BankStatementApi.createBankStatementItem(this.bankDocument.bankStatementItem, isOver);
    } catch (error) {
      console.log(error);
    }
  }

  async loadBankStatementPictByStatementIdWithoutBusy(bankStatementId: number) {
    this.bankStatementPict = (
      await BankStatementApi.getBankStatementPictByStatementId(bankStatementId)
    ).map((x) => ({
      ...x,
      link: BankStatementApi.generateDocumentDownLoadLink(x.id),
      uploading: false,
    }));
  }

  async uploadBankStatementPict(itemId: number, doctype: number, file: File) {
    return await BankStatementApi.uploadBankStatementPict(
      itemId,
      doctype,
      "",
      file
    );
  }
}

const store = new BankStatementStore();
export default store;
