










































































import Vue from "vue";
import Page from "@/components/Page.vue";
import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import { ApiSearchColumn, ColumnType } from "@/components/apiSearch/models/ApiSearch";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import { BankStatementDTO } from "../models/BankStatement";
import store from "../services/BankStatementStore";
import BankStatementDetails from "./BankStatementDetails.vue";

export default Vue.extend({
  async beforeMount() {
    this.loading = true;
    window.scrollTo(0, 0);
    await store.loadBankStatuses();
    this.loading = false;
  },

  data: () => {
    return {
      loading: false,
      selectedStatementID: 0,
    };
  },

  components: {
    Page,
    ApiSearchPage,
    MoneyDisplay,
    BankStatementDetails,
  },

  methods: {
    create(){
      this.$router.push("bank-statement/create/1/head")
    },

    async showDetails(item: BankStatementDTO) {
      if (!item.selected) {
        await store.loadBankDocumentsByStatementId(item.id);
        this.selectedStatementID = item.id;
      }

      item.selected = !item.selected;
    },

    editItem(item: BankStatementDTO) {
      this.$router.push(`/bank-statement/create/${item.id}/head`);
    },

    getStatusName(item: BankStatementDTO) {
      return store.bankStatuses.filter((x) => x.name === item.status)[0].name;
    },
  },

  computed: {
    url() {
      return "bank-statement/search";
    },

    columns: (): ApiSearchColumn[] => [
      {
        cell: "statementDetailsButtonTemplate",
        filterable: false,
      },
      { field: "id", title: "Azonosító" },
      { field: "bank", title: "Bankszámla" },
      { field: "bankCode", title: "Bankszámla szám" },
      { field: "extract", title: "Kivonat" },
      {
        field: "statusID",
        cell: "status",
        title: "Bankkivonat státusza",
        multiselectField: "selectedStatuses",
        multiselectOptions: store.bankStatuses.map((x) => ({
          text: x.name,
          value: x.id,
        })),
        multiselect: true,
      },
      {
        field: "edate",
        title: "Kiv. dátuma",
        type: ColumnType.Date,
        fieldClass: "center_text adjust_right",
        headerClass: "center_text adjust_right",
      },
      {
        title: "Nyitó egyenleg",
        cell: "openBalanceTemplate",
        field: "openBalance",
        fieldClass: "right_text adjust_right",
        headerClass: "right_text adjust_right",
      },
      {
        title: "Záró egyenleg",
        cell: "closeBalanceTemplate",
        field: "closeBalance",
        fieldClass: "right_text adjust_right",
        headerClass: "right_text adjust_right",
      },
      { cell: "commandTemplate", filterable: false },
    ],
  },
});
