























































































































































import Vue from "vue";
import store, { BankStatementStore } from "../services/BankStatementStore";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import eventBus from "@/services/eventBus";
import BankDocument from "../models/BankDocument";
import configuration, { AppConfiguration } from "@/models/Configuration";

interface Data {
  store: BankStatementStore;
  sum: number;
  currSum: number;
  usable: number;
  over: number;
  finishedBills: number;
}

interface Computed {
  bankStatementItem: BankDocument;
  addedPaymentCurrSum: number;
  addedPaymentSum: number;
  availableSum: number;
  availableCurrSum: number;
  remainderSum: number;
  remainderCurrSum: number;
  defaultCurrency: any;
  exchangeRate: number;
  bankStatementAmount: number;
}

export default Vue.extend<Data, Computed, {}, {}>({
  components: {
    NumericTextBox,
  },

  mounted(){
    eventBus.$on("update-sum", (sum: number) => {
      this.sum = sum;

      const rate = this.exchangeRate;
      this.currSum = this.sum / (rate == 0 ? 1 : rate);
    });

    eventBus.$on("update-curr-sum", (sum: number) => {
      this.currSum = sum;

      const rate = this.exchangeRate;
      this.sum = this.currSum * (rate == 0 ? 1 : rate);
    });
  },

  data() {
    return {
      store,

      sum: 0,
      currSum: 0,
      usable: 0,
      over: 0,
      finishedBills: 0,
    };
  },

  watch: {
  },

  computed: {
    bankStatementAmount(){
      return store.bankDocument.bankAccountCurrency == 'HUF' ? store.bankDocument.bankStatementItem.amount : store.bankDocument.bankStatementItem.currAmount;
    },

    exchangeRate(){
      let rate = this.store.bankDocument.bankStatementItem.exchangeRate;
      if (this.store.bankDocument.bankAccountCurrency == this.defaultCurrency.value){
        rate = 1;
      }

      return rate;
    },

    bankStatementItem(){
      const ret = this.store.bankDocuments.find(x => x.bankStatementItem.id == this.store.bankDocument.bankStatementItem.id);
      return ret;
    },

    addedPaymentCurrSum(){
      /*const groupBy = function(xs: any, key: string) {
        return xs.reduce(function(rv: any, x: any) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };*/

      let sum = 0;

      if (this.bankStatementItem){
        /*this.bankStatementItem.paymentItems.forEach(x => {
          x["groupProp"] = x.id.toString() + "-" + x.prefix;
        });*/



        this.bankStatementItem.paymentItems.forEach(x => {
          if (!x.isExchangeComp){
            sum += x.paidAmount;
          }
        });

        /*const groupped = groupBy(this.bankStatementItem.paymentItems, "groupProp");

        for (const group in groupped)
        {

          sum += groupped[group][0].amount;
        }*/
        
        return sum;
      }

      return 0;
    },

    addedPaymentSum(){
      const rate = this.exchangeRate;
      return (rate == 0 ? 1 : rate) * this.addedPaymentCurrSum;
    },

    availableSum(){
      return Math.max(0, this.store.bankDocument.bankStatementItem.amount - this.sum - this.addedPaymentSum);
    },

    availableCurrSum(){
      return Math.max(0, this.store.bankDocument.bankStatementItem.currAmount - this.currSum - this.addedPaymentCurrSum);
    },

    remainderSum(){
      return Math.abs(Math.min(0, this.store.bankDocument.bankStatementItem.amount - this.sum - this.addedPaymentSum));
    },

    remainderCurrSum(){
      return Math.abs(Math.min(0, this.bankStatementAmount - this.currSum - this.addedPaymentCurrSum));
    },

    defaultCurrency(){
      return configuration.sysParams.defaultCurrency;
    },
  }
});
