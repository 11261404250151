import { ValidationRules } from "@/models/Validation";

export default interface Partner {
  id: number;
  name: string;
  nicName: string;
  partnerType: string;
  taxAccount: string;
  invoiceCity: string;
  invoiceAddress: string;
  email: string;
  phoneNumber: string;
  webPage: string;
  userId: number;
  contactUser: string;
  native: boolean;
  note: string;
  euTaxAccount: string;
  taxAccGroup: string;
  insDate: string | Date;
  insTime: string | Date;
  mobileNumber: string;
  defCurrency: string;
  status: string;
  nameExt: string;
  specTax: boolean;
  specTaxId: number;
  specTaxName: string;
  corName: string;
  payTerm: number;
  payMode: string;
  payModeID: number;
  companyForm: string;
  payClass: string;
  salesUser: string;
  partnerGroup1: string;
  partnerGroup2: string;
  postCode: string;
  corCity: string;
  corAddress: string;
  corPostCode: string;
  isAuthority: boolean;
  docTemplate: string;
  cid: string;
  countryName: string;
  partnerTax: string;
  extCode: string;
  prePartnerName: string;
  invStreet: string;
  invStreetTypeName: string;
  invStreetNum: string;
  invBuilding: string;
  invFloor: string;
  invDoor: string;
  corStreet: string;
  corStreetTypeName: string;
  corStreetNum: string;
  corBuilding: string;
  corFloor: string;
  corDoor: string;
  invStreet2: string;
  corStreet2: string;
  supplierDefaultCurrency: string;
  outsider: boolean;
  workflow: number;
}

// export const rules: ValidationRules = {
//   name: [(v: string) => !!v || "Kötelező"],
//   postCode: [(v: string) => !!v || "Kötelező"],
//   invoiceCity: [(v: string) => !!v || "Kötelező"],
//   invoiceAddress: [(v: string) => !!v || "Kötelező"],
//   mobileNumber: [(v: string) => !!v || "Kötelező"],
//   email: [(v: string) => !!v || "Kötelező"],
//   status: [(v: string) => !!v || "Kötelező"],
//   partnerType: [(v: string) => !!v || "Kötelező"],
//   taxAccount: [(v: string) => !!v || "Kötelező"],
//   companyForm: [(v: string) => !!v || "Kötelező"],
//   countryName: [(v: string) => !!v || "Kötelező"],
//   specTaxId: [(v: string) => !!v || "Kötelező"],
//   invStreetTypeName: [(v: string) => !!v || "Kötelező"],
// };

export interface PartnerResponse {
  partners: Partner[];
  count: number;
}

export const partnerDefaultProps: Partner = {
  id: 0,
  name: "",
  nicName: "",
  partnerType: "",
  taxAccount: "",
  invoiceCity: "",
  invoiceAddress: "",
  email: "",
  phoneNumber: "",
  webPage: "",
  userId: 1,
  contactUser: "-",
  native: true,
  note: "",
  euTaxAccount: "",
  taxAccGroup: "",
  insDate: "2000. 01. 01.",
  insTime: "2000. 01. 01. 00:00:00",
  mobileNumber: "",
  defCurrency: "-",
  status: "",
  nameExt: "",
  specTax: false,
  specTaxId: 1,
  specTaxName: "",
  corName: "",
  payTerm: 0,
  payMode: "Átutalás",
  payModeID: 1,
  companyForm: "-",
  payClass: "-",
  salesUser: "-",
  partnerGroup1: "-",
  partnerGroup2: "-",
  postCode: "",
  corCity: "",
  corAddress: "",
  corPostCode: "",
  isAuthority: false,
  docTemplate: "-",
  cid: "",
  countryName: "MAGYARORSZÁG",
  partnerTax: "-",
  extCode: "",
  prePartnerName: "-",
  invStreet: "",
  invStreetTypeName: "",
  invStreetNum: "",
  invBuilding: "",
  invFloor: "",
  invDoor: "",
  corStreet: "",
  corStreetTypeName: "",
  corStreetNum: "",
  corBuilding: "",
  corFloor: "",
  corDoor: "",
  invStreet2: "",
  corStreet2: "",
  supplierDefaultCurrency: "",
  outsider: true,
  workflow: 1,
};
