import http, { handleApiResponse, checkResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import PreInvoiceType from "../models/PreInvoiceType";
import GenoExperimentalInvoice from "../models/GenoExperimentalInvoice";
import PayResult from "../models/PayResult";

export default class KanriInvoiceApi {
    static getItemsByHead(head: number): Promise<any[]> {
        return http
            .get<ApiResponse<any[]>>(`kanri/invoice-items/${head}`)
            .then(handleApiResponse);
    }

    static importKanri(objectid: number, payload: any): Promise<any> {
        return http.post<ApiResponse<any>>(`kanri/import/geno/${objectid}`, { payload }).then(handleApiResponse);
    }

    static importCarAgreement(objectid: string, id: number): Promise<any> {
        return http.post<ApiResponse<any>>(`kanri/import/car-agreement`, { objectid, id }).then(handleApiResponse);
    }

    static createProformGeno(id: number, payload: any): Promise<number> {
        return http.post<ApiResponse<number>>(`kanri/create/geno/proform/${id}`, { payload }).then(handleApiResponse);
    }

    static refreshInvoice(id: number, type: string): Promise<boolean> {
        return http.put<ApiResponse<boolean>>(`kanri/refresh/invoice/${id}/${type}`).then(handleApiResponse);
    }

    static updateGenoHeadAndItems(head: GenoExperimentalInvoice, items: any[]): Promise<boolean> {
        const payload = { head, items };
        return http.post<ApiResponse<boolean>>(`geno/update-geno-head-and-items`, { payload }).then(handleApiResponse);
    }

    static updateStatus(id: number, payload: any): Promise<any> {
        return http.put<ApiResponse<any>>(`geno/update/geno-status/${id}`, { payload }).then(handleApiResponse);
    }

    static deleteGeno(id: number): Promise<any> {
        return http.delete<ApiResponse<any>>(`geno/delete/${id}`).then(handleApiResponse);
    }

    static loadCrystalReport(id: number): Promise<any> {
        return http.get<ApiResponse<any>>(`geno/crystal-report/${id}`).then(handleApiResponse);
    }

    static pay(id: number): Promise<PayResult> {
        return http.post<ApiResponse<PayResult>>(`cash-statement/generate-cash-statement/GENO/${id}`).then(handleApiResponse);
    }

    static payWithCashPoint(id: number, cashPoint: number): Promise<PayResult> {
        return http.post<ApiResponse<PayResult>>(`cash-statement/generate-cash-statement/GENO/${id}/${cashPoint}`).then(handleApiResponse);
    }

    static setPaid(id: number): Promise<any> {
        return http.post<ApiResponse<any>>(`cash-statement/generate-paid-statement/GENO/${id}`).then(handleApiResponse);
    }

    static setPaidStatus(id: number): Promise<any> {
        return http.post<ApiResponse<any>>(`cash-statement/geno-intr/paid/${id}`).then(handleApiResponse);
    }

    static updateGenoItem(item: any): Promise<any> {
        return http.post<ApiResponse<any>>(`geno/item/update/${item.id}`, { item }).then(handleApiResponse);
    }

    //dropdowns
    static getPreInvoiceTypes(): Promise<PreInvoiceType[]> {
        return http
            .get<ApiResponse<PreInvoiceType[]>>(`kanri/pre-invoice-types`)
            .then(handleApiResponse);
    }

    static getAccGroups(): Promise<any[]> {
        return http
            .get<ApiResponse<any[]>>(`kanri/acc-groups`)
            .then(handleApiResponse);
    }

    static getPreGenInvStatuses(): Promise<any[]> {
        return http
            .get<ApiResponse<any[]>>(`kanri/pre-gen/inv-statuses`)
            .then(handleApiResponse);
    }

    static downSyncPartner(id: number): Promise<any> {
        return http.put<ApiResponse<any>>(`kanri/downsync/partner/${id}`).then(handleApiResponse);
    }
}