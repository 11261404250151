
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import PaymentScheduleView from "../../invoicePaymentSchedule/views/PaymentScheduleView.vue";
import ColspanFixer from "@/components/ColspanFixer.vue";
import DocumentTemplatePicker from "../components/DocumentTemplatePicker.vue";
import PartnerRelationPicker from "../components/PartnerRelationPicker.vue";
import BankAccountPickerWrapper from "../components/BankAccountPickerWrapper.vue";
import CurrencyPicker from "../components/CurrencyPicker.vue";
import Fragment from "@/components/Fragment.vue";
import PartBankCreateComponent from "@/modules/partner/components/PartBankCreateComponent.vue";
import MoneyInput from "@/components/MoneyInput.vue";
import NoticeTable from "../../invoiceItems/components/NoticeTable.vue";
import SetIncorrectInvoice from "../components/SetIncorrectInvoice.vue";
import IconButton from "@/components/IconButton.vue";
import InvoiceTemplateView from "./InvoiceTemplateView.vue";
import DateInput from "../../../components/DateInput.vue";

import Vue from "vue";
import eventBus from "@/services/eventBus";
import configuration, {
  AppConfiguration,
  loadConfiguration,
} from "@/models/Configuration";
import store, {
  FlowStatus,
  IncomingInvoiceFinancialPageValidator,
  InvoiceFlowStore,
} from "../services/InvoiceFlowStore";
import permStore, { PermissionStore } from "@/services/permissions";
import relStore, {
  FlowDocumentRelationStore,
} from "@/modules/flowDocumentRelation/services/FlowDocumentRelationStore";
import itemStore, {
  InvoiceItemStore,
} from "@/modules/invoiceItems/services/store";
import {
  InvoiceAcceptRequest,
  invoiceAcceptRequestDefaultProps,
  InvoiceControlRequest,
  invoiceControlRequestDefaultProps,
} from "../models/InvoiceRequest";
import User, {
  userDefaultProps,
  userTechProps,
} from "@/modules/partner/models/User";
import PartnerTax, {
  partnerTaxDisplayDefaultProps,
} from "@/modules/generalIngoingInvoice/models/PartnerTax";
import Tax from "@/modules/generalIngoingInvoice/models/Tax";
import { getInvoiceItems } from "@/modules/invoiceItems/services/api";
import http from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import { PrecisionType, roundMoney } from "@/services/prices";
import LetterOfCreditBrowser from "@/modules/letterOfCredit/LetterOfCreditBrowser.vue";
import ProjectApiSearch from "@/modules/invoiceItemDimension/components/ProjectApiSearch.vue";
import ProjectHeadDTO from "@/modules/projectHead/models/ProjectHeadDTO";
import ValidationError from "@/modules/downPayment/components/ValidationError.vue";
import pStore, { PartnerStore } from "@/modules/partner/services/PartnerStore";
import InvoiceFlowStatusRelation from "@/modules/invoiceFlowStatus/model/invoiceFlowStatusRelation";
import VatReturnStatus from "@/modules/vat/models/VatReturnStatus";

import { ConfirmationMixin } from "@/mixins/confirmation";
import Confirmation from "@/components/Confirmation.vue";

import dayjs from 'dayjs';

interface Data {
  configuration: AppConfiguration;
  store: InvoiceFlowStore;
  itemStore: InvoiceItemStore;
  permStore: PermissionStore;
  relStore: FlowDocumentRelationStore;
  paymentScheduleVisible: boolean;
  specialInfoVisible: boolean;
  discountVisible: boolean;
  letterOfCreditVisible: boolean;
  controllerDetailsVisible: boolean;
  acceptorDetailsVisible: boolean;
  ffControllerDetailsVisible: boolean;
  ffAcceptorDetailsVisible: boolean;
  selectedController: User | undefined;
  selectedAcceptor: User | undefined;
  selectedFFController: User | undefined;
  selectedFFAcceptor: User | undefined;
  selectedPartnerTax: PartnerTax | undefined;
  partBankCreateVisible: boolean;
  templateVisible: boolean;
  isMounted: boolean;
  isCashAccountingReassure: boolean;
  isLoading: boolean;
  subcontractorProjectVisible: boolean;
  invVatModified: boolean;
  pStore: PartnerStore;
  shipDateWarning: boolean;
  payTermWarning: boolean;
  shipDateChange: boolean;
}

interface Methods {
  onPartBankCreateVisibleChange(): void;
  savePartBankCreate(): void;
  cancelPartBankCreate(): void;
  loadApprovalMatrixUsers(): void;
  setControllerDeleting(item: InvoiceControlRequest): void;
  setAcceptorDeleting(item: InvoiceAcceptRequest): void;
  confirmControllerDelete(item: InvoiceControlRequest): void;
  confirmAcceptorDelete(item: InvoiceAcceptRequest): void;
  onTaxChange(e: any): void;
  nettoChanged(): void;
  vatChanged(): void;
  totalChanged(): void;
  onPartnerTaxChange(): void;
  onPaymentModeChange(): void;
  onControllerChange(user: User): void;
  onAcceptorChange(user: User): void;
  onFFControllerChange(user: User): void;
  onFFAcceptorChange(user: User): void;
  isSelectedUserCorrect(
    user: User,
    list: InvoiceControlRequest[] | InvoiceAcceptRequest[],
    prop: string,
    resetGeniUser?: number
  ): boolean;
  addToControllers(user: User, isFF?: boolean): void;
  addToAcceptors(user: User, isFF?: boolean): void;
  onSpecTaxSwitchChange(): void;
  onSpecTaxTypeChange(): void;
  checkBeforeSave(): boolean;
  update(saveOnly?: boolean): void;
  shipDateChanged(): void;
  payTermChanged(): void;

  toggleInvoiceTemplates(): void;

  deleteDownPaymentRels(val: boolean): void;
  priceHeadPrecision(item: string): number;
  reassurance(): void;
  projectChange(project: ProjectHeadDTO): void;
  setInvVat(val: number): void;
  setShipDateWarning(): void;
  setIsGeniSeparCont(): void;
  payDateChanged(payDate: string): void;

  tabHandler(e: any, idx: any): void;
  updateOrCashAccountingReassure(): void;
  next(): void;
}

interface Computed {
  validationError: string;
  itemsHaveDifferentTax: string;
  vatDiffersFromTaxDegree: string;
  isPageDisabled: boolean;
  notiNotSelected: boolean;
  taxes: Tax[];
  isOtherItemRelation: boolean;
  isTaxComboBoxEnabled: boolean;
  partnerIsSpecTaxChanged: boolean;
  controllers: User[];
  fullfilmentControllers: User[];
  fullfilmentAcceptors: User[];
  acceptors: User[];
  controllerRequests: InvoiceControlRequest[];
  acceptorRequests: InvoiceAcceptRequest[];
  ffControllerRequests: InvoiceControlRequest[];
  ffAcceptorRequests: InvoiceAcceptRequest[];
  isLetterOfCredit: boolean;
  recordedStatus: InvoiceFlowStatusRelation | undefined;
  preStatusCheck: boolean | undefined;
  isNext: boolean;
}

export default ConfirmationMixin.extend<Data, Methods, Computed, {}>({
  mixins: [ConfirmationMixin],

  components: {
    PaymentScheduleView,
    ColspanFixer,
    DocumentTemplatePicker,
    PartnerRelationPicker,
    BankAccountPickerWrapper,
    CurrencyPicker,
    Fragment,
    PartBankCreateComponent,
    MoneyInput,
    NoticeTable,
    SetIncorrectInvoice,
    IconButton,
    InvoiceTemplateView,
    DateInput,
    LetterOfCreditBrowser,
    ProjectApiSearch,
    ValidationError,
    Confirmation,
  },

  data: (): Data => ({
    configuration,
    store,
    itemStore,
    permStore,
    relStore,
    paymentScheduleVisible: false,
    specialInfoVisible: false,
    discountVisible: false,
    letterOfCreditVisible: false,
    controllerDetailsVisible: false,
    acceptorDetailsVisible: false,
    ffControllerDetailsVisible: false,
    ffAcceptorDetailsVisible: false,
    selectedController: { ...userDefaultProps },
    selectedAcceptor: { ...userDefaultProps },
    selectedFFController: { ...userDefaultProps },
    selectedFFAcceptor: { ...userDefaultProps },
    selectedPartnerTax: { ...partnerTaxDisplayDefaultProps },
    partBankCreateVisible: false,
    templateVisible: false,
    isMounted: false,
    isCashAccountingReassure: false,
    isLoading: false,
    subcontractorProjectVisible: false,
    invVatModified: false,
    pStore,
    shipDateWarning: false,
    payTermWarning: false,
    shipDateChange: false,
  }),

  /*   destroyed() {
    if (this.validationError != "") {
      this.store.notisOnInvoce.forEach(async (e) => {
        console.log(e);
        await this.store.deleteNoti(e);
      });
    }
  }, */

  async beforeMount() {
    try{
      this.isLoading=true;
    window.scrollTo(0, 0);
    const invoiceId = Number(this.$route.params.invoiceId) || 0;
    await this.store.loadInvoiceWithoutBusy(invoiceId);

    await Promise.all([
      this.store.loadPartnerTaxes(),
      this.store.loadAccContrUsers(),
      this.store.loadControllerRequests(invoiceId),
      this.store.loadAcceptorRequests(invoiceId),
      this.pStore.loadPartSuppliers(this.store.invoice.partner),
      this.setShipDateWarning()
    ]);

    if (
      this.store.flowUsers.invoiceApproval.length < 1 &&
      this.store.flowUsers.invoiceChecking.length < 1 &&
      this.store.flowUsers.fullfilmentApproval.length < 1 &&
      this.store.flowUsers.fullfilmentChecking.length < 1
    ) {
      await this.store.flowUsers.load();
    }

    this.isMounted = true;

    // Ellenőrzés, jóváhagyás
    this.controllerDetailsVisible = this.controllerRequests.length > 0;
    this.acceptorDetailsVisible = this.acceptorRequests.length > 0;
    this.ffControllerDetailsVisible = this.ffControllerRequests.length > 0;
    this.ffAcceptorDetailsVisible = this.ffAcceptorRequests.length > 0;

    if (this.store.paymentModes.length == 0)
      await this.store.loadPaymentModes();
    if (this.store.selectedPaymentMode?.id != this.store.invoice.paymode) {
      this.store.setSelectedPaymentMode();
    }

    if (this.store.selectedTax?.id != this.store.invoice.tax) {
      this.store.setSelectedTax();
    }

    if (!this.store.users.some((x) => x.id == 1)) {
      this.store.users = [{ ...userTechProps }, ...this.store.users];
    }

    this.selectedController = this.store.users.find(
      (x) => x.id == this.store.invoice.contrPers
    );
    if (!this.selectedController && this.store.users.length > 0) {
      this.selectedController = this.store.users[0];
      this.store.invoice.contrPers = this.selectedController.id;
    }

    this.selectedAcceptor = this.store.users.find(
      (x) => x.id == this.store.invoice.acceptor
    );
    if (!this.selectedAcceptor && this.store.users.length > 0) {
      this.selectedAcceptor = this.store.users[0];
      this.store.invoice.acceptor = this.selectedAcceptor.id;
    }

    this.selectedPartnerTax = this.store.partnerTaxes.find(
      (x) => x.id == this.store.invoice.partnerTax
    );
    if (!this.selectedPartnerTax && this.store.partnerTaxes.length > 0) {
      this.selectedPartnerTax = this.store.partnerTaxes[0];
      this.store.invoice.partnerTax = this.selectedPartnerTax.id;
    }

    this.specialInfoVisible = this.store.invoice.specTax;

    this.itemStore.invoiceID = invoiceId;
    const items = await getInvoiceItems(invoiceId);
    this.itemStore.itemList.initialize(
      items,
      this.store.invoice.exchangeRate,
      this.store.invoice.invCurrency
    );
    if (this.itemStore.itemList.items.length > 0) {
      this.store.nettoChanged(false, false);
    }
    this.store.subcontractorProjectDetails = [];
    if (!configuration.operel && this.store.invoice.selectedProjectId > 1)
      this.store.getsubcontractorProjectDetails(this.store.invoice.selectedProjectId);
  }
  finally{
    this.isLoading = false;
  }
  },

  async created() {
    if (
      this.store.selectedCurrency?.currency.toUpperCase().trim() !== "HUF" &&
      this.store.selectedCurrency?.currency !== "" &&
      this.store.invoice.invCurrency !== "" &&
      this.store.invoice.exchangeRate < 2 &&
      this.store.invoice.id > 1
    ) {
      try {
        console.log(
          "this.store.selectedCurrency?.currency.toUpperCase().trim() = " +
            this.store.selectedCurrency?.currency.toUpperCase().trim()
        );
        console.log("this.store.selectedCurrency?.currency = " + this.store.selectedCurrency?.currency);
        console.log("this.store.invoice.invCurrency = " + this.store.invoice.invCurrency);
        console.log(
          "this.store.invoice.exchangeRate = " +
            this.store.invoice.exchangeRate +
            " ---> <2 ?= " +
            (this.store.invoice.exchangeRate < 2)
        );
        await this.store.selectedCurrencyChanged();
      } catch (ex) {
        console.log(ex);
      }
    }
  },

  methods: {
    next() {
      this.$router.push(`/general-incoming-invoice/flow/edit/${this.store.invoice.id}/4`);
    },

    updateOrCashAccountingReassure() {
      if (!this.store.invoice.isCashAccounting) {
        this.update();
      }
      else {
        this.isCashAccountingReassure = true;
      }
    },

    tabHandler(e: any, idx: any) {
      e.preventDefault();
      if (e.key != "Tab") {
        return;
      }

      if (this.configuration.sysParams.controllersVisible) {
        if (idx == 7) {
          (this.$refs.fullfilmentControllers as HTMLElement).blur();
          ((this.$refs.fullfilmentAcceptors as any).$el.querySelector('input') as HTMLElement).focus();
          return;
        }

        if (idx == 8) {
          (this.$refs.fullfilmentAcceptors as HTMLElement).blur();
          ((this.$refs.controllers as any).$el.querySelector('input')as HTMLElement).focus();
          return;
        }

        if (idx == 9) {
          (this.$refs.controllers as HTMLElement).blur();
          ((this.$refs.acceptors as any).$el.querySelector('input')as HTMLElement).focus();
          return;
        }

        if (idx == 10) {
          (this.$refs.acceptors as HTMLElement).blur();
          (this.$refs.doneButton as any).$el.focus();
          return;
        }
      }
      else {
        if (idx == 8) {
          (this.$refs.fullfilmentAcceptors as HTMLElement).blur();
          ((this.$refs.acceptors as any).$el.querySelector('input')as HTMLElement).focus();
          return;
        }

        if (idx == 10) {
          (this.$refs.acceptors as HTMLElement).blur();
          (this.$refs.doneButton as any).$el.focus();
          return;
        }
      }
    },

    payDateChanged(payDate: string) {
      this.store.invoice.payDate = dayjs(payDate).format("YYYY-MM-DD");
      const next = new Date(this.store.invoice.shipDate as string);
      next.setMonth(next.getMonth() + 1);
      next.setDate(15);
      const arr = [next, new Date(this.store.invoice.arrivDate), new Date(payDate)].sort((a, b) => a.getTime() - b.getTime());
      this.store.invoice.vatDate = dayjs(arr[0]).format("YYYY-MM-DD");
    },

    async setShipDateWarning(){
      if (this.store.invoice.shipDate) {
        const shippingDatePhase = await this.store.getInvoiceShippingPhaseDate();
        console.log(shippingDatePhase)
        if (
          shippingDatePhase.id !== 1 &&
          shippingDatePhase.statusID === VatReturnStatus.Closed
        ) {
          this.shipDateWarning = true;
        }
      }
    },

    setIsGeniSeparCont() {
      if (!configuration.isGeniSeparCont.enabled) {
        return;
      }
      this.store.invoice.isSeparCont = this.store.invoice.shipDateC;
    },

    setInvVat(val) {
      this.store.invoice.invVat = val;
      this.invVatModified = true;
    },

    reassurance() {
      if (
        this.store.invoice.isCashAccounting &&
        !this.isCashAccountingReassure
      ) {
        this.isCashAccountingReassure = true;
      } else {
        this.isCashAccountingReassure = false;
        this.update();
      }
    },

    priceHeadPrecision(currency) {
      if (configuration.operel) return 4;
      else if (!configuration.isLoaded) {
        loadConfiguration();
      }
      return configuration.getCurrDigits(currency) !== undefined
        ? configuration.getCurrDigits(currency)?.geniHeadDigits ?? 0
        : 0;
    },

    deleteDownPaymentRels(val) {
      if (val) return;

      http.delete<ApiResponse<boolean>>(
        `noti/invoice/${this.store.invoice.id}`
      );
    },

    onPartBankCreateVisibleChange() {
      this.partBankCreateVisible = !this.partBankCreateVisible;
      if (this.partBankCreateVisible) window.scrollTo(0, 0);
    },

    savePartBankCreate() {
      eventBus.$emit("flow:part-bank-created");
      this.partBankCreateVisible = false;
    },

    cancelPartBankCreate() {
      this.partBankCreateVisible = false;
    },

    async loadApprovalMatrixUsers() {
      this.store.approvalMatrixHeads = [];
      this.store.controllerRequests = [];
      this.store.acceptorRequests = [];

      await this.store
        .loadApprovalMatrixHeadsByPartner(this.store.invoice.partner)
        .then(() =>
          this.store.fillControllerApproverRequests(this.store.invoice.id)
        );

      this.controllerDetailsVisible = this.controllerRequests.length > 0;
      this.acceptorDetailsVisible = this.acceptorRequests.length > 0;
      this.ffControllerDetailsVisible = this.ffControllerRequests.length > 0;
      this.ffAcceptorDetailsVisible = this.ffAcceptorRequests.length > 0;
    },

    setControllerDeleting(item: InvoiceControlRequest) {
      if (item.id == 0) {
        this.confirmControllerDelete(item);
        return;
      } else {
        item.deleting = !item.deleting;
      }
    },

    setAcceptorDeleting(item: InvoiceAcceptRequest) {
      if (item.id == 0) {
        this.confirmAcceptorDelete(item);
        return;
      } else {
        item.deleting = !item.deleting;
      }
    },

    confirmControllerDelete(item: InvoiceControlRequest) {
      item.deleting = false;
      const index = this.store.controllerRequests.indexOf(item);
      if (index > -1) this.store.controllerRequests.splice(index, 1);
    },

    confirmAcceptorDelete(item: InvoiceAcceptRequest) {
      item.deleting = false;
      const index = this.store.acceptorRequests.indexOf(item);
      if (index > -1) this.store.acceptorRequests.splice(index, 1);
    },

    onTaxChange(e: any) {
      this.store.selectedTaxChanged();
    },

    nettoChanged() {
      this.store.nettoChanged();
    },

    vatChanged() {
      this.store.invoice.currTotal = Number(this.store.invoice.currNetto) + Number(this.store.invoice.currVat);

    },

    totalChanged() {
      this.store.totalChanged(true);
    },

    onPartnerTaxChange() {
      this.store.invoice.partnerTax = this.selectedPartnerTax?.id ?? 1;
    },

    onPaymentModeChange() {
      this.store.invoice.paymode = this.store.selectedPaymentMode?.id ?? 1;
      eventBus.$emit("payment-method:changed", this.store.invoice.paymode);
    },

    onControllerChange(user: User) {
      if (
        !this.isSelectedUserCorrect(
          user,
          this.controllerRequests,
          "controllerId",
          this.store.invoice.contrPers
        )
      )
        return;

      this.controllerDetailsVisible = true;
      this.addToControllers(user);
      this.selectedController = { ...userDefaultProps };
    },

    onAcceptorChange(user: User) {
      if (
        !this.isSelectedUserCorrect(
          user,
          this.acceptorRequests,
          "acceptorId",
          this.store.invoice.acceptor
        )
      )
        return;

      this.acceptorDetailsVisible = true;
      this.addToAcceptors(user);
      this.selectedAcceptor = { ...userDefaultProps };
    },

    onFFControllerChange(user: User) {
      if (
        !this.isSelectedUserCorrect(
          user,
          this.ffControllerRequests,
          "controllerId"
        )
      )
        return;

      this.ffControllerDetailsVisible = true;
      this.addToControllers(user, true);
      this.selectedFFController = { ...userDefaultProps };
    },

    onFFAcceptorChange(user: User) {
      if (
        !this.isSelectedUserCorrect(user, this.ffAcceptorRequests, "acceptorId")
      )
        return;

      this.ffAcceptorDetailsVisible = true;
      this.addToAcceptors(user, true);
      this.selectedFFAcceptor = { ...userDefaultProps };
    },

    // eslint-disable-next-line
    isSelectedUserCorrect(
      user: User,
      list: any[],
      prop: string,
      resetGeniUser = 0
    ) {
      if (user == null || typeof user != "object") return false;
      if (user?.id <= 1) {
        if (resetGeniUser > 0) resetGeniUser = 1;
        return false;
      }
      if (list.filter((x) => x[prop] == user?.id).length > 0) return false;

      return true;
    },

    addToControllers(user: User, isFF = false) {
      this.store.controllerRequests.push({
        ...invoiceControlRequestDefaultProps,
        invId: this.store.invoice.id,
        controllerId: user.id,
        name: user.name,
        isFullFillReq: isFF,
      });
    },

    addToAcceptors(user: User, isFF = false) {
      this.store.acceptorRequests.push({
        ...invoiceAcceptRequestDefaultProps,
        invId: this.store.invoice.id,
        acceptorId: user.id,
        name: user.name,
        isFullFillReq: isFF,
      });
    },

    onSpecTaxSwitchChange() {
      this.store.selectedTax =
        this.taxes.find(
          (x) => x.degree == 27 || x.id == this.store.selectedSpecTaxType?.tax
        ) ?? this.taxes[0];
      this.store.selectedTaxChanged();
    },

    onSpecTaxTypeChange() {
      this.store.invoice.specTaxType = this.store.selectedSpecTaxType?.id ?? 1;
      const specTaxTax = this.store.selectedSpecTaxType?.tax ?? 1;
      this.store.invoice.tax =
        this.taxes.find((x) => x.id == specTaxTax)?.id ?? this.taxes[0].id;
      this.store.setSelectedTax();
    },

    checkBeforeSave() {
      if (!(this.store.invoice.tax > 1)) {
        eventBus.$emit("error", "Az ÁFA kulcs megadása kötelező!");
        return false;
      }
      
      if (this.store.selectedPartnerRelation == null) {
        eventBus.$emit("error", "Kötelező partner relációt választani!");
        return false;
      }

      if (
        this.store.invoice.shipDateC &&
        (!this.store.invoice.accDateStart ||
          this.store.invoice.accDateStart == "" ||
          !this.store.invoice.accDate ||
          this.store.invoice.accDate == "")
      ) {
        eventBus.$emit(
          "error",
          "Időszakos elszámolású ügylet esetén a dátumok megadása kötelező!"
        );
        return false;
      }

      if (
        this.store.paymentSchedule &&
        this.store.paymentSchedule.items.length > 0 &&
        this.store.invoice.currTotal !=
          (this.store.paymentSchedule.items?.reduce(
            (acc, current) => (acc += current.amount),
            0
          ) ?? 0)
      ) {
        eventBus.$emit(
          "error",
          "A számla bruttó értéke nem egyezik meg a fizetési ütemezés teljes összegével!"
        );
        return false;
      }

      if (
        this.relStore.hasAllRequiredDocuments(FlowStatus.FinancialRecording)
      ) {
        eventBus.$emit(
          "error",
          "Hiányoznak a lépéshez tartozó kötelező dokumentum(ok)!"
        );
        return false;
      }

      if (
        this.store.invoice.specTax &&
        (this.store.invoice.specTaxType <= 1 || !this.store.invoice.specTaxType)
      ) {
        eventBus.$emit(
          "error",
          "A partner speciális adózási típus alá tartozik, így a Speciális adó típust kötelező megadni!"
        );
        return false;
      }

      return true;
    },

    async update(saveOnly = false) {
      if (!this.permStore.hasRecording()) {
        eventBus.$emit(
          "error",
          "Nincs jogosultsága a számla pénzügyi rögzítéséhez!"
        );
        return;
      }

      if (!saveOnly) {
        if (!this.checkBeforeSave()) return;
      }

      if (!saveOnly) {
        const vatReturn = await this.store.checkVatReturn(this.store.invoice.vatDate, this.store.invoice.id);
        if (vatReturn == -2) {
          console.log("-2");
          eventBus.$emit("warn", "A számla olyan időszakba esik, amelyre már van megnyitva áfa bevallás, az áfa bevallást ezért frissítenie kell!");
        }
        else if (vatReturn == -1) {
          console.log("-1");
          const confirmed = await this.confirm("A számla lezárt ÁFA időszakot érint, folytatja?");
          if (!confirmed) {
            return;
          }
        }
        else if(vatReturn == 1) {
          console.log("1");
          this.store.invoice.latecomer = true;
        }
        else if(vatReturn == 0) {
          console.log("0");
          this.store.invoice.latecomer = false;
        }
      }

      if (this.shipDateChange && this.configuration.sysParams.shipDateChangeNotificationEnabled) {
        const confirmed2 = await this.confirm("Módosult a teljesítés dátuma, a fizetési határidőt írjuk felül az ebből számított dátummal?");
        if (confirmed2) {
          this.store.invoice.payTerm = await this.store.loadPartnerPayTermWithDate(this.store.invoice.partner, (this.store.invoice.shipDate as any).toString());
        }
      }

      this.store.invoice.netto = this.store.toDecimal(
        this.store.invoice.currNetto * this.store.invoice.exchangeRate
      );
      this.store.invoice.vat = this.store.toDecimal(
        this.store.invoice.currVat * this.store.invoice.exchangeRate
      );
      this.store.invoice.total = this.store.toDecimal(
        this.store.invoice.currTotal * this.store.invoice.exchangeRate
      );

      if (this.store.invoice.invCurrency == "HUF" && this.store.invoice.invVat == 0) {
        this.store.invoice.invVat = this.store.invoice.currVat;
      }

      // if (this.store.controllerRequests.length > 1) {
      //   this.selectedController = this.store.users.find((x) => x.id == this.store.controllerRequests[0].controllerId);
      //   this.store.invoice.contrPers = this.store.controllerRequests[0].id;
      // }

      // if (this.store.acceptorRequests.length > 1) {
      //   this.selectedAcceptor = this.store.users.find((x) => x.id == this.store.acceptorRequests[0].acceptorId);
      //   this.store.invoice.acceptor = this.store.acceptorRequests[0].id;
      // }

      await this.store.updatePartnerRelRelation();

      this.isLoading = true;
      await this.store.updateFlow(this.isOtherItemRelation, saveOnly);

      await Promise.all([
        this.store.managePaymentScheduleAndItems(),
        this.store.updateControlRequests(),
        this.store.updateAcceptorRequests(),
      ]);

      await this.store.loadAcceptState();

      if (!saveOnly) {
        this.$invoiceTaskUpdateHub.send("InvoiceTaskUpdated");

        this.$emit("update");
        eventBus.$emit("success", "Sikeres pénzügyi rögzítés.");
        await this.permStore.getFlowPermissions(this.store.invoice.id);
      } else {
        eventBus.$emit("success", "Sikeres mentés.");
      }

      eventBus.$emit("flowfinancepage-saved");
      this.isLoading = false;

      this.$nextTick(() => (this.$refs.doneButton as any).$el.focus());
    },

    async shipDateChanged() {
      if (!this.store.invoice.shipDate) return;
      await this.store.currencyChanged();
      this.setShipDateWarning()
      this.shipDateChange = true;
    },

    async payTermChanged() {
      if (!this.store.invoice.payTerm) return;
      this.payTermWarning = true;
      console.log("henlo");
    },

    toggleInvoiceTemplates() {
      this.templateVisible = !this.templateVisible;
      if (this.templateVisible)
        store.loadInvoiceFlowTemplatesByPartnerId(store.invoice.partner);
    },
    projectChange(project: ProjectHeadDTO) {
      this.store.invoice.selectedProjectId = project.id;
      this.store.subcontractorProjectDetails = [];
      if (!configuration.operel && this.store.invoice.selectedProjectId > 1)
        this.store.getsubcontractorProjectDetails(this.store.invoice.selectedProjectId);
    },
  },

  computed: {
    isNext() {
      console.log(this.store.flowStatusRelations);
      return this.store.flowStatusRelations.length > 2;
    },

    validationError() {
      const validator = new IncomingInvoiceFinancialPageValidator(
        this.store.invoice,
        this.controllerRequests,
        this.acceptorRequests,
        this.ffControllerRequests,
        this.ffAcceptorRequests,
        this.store.notisOnInvoce,
        this.store.invoiceDetails
      );
      return validator.validate();
    },

    vatDiffersFromTaxDegree() {
      const brutto = this.store.toDecimal(this.store.invoice.currTotal);
      const netto = this.store.toDecimal(this.store.invoice.currNetto);

      if (netto < 0 || brutto < 0) {
        return "";
      }

      const taxRate = 1.0 + (this.store.selectedTax?.degree ?? 27.0) / 100.0;
      const vat =
        brutto -
        roundMoney(
          brutto / taxRate,
          store.invoice.invCurrency,
          undefined,
          PrecisionType.geniHead
        );

      if (this.itemsHaveDifferentTax != "") {
        return this.itemsHaveDifferentTax;
      }

      const itemVats = new Set(
        (this.store.invoiceItems || []).map((i) => i.taxID)
      );

      if (brutto - netto === vat) {
        return "";
      }

      if (itemVats.size > 1) {
        return `A számla eltérő ÁFA kulcsú tételeket tartalmaz.`;
      }

      return `A számla fej nettó érték felül írva kerekítési eltérés vagy a tételek eltérő áfakulcsa miatt`;
    },

    itemsHaveDifferentTax() {
      if (
        this.itemStore.invoiceID == this.store.invoice.id &&
        this.itemStore.itemList.items.length > 0 &&
        this.itemStore.itemList.items.some(
          (x) => x.tax.degree != this.store.selectedTax?.degree
        )
      ) {
        return "A számla eltérő ÁFA kulcsú tételeket tartalmaz.";
      }

      return "";
    },

    isPageDisabled() {
      return (
        !this.permStore.hasRecording() ||
        !this.store.isEditingEnabled ||
        !this.store.flowStatusRelations.some(
          (x) => x.statusId == FlowStatus.Recording && !x.deleted
        )
      );
    },

    isLetterOfCredit() {
      return (
        this.store.invoice.paymode ==
        this.store.paymentModes.find((x) => x.name == "Akkreditív")?.id
      );
    },

    notiNotSelected() {
      if (this.store.hasNoti) {
        return true;
      }
      return false;
    },

    taxes() {
      return this.store.taxes.filter(
        (x) =>
          (this.store.invoice.specTax && x.specTax) ||
          (!this.store.invoice.specTax && !x.specTax)
      );
    },

    isOtherItemRelation() {
      return this.store.invoice.type == 6;
    },

    isTaxComboBoxEnabled() {
      return this.store.isEditingEnabled && !this.store.invoice.specTax;
    },

    partnerIsSpecTaxChanged() {
      return this.store.invoiceDetails.partnerIsSpecTax;
    },

    fullfilmentControllers() {
      return [
        { ...userTechProps },
        ...this.store.flowUsers.fullfilmentChecking,
      ];
    },

    controllers() {
      return [{ ...userTechProps }, ...this.store.flowUsers.invoiceChecking];
    },

    fullfilmentAcceptors() {
      return [
        { ...userTechProps },
        ...this.store.flowUsers.fullfilmentApproval,
      ];
    },

    acceptors() {
      return [{ ...userTechProps }, ...this.store.flowUsers.invoiceApproval];
    },

    controllerRequests() {
      return this.store.controllerRequests.filter((x) => !x.isFullFillReq);
    },

    acceptorRequests() {
      return this.store.acceptorRequests.filter((x) => !x.isFullFillReq);
    },

    ffControllerRequests() {
      return this.store.controllerRequests.filter((x) => x.isFullFillReq);
    },

    ffAcceptorRequests() {
      return this.store.acceptorRequests.filter((x) => x.isFullFillReq);
    },

    recordedStatus() {
      return this.store.flowStatusRelations.find((x) => x.statusId == FlowStatus.FinancialRecording);
    },

    preStatusCheck() {
      return this.store.invoice.preStatus == 9;
    },
  },
});
