var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-4"},[_c('v-card-text',[_c('ApiSearchPage',_vm._b({attrs:{"url":_vm.url,"columns":_vm.columns,"itemText":"combined","extraFilter":_vm.filter,"search":false,"delay":1000,"refresh":true,"resetSort":false,"resetFilter":false,"detailsField":"selected","title":'Kanri-ból beemelhető számlák',"name":'kanriInvoices'},scopedSlots:_vm._u([{key:"selectButton",fn:function(ref){
var item = ref.item;
return [_c('IconButton',{staticStyle:{"width":"32px !important"},attrs:{"icon":'fa-bars',"tooltip":'Részletek'},on:{"click":function($event){return _vm.showDetails(item)}}})]}},{key:"importCell",fn:function(ref){
var item = ref.item;
return [_c('IconButton',{staticStyle:{"width":"32px !important"},attrs:{"icon":'fa-plus',"tooltip":'Számla készítés'},on:{"click":function($event){return _vm.importKanri(item)}}})]}},{key:"nettoTemplate",fn:function(ref){
var item = ref.item;
return [_c('MoneyDisplay',{attrs:{"value":item.netto,"currency":item.currency,"decimals":2}})]}},{key:"vatTemplate",fn:function(ref){
var item = ref.item;
return [_c('MoneyDisplay',{attrs:{"value":item.vat,"currency":item.currency,"decimals":2}})]}},{key:"totalTemplate",fn:function(ref){
var item = ref.item;
return [_c('MoneyDisplay',{attrs:{"value":item.total,"currency":item.currency,"decimals":2}})]}},{key:"details",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"documents-info pa-2"},[_c('KanriInvoiceItemsView',{attrs:{"head":item}})],1),_c('v-row',{staticClass:"pt-4",staticStyle:{"background":"white"}},[_c('v-col',{attrs:{"align":"right"}},[_c('label',{staticClass:"input-label input-label-name"},[_vm._v(" E-számla? ")])]),_c('v-col',[_c('v-switch',{attrs:{"dense":""},model:{value:(_vm.isEinvoice),callback:function ($$v) {_vm.isEinvoice=$$v},expression:"isEinvoice"}})],1),_c('v-col',{attrs:{"align":"right"}},[_c('label',{staticClass:"input-label input-label-name"},[_vm._v(" Teljesítés dátuma ")])]),_c('v-col',[_c('v-row',{staticClass:"align-center"},[_c('DateInput',{model:{value:(_vm.shipDate),callback:function ($$v) {_vm.shipDate=$$v},expression:"shipDate"}}),(_vm.valid)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mb-2",attrs:{"size":"20","color":"red"}},'v-icon',attrs,false),on),[_vm._v("fa-exclamation-circle")])]}}],null,true)},[_c('span',[_vm._v("Kötelező megadni!")])]):_vm._e()],1)],1),_c('v-col',{attrs:{"align":"right"}},[_c('label',{staticClass:"input-label input-label-name"},[_vm._v(" Megjegyzés ")])]),_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","single-line":"","dense":""},model:{value:(_vm.remark),callback:function ($$v) {_vm.remark=$$v},expression:"remark"}})],1)],1),_c('div',{staticClass:"d-flex justify-center align-center pb-4",staticStyle:{"background":"white"}},[(_vm.importInProgress)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"20","width":"3","color":"primary"}}):_vm._e(),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"primary","disabled":_vm.valid || _vm.importInProgress || item.preGenId > 1},on:{"click":function($event){return _vm.importKanri(item)}}},[_vm._v("Számla generálás")]),_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.showDetails(item)}}},[_vm._v("Mégse")])],1),_c('v-row',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"}},[(_vm.finalReportSrc != null && _vm.finalReportSrc.length > 0)?_c('PdfViewer',{staticStyle:{"width":"25%"},attrs:{"openOnNewPageButton":true,"src":_vm.finalReportSrc,"showExitButton":false}}):_vm._e()],1)]}}])},'ApiSearchPage',_vm.$attrs,false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }