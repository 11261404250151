
































































































































































import Vue from "vue";
import PartCust, { partCustDefaultProps } from "../models/PartCust";
import store, { PartnerStore } from "../services/PartnerStore";
import cashPointStore, { CashPointStore } from "../../cashPoint/services/CashPointStore";

interface Data {
  store: PartnerStore;
  cashPointStore: CashPointStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  data() {
    return {
      store,
      cashPointStore,
    };
  },

  async beforeMount() {
    window.scrollTo(0, 0);

    await Promise.all([
      this.store.loadCurrencies(),
      this.store.loadUsers(),
      this.store.loadPayModes(),
      this.cashPointStore.loadPartBanks(),
    ]);
    store.partCust = { ...partCustDefaultProps };
    store.partCust.partner = store.partner.id;
    console.log(store.partCust);
  },

  methods: {
    async savePartCust(item: PartCust) {
      await this.store.createPartCust(item);
      this.$router.push(`/partners/${store.partner.id}/${this.$route.params.source}`);
    },

    cancelCreatePartCust() {
      store.partCust = { ...partCustDefaultProps };
      this.$router.push(`/partners/${store.partner.id}/${this.$route.params.source}`);
    },
  },
});
