





























































import Vue from "vue";
import store, { InvoiceFlowStore } from "../services/InvoiceFlowStore";
import eventBus from "@/services/eventBus";
import permStore, {
  PermissionStore,
  InvoicePermissions,
} from "@/services/permissions";

interface Data {
  fileIsTooLarge: boolean;
  permStore: PermissionStore;
  store: InvoiceFlowStore;
  fileNote: string;
  page: number;
}

interface Methods {
  addFile(): void;
  openInNewTab(): void;
  fileSelected(): void;
}

interface Computed {
  isAttachmentEmpty: boolean;
  storeInvoiceId: number;
}

interface Props {
  invoiceId: number;
  isEditingEnabled: boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    invoiceId: Number,
    isEditingEnabled: Boolean,
  },

  data() {
    return {
      permStore,
      store,
      fileNote: "",
      page: 1,
      fileIsTooLarge: false,
    };
  },

  methods: {
    fileSelected() {
      const file: File = (this.$refs.file as any).files[0];
      if (!file) {
        this.fileIsTooLarge = false;
      } else if (file.size > 50 * 1024 * 1024) {
        this.fileIsTooLarge = true;
      } else {
        this.fileIsTooLarge = false;
      }
    },

    openInNewTab() {
      window.open(this.store.attachment.link, "_blank");
    },

    async addFile() {
      if (
        !this.permStore.has([InvoicePermissions.Recording])
        // !this.permStore.hasInvoice([InvoicePermissions.Controlling])
      ) {
        eventBus.$emit(
          "error",
          "Nincs jogosultsága a számla kép feltöltéshez!"
        );
        return;
      }

      const file: File = (this.$refs.file as any).files[0];
      if (!file) return;

      if (file.size > 50 * 1024 * 1024) {
        eventBus.$error("A számla kép mérete nem lehet nagyobb mint 50MB!.");
        return;
      }

      this.store.attachment = {
        id: 0,
        file: file,
        link: "",
        note: this.fileNote,
        name:
          this.store.invoice.id != 0
            ? this.store.invoice.id + file.name
            : "__replacewithgeniid__" + file.name,
        fileBase64: "",
        size: file.size,
      };

      if (this.store.invoice.id < 2 ) {
        eventBus.$emit("geni-picture:new");
      }


      if (this.store.invoice.id > 0) {
        await this.store.uploadGENIPicture(this.store.invoice.id);
        await this.store.loadGENIPicture(this.store.invoice.id);
      }
    },
  },

  computed: {
    storeInvoiceId() {
      return this.store.invoice.id;
    },

    isAttachmentEmpty() {
      return (
        this.store.attachment != undefined &&
        this.store.attachment.id == 0 &&
        this.store.attachment.file == ""
      );
    },
  },
});
