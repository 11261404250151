
















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import store, { InvoiceFlowStore } from "../services/InvoiceFlowStore";

import IncomingInvoiceTypeDisplay, { incomingInvoiceTypeDisplayDefaultProps } from "@/modules/incomingInvoice/models/IncomingInvoiceTypeDisplay";
import IncomingInvoiceSubtype, { incomingInvoiceSubtypeDisplayDefaultProps } from "@/modules/generalIngoingInvoice/models/IncomingInvoiceSubtype";
import PaymentModeDisplay, { paymentModeDisplayDefaultProps } from "@/modules/paymentMode/models/PaymentModeDisplay";
import DivisionItem, { divisionItemDefaultProps } from "@/modules/invoiceGroup/models/DivisionItem";
import InvoiceGroupDisplay, { invoiceGroupDisplayDefaultProps } from "@/modules/invoiceGroup/models/InvoiceGroupDisplay";
import IncomingInvoiceAccItem, { incomingInvoiceAccItemDefaultProps } from "../models/IncomingInvoiceAccItem"; 
import GeneralIngoingInvoice from "@/modules/generalIngoingInvoice/models/GeneralIngoinInvoice";
import VatDivisionDisplay, { vatDivDispDefaultProps } from "../models/VatDivisionDisplay";
import AccInvSError from "../models/AccInvSError";
import BankAccountPicker from "../components/BankAccountPicker.vue";
import PartBank, { partBankDefaultProps } from "@/modules/cashPoint/models/PartBank";
import FlowReviewGrid from "./FlowReviewGrid.vue";

import configuration, { AppConfiguration, loadConfiguration } from "@/models/Configuration";
import { deepCopy } from "@/utils/objects";
import MoneyInput from "@/components/MoneyInput.vue";
import InvoiceFlowApi from "../services/InvoiceFlowApi";
import eventBus from "@/services/eventBus";
import VatAnalytics from "../components/VatAnalytics.vue";
import CurrencyRateApi from "@/modules/currencyRate/services/CurrencyRateApi";
import ValidationError from "@/modules/downPayment/components/ValidationError.vue";
import IconButton from "@/components/IconButton.vue";
import { InvoiceItemsTable } from "@/modules/invoiceItems";
import itemStore, { InvoiceItemStore } from "../../invoiceItems/services/store";
import PartnerApi from "@/modules/partner/services/PartnerApi";


class AccCode {
  value = "";
  name = "";
}

interface Data {
  store: InvoiceFlowStore;
  invoiceID: number;
  selectedType: IncomingInvoiceTypeDisplay;
  selectedSubType: IncomingInvoiceSubtype;
  selectedPayMode: PaymentModeDisplay;
  selectedDivItem: DivisionItem;
  selectedInvGroup: InvoiceGroupDisplay;
  accountItemGroupOrder: string[];
  accountingPrecision: number;
  changedVatDivs: number[];
  updateVatStore: boolean;
  supplierID: number;
  
  isPageDisabled: boolean;
  backupItem: IncomingInvoiceAccItem;
  inEdit: boolean;
  itemIDToDelete: number;
  deleting: boolean;
  backupDim1: string;
  dim1editing: boolean;
  newItem: IncomingInvoiceAccItem;
  isNewItem: boolean;
  uninvoicedDelivery: boolean;
  isLoading: boolean;
  itemStore: InvoiceItemStore;
  selectedBankAccount: PartBank | undefined;
}

interface Computed {
  invoice: GeneralIngoingInvoice;
  accountItems: IncomingInvoiceAccItem[];
  simpleColumns: any[];
  hufInvoice: boolean;
  accCodes: AccCode[];
  columns: any[];
  newItemValidation: string;
  accInvSErrors: AccInvSError[];
  hasErrors: boolean;
  simpleAccountItems: IncomingInvoiceAccItem[];
  isAfterVatConfession: boolean;
  isAfterBooking: boolean;
}

interface Methods {
  getMarginUnderStockGroup(h: any, trElement: any, defaultSlots: any, props: any): any;
  tartozikStockGroupClass(item: IncomingInvoiceAccItem): string;
  kovetelStockGroupClass(item: IncomingInvoiceAccItem): string;
  save(showMessage: boolean ): void;
  addItemToChanged(dataItem: VatDivisionDisplay): void;
  saveVatDivItems(): void;

  hasNote(item: IncomingInvoiceAccItem): boolean;
  totalChanged(dataItem: IncomingInvoiceAccItem): void;
  currencyChanged(dataItem: IncomingInvoiceAccItem): void;
  setInEdit(dataItem: IncomingInvoiceAccItem): void;
  saveEdit(dataItem: IncomingInvoiceAccItem): void;
  cancelEdit(dataItem: IncomingInvoiceAccItem): void;
  setDelete(dataItem: IncomingInvoiceAccItem): void;
  cancelDelete(): void;
  confirmDelete(): void;
  setExpanded(dataItem: IncomingInvoiceAccItem): void;
  isDimensions(dataItem: IncomingInvoiceAccItem): boolean;
  setDimEdit(dataItem: IncomingInvoiceAccItem): void;
  saveDimEdit(dataItem: IncomingInvoiceAccItem): void;
  cancelDimEdit(dataItem: IncomingInvoiceAccItem): void;
  setNewItem(): void;
  cancelNewItem(): void;
  confirmNewItem(): void;
  showUninvoicedDelivery(stock: number): void;
  preAccounting(): Promise<void>;
  kontirSave(): Promise<void>;
  saveItems(): void;
  bankAccountSelected(partBank: PartBank): void;
}

export default Vue.extend<Data, Methods, Computed, {}>({
  components:
  {
      MoneyInput,
      VatAnalytics,
      ValidationError,
      IconButton,
      InvoiceItemsTable,
      BankAccountPicker,
      FlowReviewGrid
  },

  data() {
    return {
      store,
      invoiceID: 0,
      selectedType: { ...incomingInvoiceTypeDisplayDefaultProps },
      selectedSubType: { ...incomingInvoiceSubtypeDisplayDefaultProps },
      selectedPayMode: { ...paymentModeDisplayDefaultProps },
      selectedDivItem: { ...divisionItemDefaultProps },
      selectedInvGroup: { ...invoiceGroupDisplayDefaultProps },
      accountItemGroupOrder: ["Partner", "Áfa", "Készlet"],
      accountingPrecision: 2,
      changedVatDivs: [],
      updateVatStore: false,
      isPageDisabled: false,
      backupItem: { ...incomingInvoiceAccItemDefaultProps },
      inEdit: false,
      itemIDToDelete: 1,
      deleting: false,
      backupDim1: '',
      dim1editing: false,
      newItem: { ...incomingInvoiceAccItemDefaultProps },
      isNewItem: false,
      uninvoicedDelivery: false,
      isLoading: false,
      supplierID: 0,
      itemStore: itemStore,
      selectedBankAccount: { ...partBankDefaultProps },
    };
  },

  async beforeMount() {
      if (!configuration.isLoaded) {
          await loadConfiguration();
      }

      this.accountingPrecision = configuration?.getCurrDigits(this.store.invoice.invCurrency)?.accountingDigits ?? 0;

      this.invoiceID = Number(this.$route.params.invoiceId);
      this.supplierID = Number(this.$route.params.partnerId);
      await this.store.loadInvoice(this.invoiceID);
      this.store.loadInvoiceTypes();
      this.store.loadSubTypes();
      this.store.loadInvoicePaymentModes();
      this.store.loadDivisionItems();
      this.store.loadInvoiceGroups();
      this.store.loadAccountItems(this.invoiceID);
      this.store.getAccItemDetailedTypes();
      this.store.getAccInvSErrors(this.invoiceID);

      const partnerId = this.store.invoice.partner;
      await PartnerApi.getPartBanksWithTech(partnerId).then((result) => (this.store.partBanks = result));

      if (this.store.invoice.bankAccount > 1){
        this.selectedBankAccount = this.store.partBanks.find((x) => x.id == this.store.invoice.bankAccount);
        if (!this.selectedBankAccount && this.store.partBanks.length > 0) {
          this.selectedBankAccount = this.store.partBanks[0];
          this.store.invoice.bankAccount = this.store.partBanks[0].id;
        }
      }
  },

  methods: {
    bankAccountSelected(partBank) {
      this.store.invoice.bankAccount = partBank.id;
      this.selectedBankAccount = partBank;

      this.save(false);
    },

    async saveItems() {
      eventBus.$emit("invoice-items:saving", true);
      
      try {
        await this.itemStore.save();
      }
      finally {
        eventBus.$emit("invoice-items:saving", false);
      }
    },

    async kontirSave(){
      await this.store.saveAccountItems();
      await this.store.loadAccountItems(Number(this.$route.params.invoiceId));
      await this.store.getAccInvSErrors(this.invoiceID);
    },

    async preAccounting() {
      try {
        this.isLoading = true;

        await this.store.preAccounting(this.invoiceID)
        await this.store.loadAccountItems(this.invoiceID),
        await this.store.getAccInvSErrors(this.invoiceID),

        eventBus.$emit("success", `A kontírozás sikeresen lefutott!`);
      } catch (ex) {
        console.log(ex);
        eventBus.$emit("Hiba történt az automatikus kontírozás közben!");
        throw ex;

      } finally {
        this.isLoading = false;
      }
    },

    async showUninvoicedDelivery(stock: number) {
      this.uninvoicedDelivery = !this.uninvoicedDelivery;
      await this.store.loadUninvoicedDelivery(stock);
    },

    setNewItem() {
      this.newItem = { ...incomingInvoiceAccItemDefaultProps };
      this.newItem.accDate = this.store.invoice.accDate || "";
      this.isNewItem = true;
    },

    cancelNewItem() {
      this.isNewItem = false;
    },

    async confirmNewItem() {
      const type = this.store.accItemDetailedTypes.find(
        (x) => x.id == this.newItem.detailedTypeID
      );
      if (type) {
        this.newItem.detailedType = type.name;
      }

      /*await this.store.createAccountItem(this.newItem).then((id) => {
        this.newItem.id = id;
        // this.store.accountItems.push(this.newItem);
        this.store.accountItems = [...this.store.accountItems, this.newItem];
        this.isNewItem = false;
      });*/

      this.store.accountItems = [...this.store.accountItems, this.newItem];
      this.isNewItem = false;

      /*await this.store.loadAccountItems(Number(this.$route.params.invoiceId));
      await this.store.getAccInvSErrors(this.invoiceID);*/
    },
    cancelDimEdit(dataItem) {
      dataItem.dimCode1 = this.backupDim1;
      dataItem.dim1Edit = false;
      this.dim1editing = false;
    },

    async saveDimEdit(dataItem) {
      //await this.store.updateAccountItem(dataItem);
      
      dataItem.dim1Edit = false;
      this.dim1editing = false;
    },

    setDimEdit(dataItem) {
      this.backupDim1 = dataItem.dimCode1;

      dataItem.dim1Edit = true;
      this.dim1editing = true;
    },

    isDimensions(item) {
      const accCodeContains =
        item.accCode.startsWith("5") ||
        item.accCode.startsWith("8") ||
        item.accCode.startsWith("9");
      const accCodeDemandContains =
        item.accCodeDemand.startsWith("5") ||
        item.accCodeDemand.startsWith("8") ||
        item.accCodeDemand.startsWith("9");

      return accCodeContains || accCodeDemandContains;
    },

    setExpanded(dataItem) {
      dataItem.expanded = !dataItem.expanded;
      this.store.accountItems = [...this.store.accountItems];
    },

    setDelete(dataItem) {
      this.itemIDToDelete = dataItem.id;
      this.deleting = true;
    },

    async confirmDelete() {
      //await this.store.deleteAccountItem(this.itemIDToDelete);

      const item = this.store.accountItems.find(
        (x) => x.id == this.itemIDToDelete
      );
      if (item) {
        const index = this.store.accountItems.indexOf(item);
        this.store.accountItems.splice(index, 1);
        this.store.accountItems = [...this.store.accountItems];
      }

      this.deleting = false;
    },

    cancelDelete() {
      this.deleting = false;
    },

    cancelEdit(dataItem) {
      const item = this.store.accountItems.find((i) => i.id === dataItem.id);
      if (item) {
        Object.assign(item, { ...this.backupItem, inEdit: false });
        this.inEdit = false;
      }
    },

    async saveEdit(dataItem) {
        const item = this.store.accountItems.find((i) => i.id === dataItem.id);
        if (item) {
          //await this.store.updateAccountItem(item);
          item.inEdit = false;
          this.inEdit = false;
        }
      },

      setInEdit(dataItem) {
          this.backupItem = deepCopy(dataItem);
          const item = this.store.accountItems.find((i) => i.id === dataItem.id);

          if (item) {
              item.inEdit = true;
              this.inEdit = true;
              this.store.accountItems = [...this.store.accountItems];
          }
      },

      async currencyChanged(dataItem: IncomingInvoiceAccItem) {
          dataItem.exchangeRate = await CurrencyRateApi.getRateOnDate(
              dataItem.currency,
              this.store.invoice.rateDate
          );
          this.totalChanged(dataItem);
      },

      totalChanged(dataItem: IncomingInvoiceAccItem) {
          if (!dataItem.exchangeRate) return;

          if (dataItem.total != null && dataItem.currTotal != null) {
              dataItem.total = this.store.toDecimal(
              dataItem.currTotal * dataItem.exchangeRate,
              4
              );
          }

          if (dataItem.totalDemand != null && dataItem.currTotalDemand != null) {
              dataItem.totalDemand = this.store.toDecimal(
              dataItem.currTotalDemand * dataItem.exchangeRate,
              4
              );
          }
      },

      hasNote(item) {
          if (item && item.note && item.note.length > 1) {
              return item.note.toLowerCase().includes("ár");
          }
          return false;
      },

      async saveVatDivItems() {
          const changedItems = this.store.vatDivisionsDisplay.filter(x => this.changedVatDivs.includes(x.id));
          if (changedItems.length > 0){
              InvoiceFlowApi.saveVatDivisionItems(changedItems);
          }

          eventBus.$emit("success", "Sikerese mentés!");
      },

      addItemToChanged(dataItem: VatDivisionDisplay) {
      if (
          this.changedVatDivs.find((x) => x === dataItem.id) === undefined ||
          this.changedVatDivs.find((x) => x === dataItem.id) === null
      )
          this.changedVatDivs.push(dataItem.id);
      },

      kovetelStockGroupClass(item: IncomingInvoiceAccItem) {
          if (item.isDemand) {
              return item.groupClass;
          } else {
              return "";
          }
      },

      tartozikStockGroupClass(item: IncomingInvoiceAccItem) {
          if (!item.isDemand) {
              return item.groupClass;
          } else {
              return "";
          }
      },

      getMarginUnderStockGroup(h, trElement, defaultSlots, props) {
          let k = "";
          if (props.dataItem.groupClass == "groupStockItem") {
              k = "groupStockMargin";
          }

          const trProps = {
              class: k,
          };

          return h("tr", trProps, defaultSlots);
      },

      save(showMessage = true){
          const request = {
              invoiceId: this.invoiceID,
              type: this.store.invoice.type,
              subType: this.store.invoice.subType,
              paymentMode: this.store.invoice.paymode,
              division: this.store.invoice.divitem,
              accInvGroup: this.store.invoice.accGroup,
              remark: this.store.invoice.remark,
              payTerm: this.store.invoice.payTerm,
              accountItems: this.accountItems.map(x => ({ id: x.id, code: x.accCode, codeDemand: x.accCodeDemand, detailedType: x.detailedTypeID})),
              bankAccount: this.selectedBankAccount?.id ?? 1,
          };

          InvoiceFlowApi.updateSimple(request).then(_ => {
            if (showMessage) {
              eventBus.$emit("success", "Sikeres mentés!");
            }
          });
      }
  },

  watch: {
      invoice(){
          this.selectedType = this.store.types.find(x => x.id == this.store.invoice.type) || { ...incomingInvoiceTypeDisplayDefaultProps };
          this.selectedSubType = this.store.subtypes.find(x => x.id == this.store.invoice.subType) || { ...incomingInvoiceSubtypeDisplayDefaultProps };
          this.selectedPayMode = this.store.invoicePaymentModes.find(x => x.id == this.store.invoice.paymode) || { ...paymentModeDisplayDefaultProps };
          this.selectedDivItem = this.store.divisionItems.find(x => x.id == this.store.invoice.divitem) || { ...divisionItemDefaultProps };
          this.selectedInvGroup = this.store.accGroups.find(x => x.id == this.store.invoice.accGroup) || { ...invoiceGroupDisplayDefaultProps };
      },

      selectedType(){
          if (this.selectedType){
              this.store.invoice.type = this.selectedType.id;
          }
      },

      selectedSubType(){
          if (this.selectedSubType){
              this.store.invoice.subType = this.selectedSubType.id;
          }
      },

      selectedPayMode(){
          if (this.selectedPayMode){
              this.store.invoice.paymode = this.selectedPayMode.id;
          }
      },

      selectedDivItem(){
          if (this.selectedDivItem){
              this.store.invoice.divitem = this.selectedDivItem.id;
          }
      },

      selectedInvGroup(){
          if (this.selectedInvGroup){
              this.store.invoice.accGroup = this.selectedInvGroup.id;
          }
      }
  },

  computed:{
      isAfterVatConfession(){
        return this.store.invoice.vatStatus > 2;
      },

      isAfterBooking(){
        return this.store.invoice.accStatus  ==  4;
      },

      hasErrors() {
        return this.accInvSErrors.length > 0;
      },
      accInvSErrors() {
        return this.store.accInvSErrors;
      },

      newItemValidation() {
        if (this.newItem.detailedTypeID < 2)
          return "Kötelező kiválasztani a tétel típusát!";
        if (
          Number(this.newItem.total) === 0 &&
          Number(this.newItem.currTotal) === 0 &&
          Number(this.newItem.total) === 0 &&
          Number(this.newItem.currTotalDemand) === 0
        )
          return "Kötelező kitölteni a követel vagy tartozik oldalon az összeget!";
        if (
          this.newItem.accCode.length < 1 &&
          this.newItem.accCodeDemand.length < 1
        )
          return "Kötelező kitölteni a követel vagy tartozik oldalon a főkönyvi számot!";

        return "";
      },

      accCodes() {
          return this.store.accCodes.map((x) => ({
          value: x.code,
          name: `${x.code} - ${x.accName}`,
          }));
      },

      invoice(){
          return this.store.invoice;
      },

      accountItems() {
          const result = [...this.store.accountItems];
          const newList: IncomingInvoiceAccItem[] = [];
          const items: IncomingInvoiceAccItem[] = [];

          const groupAccessor = (item: IncomingInvoiceAccItem) => item.detailedType;

          const sortAccessor = (item: IncomingInvoiceAccItem) => this.accountItemGroupOrder.indexOf(item.detailedType);

          result.sort((a, b) => (sortAccessor(a) > sortAccessor(b) ? 1 : 0));

          /* console.log("raw items");
          console.log(result);
          console.log("--------"); */

          result.forEach((i) => {
              //head = a group's first item
              i.group = groupAccessor(i);
              i.head =
              i.id ===
                  result.find((o) => groupAccessor(i) === groupAccessor(o))?.id ?? 0;

              //For stock items if it has a invoiceitem-item, the original item's id is the item-item's groupWith prop
              if (i.groupWith > 1) {
              const f = result.find((x) => x.id == i.groupWith);
              i.groupClass = "groupStock";
              newList.push(i);
              if (f) {
                  f.groupClass = "groupStockItem";
                  newList.push(f);
              }
              } else if (!newList.find((y) => y.id == i.id)) {
              newList.push(i);
              }
          });

          //the stock group's first item is the head
          const stockHeads = newList.filter(
              (x) => x.group == this.accountItemGroupOrder[2] && x.head
          );
          //every other item, which is not stock group, can be pushed into the array
          const notStock = newList.filter(
              (x) => x.group != this.accountItemGroupOrder[2]
          );
          notStock.forEach((x) => {
              items.push(x);
          });

          //a new row for stock groups (just for rendering)
          const emptyRow = incomingInvoiceAccItemDefaultProps;
          emptyRow.accCode = "placehold";
          emptyRow.group = this.accountItemGroupOrder[2];
          emptyRow.head = true;

          //adding new rows for every kind of group (for rendering)
          for (let i = 0; i < newList.length; i++) {
              stockHeads.forEach((x) => {
              const ind = newList.indexOf(x);
              if (i === ind) {
                  items.push(emptyRow);
              }
              if (i >= ind) {
                  newList[i].head =
                  newList[i].group === "Készlet" ? false : newList[i].head;
                  items.push(newList[i]);
              }
              });
          }

          //
          let count = 0;
          items.forEach((element) => {
              count = 0;
              items.forEach((xa) => {
              if (
                  element.accCode === xa.accCode &&
                  element.currTotal === xa.currTotal &&
                  element.accNo === xa.accNo &&
                  element.currTotalDemand === xa.currTotalDemand &&
                  element.invItem === xa.invItem &&
                  element.invItemName === xa.invItemName &&
                  element.currency === xa.currency &&
                  element.stock === xa.stock &&
                  element.stockID === xa.stockID &&
                  element.accCodeDemand === xa.accCodeDemand &&
                  element.detailedType === xa.detailedType
              ) {
                  count++;
                  if (count > 1) {
                  let del = 0;

                  for (let index = items.length; index > 0; index--) {
                      const element = items[index];
                      if (xa === element) {
                      if (del > 0) {
                          continue;
                      }
                      items.splice(index, 1);
                      del++;
                      }
                  }
                  }
              }
              });
          });        

          return items;
      },

      simpleAccountItems(){
        //deep copy
        const items = JSON.parse(JSON.stringify(this.accountItems)) as IncomingInvoiceAccItem[];

        items.forEach(x => {
              x.inEdit = x.detailedType.toLocaleLowerCase() != "áfa";
          });

        return items;
      },

      simpleColumns() {
          const _columns: any[] = [
              {
              cell: "typeTemplate",
              width: 200,
              title: "Főkönyvi tétel típus",
              headerClassName: "font-weight-bold grey--text text--darken-3",
              },
              {
              field: "accCode",
              title: "Tartozik",
              cell: "tartozikTemplate",
              headerCell: "headerIncomeTemplate",
              },
              {
              width: 150,
              field: "total",
              title: "Összeg",
              cell: "tartozikTotalTemplate",
              headerCell: "headerIncomeTemplate",
              },
              {
              width: 150,
              field: "currTotal",
              title: "Összeg deviza",
              cell: "tartozikCurrTotalTemplate",
              headerCell: "headerIncomeTemplate",
              },
              {
              field: "accCodeDemand",
              title: "Követel",
              cell: "kovetelTemplate",
              headerCell: "headerDemandTemplate",
              },
              {
              width: 150,
              field: "totalDemand",
              title: "Összeg",
              cell: "kovetelTotalTemplate",
              headerCell: "headerDemandTemplate",
              },
              {
              width: 150,
              field: "currTotalDemand",
              title: "Összeg deviza",
              cell: "kovetelCurrTotalTemplate",
              headerCell: "headerDemandTemplate",
              },
              {
              field: "accDate",
              title: "Könyvelés dátuma",
              headerClassName: "font-weight-bold grey--text text--darken-3",
              cell: "accDateTemplate",
              width: 100,
              }                
          ];

          // Insert separator column
          const separatorIndex = this.hufInvoice ? 3 : 4;
          _columns.splice(separatorIndex, 0, {
              width: 5,
              cell: "separatorTemplate",
          });

          if (this.hufInvoice) {
              return _columns.filter(
              (c) => !["currTotal", "currTotalDemand"].includes(c.field || "")
              );
          }

          return _columns;
      },
      
      columns() {
          const _columns = [
              {
              width: 30,
              cell: "expandDetailTemplate",
              },
              {
              cell: "typeTemplate",
              width: 80,
              headerCell: "typeHeaderTemplate",
              },
              {
              field: "accCode",
              title: "Tartozik",
              cell: "tartozikTemplate",
              headerCell: "headerIncomeTemplate",
              },
              {
              width: 150,
              field: "total",
              title: "Összeg",
              cell: "tartozikTotalTemplate",
              headerCell: "headerIncomeTemplate",
              },
              {
              width: 150,
              field: "currTotal",
              title: "Összeg deviza",
              cell: "tartozikCurrTotalTemplate",
              headerCell: "headerIncomeTemplate",
              },
              {
              field: "accCodeDemand",
              title: "Követel",
              cell: "kovetelTemplate",
              headerCell: "headerDemandTemplate",
              },
              {
              width: 150,
              field: "totalDemand",
              title: "Összeg",
              cell: "kovetelTotalTemplate",
              headerCell: "headerDemandTemplate",
              },
              {
              width: 150,
              field: "currTotalDemand",
              title: "Összeg deviza",
              cell: "kovetelCurrTotalTemplate",
              headerCell: "headerDemandTemplate",
              },
              {
              field: "accDate",
              title: "Könyvelés dátuma",
              headerClassName: "font-weight-bold grey--text text--darken-3",
              cell: "accDateTemplate",
              width: 100,
              },
              { width: 50, cell: "commandsTemplate" },
          ];

          // Insert separator column
          const separatorIndex = this.hufInvoice ? 4 : 5;
          _columns.splice(separatorIndex, 0, {
              width: 5,
              cell: "separatorTemplate",
          });

          if (this.hufInvoice) {
              return _columns.filter(
              (c) => !["currTotal", "currTotalDemand"].includes(c.field || "")
              );
          }

          return _columns;
      },

      hufInvoice() {
          return this.store.invoice.invCurrency === "HUF";
      },
  }
});
