



























































































































































































































































































































































import MoneyInput from "@/components/MoneyInput.vue";
import UpdatingParameter from "../components/UpdatingParameter.vue";

import Vue from "vue";
import { SysParam, SystemParameters } from "../models/SysParam";
import configuration, { AppConfiguration } from "@/models/Configuration";
import configStore, { ConfigurationStore } from "../services/ConfigurationStore";
import ConfigurationApi from "../services/ConfigurationApi";
import CurrencyDigitsPage from "@/modules/currencyDigits/views/CurrencyDigitsPage.vue";
import User from "@/modules/user/models/User";

interface Data {
  configStore: ConfigurationStore;
  configuration: AppConfiguration;
  toleranceMax: number;
  roundMax: number;
  isCurrDigitsVisible: boolean;
  selectedToleranceApprovers: User[];
  usersVisible: boolean;
}

export default Vue.extend({
  components: { MoneyInput, UpdatingParameter, CurrencyDigitsPage },

  data: (): Data => ({
    configStore,
    configuration,
    toleranceMax: 1000,
    roundMax: 100,
    isCurrDigitsVisible: true,
    selectedToleranceApprovers: [],
    usersVisible: false,
  }),

  async beforeMount(){
    await this.configStore.getToleranceApprovers();
    this.selectedToleranceApprovers = await ConfigurationApi.getSeletedToleranceApprovers();
  },

  methods: {
    async toleranceApproversChangeHandler(){
      await configStore.updateToleranceApprovers(this.selectedToleranceApprovers);
    },

    async updateSysParam(param: SystemParameters | string, value: string | number | boolean | null): Promise<void> {
      await ConfigurationApi.updateSysParam(new SysParam(param, value));
    },

    async updateGeniSendEmail() {
      await this.updateSysParam(SystemParameters.GeniSendEmail, configuration.sysParams.geniSendEmail);
    },

    async updateGeniFulFillTolerance() {
      await this.updateSysParam(SystemParameters.GeniFulFillTolerance, configuration.sysParams.geniFulFillTolerance);
    },

    async updateGeniMaxRound() {
      configuration.sysParams.geniMaxRound =
        configuration.sysParams.geniMaxRound > this.roundMax ? this.roundMax : configuration.sysParams.geniMaxRound;

      await this.updateSysParam(SystemParameters.GeniMaxRound, configuration.sysParams.geniMaxRound);
    },

    async updateGeniTaskReminderDays() {
      await this.updateSysParam(SystemParameters.GeniTaskReminderDays, configuration.sysParams.geniTaskReminderDays);
    },

    async setGeniItemNoteVisible() {
      await this.configStore.setGeniItemNoteVisible(configuration.geniItemNoteVisible.enabled);
    },

    async setInvGroupsSaved() {
      await this.configStore.setInvGroupsSaved(configuration.invGroupsSaved.enabled);
    },

    async setBankDefrayerEnabled() {
      await this.configStore.setBankDefrayerEnabled(configuration.sysParams.bankDefrayerEnabled);
    },

    async setTaxChangeQuestionVisible() {
      await this.configStore.setTaxChangeQuestionVisible(configuration.taxChangeQuestionVisible.enabled);
    },

    async setIsInvPermit() {
      await this.configStore.setIsInvPermit(configuration.isInvPermit);
    },

    async updateIsGeniHUFItemPrecise() {
      await this.updateSysParam(SystemParameters.IsGeniHUFItemPrecise, configuration.sysParams.isGeniHUFItemPrecise);
    },

    async updateIsGeniExchangeRateEditEnabled() {
      await this.updateSysParam(
        SystemParameters.IsGeniExchangeRateEditEnabled,
        configuration.sysParams.isGeniExchangeRateEditEnabled
      );
    },

    async setSupplierRelationEnabled() {
      await this.configStore.setSupplierRelationEnabled(configuration.sysParams.automaticSupplierRelationEnabled);
    },
  },
});
