




























































import Page from "../../../components/Page.vue";
import IconButton from "@/components/IconButton.vue";

import Vue from "vue";
import dayjs from "dayjs";
import store, { CurrencyRateStore } from "../services/CurrencyRateStore";

interface Data {
  store: CurrencyRateStore;
  startDate: string;
  endDate: string;
  maxEndDate: string;
  currencyCUR: string;
}

export default Vue.extend({
  components: { Page, IconButton },

  data: (): Data => ({
    store,
    startDate: "",
    endDate: "",
    maxEndDate: "",
    currencyCUR: "",
  }),

  async beforeMount() {
    this.startDate = dayjs()
      .add(-1, "month")
      .format("YYYY-MM-DD");

    this.endDate = this.maxEndDate = dayjs().format("YYYY-MM-DD");

    await this.store.loadCurrencyRates(this.startDate, this.endDate);
    await this.store.loadCurrencies();
  },

  computed: {
    columns: () => [
      {
        field: "rateDate",
        title: "Árfolyam dátuma",
        type: "date",
        format: "{0: yyyy. MM. dd.}",
      },
      { field: "centralRate", title: "Középárfolyam" },
      { field: "currency", title: "Pénznem" },
      { field: "unit", title: "Egység" },
    ],

    currencySelectorValues() {
      const currs = ["Összes"];
      this.store.currencies.forEach((curr) => {
        currs.push(curr.currency);
      });

      return currs;
    },
  },

  methods: {
    create() {
      this.$router.push(`/currency-rates/create`);
    },

    async search() {
      if (this.currencyCUR === "Összes" || this.currencyCUR === "")
        await this.store.loadCurrencyRates(this.startDate, this.endDate);
      else await this.store.loadFilteredCurrencyRates(this.startDate, this.endDate, this.currencyCUR);
    },
  },
});
