export default interface BankOutView {
    code: string;
    prefix: string;
    invNo: string;
    depot: string;
    divItem: number;
    section: number;
    partner: string;
    pmode: string;
    invDate: Date | string;
    invTime: Date | string;
    shipDate: Date | string;
    pterm: Date | string;
    netto: number;
    total: number;
    vat: number;
    amount: number;
    status: string;
    dpaym: boolean;
    dpaymIncl: boolean;
    dpaymNetto: number;
    dpaymVat: number;
    accGroup: string;
    currency: string;
    car: number;
    withVat: string;
    user: string;
    bankAccount: string;
    worksheet: number;
    custOrd: number;
    norder: number;
    sorder: number;
    outerInvNo: string;
    bodyNo: string;
    regNum: string;
    remark1: string;
    remark2: string;
    invCurrency: string;
    currNetto: number;
    currTotal: number;
    currVat: number;
    exchangeRate: number;
    rateDate: Date | string;
    toPayment: number;
    currToPayment: number;
    sorted: boolean;
    selected: boolean;

    sortable: boolean;
    added: boolean;
    lockAction: string;
}

export const bankOutViewDefaultProps: BankOutView = {
    code: "",
    prefix: "",
    invNo: "",
    depot: "",
    divItem: 0,
    section: 0,
    partner: "",
    pmode: "",
    invDate: "1980. 01. 01.",
    invTime: "1980. 01. 01.",
    shipDate: "1980. 01. 01.",
    pterm: "1980. 01. 01.",
    netto: 0,
    total: 0,
    vat: 0,
    amount: 0,
    status: "",
    dpaym: false,
    dpaymIncl: false,
    dpaymNetto: 0,
    dpaymVat: 0,
    accGroup: "",
    currency: "",
    car: 0,
    withVat: "",
    user: "",
    bankAccount: "",
    worksheet: 0,
    custOrd: 0,
    norder: 0,
    sorder: 0,
    outerInvNo: "",
    bodyNo: "",
    regNum: "",
    remark1: "",
    remark2: "",
    invCurrency: "",
    currNetto: 0,
    currTotal: 0,
    currVat: 0,
    exchangeRate: 0,
    rateDate: "1980. 01. 01.",
    toPayment: 0,
    currToPayment: 0,
    selected: false,
    sorted: false,


    sortable: false,
    added: false,
    lockAction: ""
}