
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import store, { PartnerStore } from "../services/PartnerStore";
import bStore, { BankStore } from "../../bank/services/BankStore";
import cStore, { CityStore } from "../../city/services/CityStore";
import dtStore, { DocumentTemplateStore } from "../../documentTemplate/services/DocumentTemplateStore";
import iStore, { InvoiceFlowStore } from "../../invoiceFlow/services/InvoiceFlowStore";
import PartBank from "../../cashPoint/models/PartBank";
import PartSupplier from "../models/PartSupplier";
import Partner from "../models/Partner";
import cashPointStore, { CashPointStore } from "../../cashPoint/services/CashPointStore";
import PartPerson from "../models/PartPerson";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import PartBankEditPage from "./PartBankEditPage.vue";
import PartnerEuTax from "../models/PartnerEuTax";
import configuration from "../../../models/Configuration";
import FinancialDocumentView from "./FinancialDocumentView.vue";
import PartnerCustomsPage from "./PartnerCustomsPage.vue";
import PartnerEditorView from "./PartnerEditorView.vue";
import PartnerPage from "./PartnerPage.vue";
import PartSupplierCreatePage from "./PartSupplierCreatePage.vue";
import PartBankCreateComponent from "../components/PartBankCreateComponent.vue";
import DocumentBrowser from "@/modules/document/views/DocumentBrowser.vue";
import BalanceStatementEmail from "../models/BalanceStatementEmail";
import PartnerBalanceEmailLog from "./PartnerBalanceEmailLog.vue";

interface Data {
  store: PartnerStore;
  bStore: BankStore;
  cStore: CityStore;
  dtStore: DocumentTemplateStore;
  iStore: InvoiceFlowStore;
  editing: boolean;
  cashPointStore: CashPointStore;
  partnerId: number;

  sort: SortDescriptor[];
  filter: CompositeFilterDescriptor;

  sortPerson: SortDescriptor[];
  filterPerson: CompositeFilterDescriptor;

  sortSupplier: SortDescriptor[];
  filterSupplier: CompositeFilterDescriptor;

  partBankEditorVisible: boolean;
  duration: number;
  offset: number;
  easing: string;

  sameAddress: boolean;
  partBankColumns: any;
  partSupplierColumns: any;
  partPersonColumns: any;
  balanceStatementColumns: any;

  valid: boolean;

  euTaxCreating: boolean;
  newEuTax: string;
  partnerEuTaxes: PartnerEuTax[];
  partnerEuTaxesToDelete: number[];
  configuration: any;

  partsupplierCreating: boolean;
  partbankCreating: boolean;
}

interface Computed {
  options: any;
  partBankItems: any;
  partSupplierItems: any;
  partPersonItems: any;
  urlFilterId: number;
}

export default Vue.extend<Data, {}, Computed, {}>({
  components: {
    NumericTextBox,
    PartBankEditPage,
    FinancialDocumentView,
    PartnerCustomsPage,
    // PartnerEditorView,
    PartnerPage,
    PartSupplierCreatePage,
    PartBankCreateComponent,
    DocumentBrowser,
    PartnerBalanceEmailLog
  },

  data: () => ({
    store,
    bStore,
    cStore,
    dtStore,
    iStore,
    partnerId: 1,

    editing: false,
    cashPointStore,

    euTaxCreating: false,
    newEuTax: "",
    partnerEuTaxes: [],

    sort: [],
    filter: {
      logic: "and",
      filters: [],
    },

    sortPerson: [],
    filterPerson: {
      logic: "and",
      filters: [],
    },

    sortSupplier: [],
    filterSupplier: {
      logic: "and",
      filters: [],
    },

    partBankEditorVisible: false,
    duration: 800,
    offset: 60,
    easing: "easeInOutQuad",

    sameAddress: false,

    partsupplierCreating: false,
    partbankCreating: false,

    partBankColumns: [
      { field: "bank", title: "Bank" },
      { field: "accountNo", title: "Bankszámla szám" },
      {
        field: "own",
        title: "Saját",
        type: "Boolean",
        editor: "boolean",
        cell: "isOwnTemplate",
      },
      {
        field: "primaryAccNo",
        title: "Egyszámla",
        editor: "boolean",
        cell: "isPrimaryAccNoTemplate",
      },
      {
        field: "technical",
        title: "Technikai",
        editor: "boolean",
        cell: "isTechnicalTemplate",
      },
      // {
      //   field: "deleted",
      //   title: "Nem élő",
      //   editor: "boolean",
      //   cell: "deletedTemplate",
      // },
      { field: "ibanNo", title: "IBAN" },
      { field: "swiftNo", title: "SWIFT" },
      {
        field: "isAutoCount",
        title: "Automatikus kivonat sorszámozás",
        editor: "boolean",
        cell: "isAutoCountTemplate",
      },
      {
        field: "autoCountFrom",
        title: "Tényleges sorszám kezdete",
        editor: "numeric",
      },
      {
        field: "autoCountTo",
        title: "Tényleges sorszám vége",
        editor: "numeric",
      },
      {
        field: "isNative",
        title: "Belföldi",
        editor: "boolean",
        cell: "isNativeTemplate",
      },
      { cell: "commandTemplate", filterable: false, width: "52px" },
    ],

    partSupplierColumns: [
      // { field: "deleted", title: "Nem élő", editor: "boolean", cell: "isDeletedTemplate" },
      { field: "suplType", title: "Szállító típusa", cell: "suplTypeTemplate" },
      {
        field: "ptermDay",
        title: "Fizetési határidő napokban",
        editor: "numeric",
        className: "right_text adjust_right",
      },
      { field: "defCurrency", title: "Alapértelmezett pénznem", cell: "currencyTemplate" },
      { field: "payMode", title: "Fizetési mód", cell: "paymentModeTemplate" },
      { field: "isCashAccounting", title: "Pénzforgalmi áfás?", cell: "isCashAccounting" },
      { cell: "commandTemplate", width: "52px", filterable: false },
    ],

    partPersonColumns: [
      { field: "name", title: "Teljes név" },
      { field: "profession", title: "Beosztás" },
      { field: "mobile", title: "Telefonszám" },
      { field: "email", title: "Email" },
      { field: "otherNo", title: "Egyéb ID" },
      { field: "address", title: "Lakcím" },
      { field: "city", title: "Város" },
      { field: "postcode", title: "Irányítószám" },
      { field: "sex", title: "Nő/férfi", editor: "boolean", cell: "sexTemplate" },
      { cell: "commandTemplate", width: 52, filterable: false },
    ],

    balanceStatementColumns: [
      { field: "email", title: "Email", cell: "emailTemplate" },
      { cell: "commandTemplate", width: 110, filterable: false },
    ],

    valid: true,
    partnerEuTaxesToDelete: [],
    configuration,
  }),

  async beforeMount() {
    const partnerId = Number(this.$route.params.partnerId);
    this.partnerId = partnerId;
    window.scrollTo(0, 0);

    await Promise.all([
      this.store.loadPartner(partnerId),
      this.store.loadPartBanks(partnerId),
      this.store.loadPartSuppliers(partnerId),
      this.store.loadPartEmployee(partnerId),
      this.store.loadPartCust(partnerId),
      this.store.loadPartPersons(partnerId),
      this.store.loadPartnerPriSupl(partnerId),
      this.store.loadBalanceStatementEmails(partnerId),
      this.cStore.loadCities(),
    ]);

    await Promise.all([
      this.store.loadUsers(),
      this.store.loadPartnerTypes(),
      this.store.loadPartnerStatuses(),
      this.store.loadSpecialTaxes(),
      this.store.loadPayModes(),
      this.store.loadCompanyForms(),
      this.store.loadPayClasses(),
      this.store.loadPartnerGroups1(),
      this.store.loadPartnerGroups2(),
      this.store.loadCurrencies(),
      this.store.loadSuplTypes(),
      this.cashPointStore.loadPartBanks(),
      this.bStore.loadBanks(),
      this.dtStore.loadDocumentTemplates(),
      this.iStore.loadPartnerTaxes(),
      this.store.loadPartnerEuTaxesByPartnerId(),
    ]);

    //extendedPartnerInfo
    if (configuration.partner.isExtendedPartnerInfoEnabled) {
      await this.store.loadPrePartnerNames();
      await this.store.loadStreetTypes();
    }
  },

  computed: {
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing,
      };
    },

    partBankItems() {
      return orderBy(filterBy(this.store.partBanks, this.filter), this.sort);
    },

    partSupplierItems() {
      return orderBy(filterBy(this.store.partSuppliers, this.filterSupplier), this.sortSupplier);
    },

    partPersonItems() {
      return orderBy(filterBy(this.store.partPersons, this.filterPerson), this.sortPerson);
    },

    urlFilterId() {
      const id = +this.$route.params.partnerId;
      return id;
    },
  },

  methods: {
    sendBalanceEmails(){
      this.store.sendBalanceEmails();
    },

    createBalanceStatement(){
      this.store.createBalanceStatementEmail(Number(this.$route.params.partnerId));
    },

    modifyBalanceStatement(entity: BalanceStatementEmail){
      this.store.modifyBalanceStatementEmail(entity);
      entity.inEdit = false;
    },

    deleteBalanceStatement(entity: BalanceStatementEmail){
      this.store.deleteBalanceStatementEmail(entity);
    },

    redirectToFlow() {
      if (this.$route.params.source != undefined) {
        this.$router.push(`/general-incoming-invoice/flow/edit/${Number(this.$route.params.source)}/1`);
      }
    },

    partnerHandler() {
      this.$vuetify.goTo("#partner_top", this.options);
    },

    filterEuTaxAccountFromPartner() {
      this.store.partner.euTaxAccount = "";
    },

    clickHandler(p: PartnerEuTax) {
      this.store.partner.euTaxAccount = p.taxAccount;
    },

    filterEuTaxHandler(item: PartnerEuTax) {
      this.store.partnerEuTaxes = this.store.partnerEuTaxes.filter((x) => x.id != item.id);
      this.store.partnerEuTaxes = [...this.store.partnerEuTaxes];

      if (item.id > 1) {
        this.partnerEuTaxesToDelete.push(item.id);
      }
    },

    specTaxHandler() {
      this.store.partner.specTaxId = 0;
    },

    saveCreatePartnerTaxAccount() {
      if (this.newEuTax.length > 1) {
        this.partnerEuTaxes.unshift({
          id: 1,
          partner: this.store.partner.id,
          taxAccount: this.newEuTax,
        });

        this.store.partnerEuTaxes.unshift({
          id: 1,
          partner: this.store.partner.id,
          taxAccount: this.newEuTax,
        });
        this.store.partnerEuTaxes = [...this.store.partnerEuTaxes];

        this.newEuTax = "";
        this.euTaxCreating = false;
      }
    },

    cancelCreatePartnerTaxAccount() {
      this.newEuTax = "";
      this.euTaxCreating = false;
    },

    clearSearch() {
      if (this.store.partner.docTemplate != "-") {
        this.store.partner.docTemplate = "-";
      }
    },

    nativeChangeHandler() {
      if (this.store.partner.native) {
        this.store.partner.countryName = "MAGYARORSZÁG";
        this.store.partner.specTax = false;
      } else {
        this.store.partner.countryName = "";
        this.store.partner.specTax = true;
      }
    },

    partPersonColumnReorder(options: any) {
      this.partPersonColumns = options.columns;
    },

    partSupplierColumnReorder(options: any) {
      this.partSupplierColumns = options.columns;
    },

    partBankColumnReorder(options: any) {
      this.partBankColumns = options.columns;
    },

    findCityHandler() {
      if (this.store.partner.postCode.length >= 4) {
        const cityName = this.cStore.cities.find((x) => x.postCode == this.store.partner.postCode)?.name ?? "";
        this.store.partner.invoiceCity = cityName;
      }
    },

    findCorCityHandler() {
      if (this.store.partner.corPostCode.length >= 4) {
        const corCityName = this.cStore.cities.find((x) => x.postCode == this.store.partner.corPostCode)?.name ?? "";
        this.store.partner.corCity = corCityName;
      }
    },

    sameAddressHandler() {
      this.store.partner.corCity = this.store.partner.invoiceCity;
      this.store.partner.corPostCode = this.store.partner.postCode;
      this.store.partner.corAddress = this.store.partner.invoiceAddress;
    },

    //partBank
    editPartBank(item: PartBank) {
      //this.store.setPartBankEditing(item);
      this.store.partBank = { ...item };
      this.partBankEditorVisible = true;
    },

    // partBankChange({ dataItem, value, field }: { dataItem: PartBank; value: any; field: string }) {
    //   const item = dataItem;
    //   const update = {
    //     [field]: value,
    //   };

    //   console.log(update);
    //   this.store.updatePartBankElement(item, update);
    // },

    cancelPartBankEdit(item: PartBank) {
      item.inEdit = false;
      this.store.savePartBank(this.store.partBank);
    },

    createPartBank(partnerId: number) {
      this.partbankCreating = true;
      this.partBankEditorVisible = false;
      // this.$router.push(`/partners/${partnerId}/${this.$route.params.source}/create-part-bank`);
    },

    partBankCreating(args: boolean) {
      this.partbankCreating = args;
    },

    filterChange(e: { filter: CompositeFilterDescriptor }) {
      this.filter = e.filter;
    },

    sortChange(e: { sort: SortDescriptor[] }) {
      this.sort = e.sort;
    },

    async hidePartBankEditor(value: boolean) {
      if (value) {
        await this.store.loadPartBanks(Number(this.$route.params.partnerId));
      }

      this.partBankEditorVisible = false;
    },

    partBankHandler() {
      this.$vuetify.goTo("#partBank_top", this.options);
    },

    //partSupplier
    editPartSupplier(item: PartSupplier) {
      this.store.setPartSupplierEditing(item);
    },

    partSupplierChange({ dataItem, value, field }: { dataItem: PartSupplier; value: any; field: string }) {
      const item = dataItem;
      const update = {
        [field]: value,
      };

      //console.log(update);
      this.store.updatePartSupplierElement(item, update);
    },

    cancelPartSupplierEdit(item: PartSupplier) {
      item.inEdit = false;
      this.store.savePartSupplier(item);
    },

    createPartSupplier(partnerId: number) {
      // this.$router.push(`/partners/${partnerId}/${this.$route.params.source}/create-part-supplier`);
      this.partsupplierCreating = true;
    },

    partSupplierCreating(args: boolean) {
      this.partsupplierCreating = args;
    },

    filterChangeSupplier(e: { filter: CompositeFilterDescriptor }) {
      this.filterSupplier = e.filter;
    },

    sortChangeSupplier(e: { sort: SortDescriptor[] }) {
      this.sortSupplier = e.sort;
    },

    partSupplHandler() {
      this.$vuetify.goTo("#partSuppl_top", this.options);
    },

    //partner
    setEdit() {
      this.store.partnersBackup.push({ ...this.store.partner });
      this.store.inEdit = true;
    },

    noEdit() {
      const foundPartner = this.store.partnersBackup.find((x) => x.id === this.store.partner.id);

      if (foundPartner) {
        this.store.partner = foundPartner;
      }

      this.store.partnersBackup = this.store.partnersBackup.filter((x) => x.id !== this.store.partner.id);

      this.store.partnerEuTaxes = this.store.partnerEuTaxes.filter(
        (ar) => !this.partnerEuTaxes.find((rm) => rm.taxAccount == ar.taxAccount)
      );
      this.partnerEuTaxes = [];
      this.euTaxCreating = false;
    },

    async save(item: Partner) {
      await this.store.updatePartner(item);

      if (this.partnerEuTaxes) {
        await this.store.createPartnerEuTaxes(this.partnerEuTaxes);
      }
      if (this.partnerEuTaxesToDelete) {
        await this.store.deletePartnerEuTax(this.partnerEuTaxesToDelete);
      }

      if (this.configuration.partner.isExtendedPartnerInfoEnabled) {
        await this.store.updateExtendedPartnerInfo();
      }

      this.store.inEdit = false;

      this.partnerEuTaxesToDelete = [];
      this.partnerEuTaxes = [];
    },

    //partEmployee
    setPartEmployeeEdit() {
      this.store.partEmployeeBackup.push({ ...this.store.partEmployee });
      this.store.partEmployee.inEdit = true;
    },

    partEmployeeNoEdit() {
      const foundEmployee = this.store.partEmployeeBackup.find((x) => x.id === this.store.partEmployee.id);

      if (foundEmployee) {
        this.store.partEmployee = foundEmployee;
      }

      this.store.partEmployeeBackup = this.store.partEmployeeBackup.filter((x) => x.id !== this.store.partEmployee.id);
    },

    async savePartEmployee() {
      await this.store.savePartEmployee();
      this.store.partEmployee.inEdit = false;
    },

    partEmployeeHandler() {
      this.$vuetify.goTo("#partEmployee_top", this.options);
    },

    //partCust
    setPartCustEdit() {
      this.store.partCustBackup.push({ ...this.store.partCust });
      this.store.partCust.inEdit = true;
    },

    partCustNoEdit() {
      const foundPartCust = this.store.partCustBackup.find((x) => x.id === this.store.partCust.id);

      if (foundPartCust) {
        this.store.partCust = foundPartCust;
      }

      this.store.partCustBackup = this.store.partCustBackup.filter((x) => x.id !== this.store.partCust.id);
    },

    async savePartCust() {
      await this.store.savePartCust(store.partCust);
      this.store.partCust.inEdit = false;
    },

    createPartCust(partnerId: number) {
      this.$router.push(`/partners/${partnerId}/${this.$route.params.source}/create-part-customer`);
    },

    partCustHandler() {
      if (this.store.partCust.id === 0) {
        this.$vuetify.goTo("#partCust_top_new", this.options);
      }

      if (this.store.partCust.id !== 0) {
        this.$vuetify.goTo("#partCust_top", this.options);
      }
    },

    //partPerson
    editPartPerson(item: PartPerson) {
      this.store.setPartPersonEditing(item);
    },

    partPersonChange({ dataItem, value, field }: { dataItem: PartPerson; value: any; field: string }) {
      const item = dataItem;
      const update = {
        [field]: value,
      };

      //console.log(update);
      this.store.updatePartPersonElement(item, update);
    },

    cancelPartPersonEdit(item: PartPerson) {
      item.inEdit = false;
      this.store.savePartPerson(this.store.partPerson);
    },

    createPartPerson(partnerId: number) {
      this.$router.push(`/partners/${partnerId}/${this.$route.params.source}/create-part-person`);
    },

    filterChangePerson(e: { filter: CompositeFilterDescriptor }) {
      this.filterPerson = e.filter;
    },

    sortChangePerson(e: { sort: SortDescriptor[] }) {
      this.sortPerson = e.sort;
    },

    partPersonHandler() {
      this.$vuetify.goTo("#partPerson_top", this.options);
    },

    balanceStatementHandler() {
      this.$vuetify.goTo("#balanceStatement_top", this.options);
    },

    balanceStatementLogHandler() {
      this.$vuetify.goTo("#balanceStatement_log_top", this.options);
    },

    vamhivatalrelaciokHandler() {
      this.$vuetify.goTo("#vamhivatal_top", this.options);
    },

    financialDocumentHandler() {
      this.$vuetify.goTo("#financialDocument_top", this.options);
    },

    allDocumentHandler() {
      this.$vuetify.goTo("#allDocument_top", this.options);
    },
  },
});
