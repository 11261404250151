import KanriInvoiceApi from "./KanriInvoiceApi";
import PaymentModeApi from "../../paymentMode/services/PaymentModeApi"
import PaymentModeDisplay from "@/modules/paymentMode/models/PaymentModeDisplay";
import CashPointApi from "../../cashPoint/services/CashPointApi";
import Country from "@/modules/cashPoint/models/Country";
import NoticeApi from "../../notice/services/NoticeApi";
import Language from "@/modules/notice/models/Language";
import NoticeGenoType from "@/modules/notice/models/NoticeGenoType";
import PreInvoiceType from "../models/PreInvoiceType";
import DivisionItem from "@/modules/invoiceGroup/models/DivisionItem";
import InvoiceGroupApi from "@/modules/invoiceGroup/services/InvoiceGroupApi";
import GenoExperimentalInvoice from "../models/GenoExperimentalInvoice";
import PartBank from "@/modules/cashPoint/models/PartBank";
import BankRemitApi from "@/modules/bankRemit/services/BankRemitApi";
import busyIndication from "@/utils/busyIndication";
import CashPointSelect from "@/modules/cashPoint/models/CashPointSelect";
import chatHub from "@/plugins/chatHub";
export class KanriInvoiceStore {
    paymentModes: PaymentModeDisplay[] = []
    currencies: Country[] = []
    languages: Language[] = []
    genoTypes: NoticeGenoType[] = []
    preInvoiceTypes: PreInvoiceType[] = []
    accGroups: any[] = []
    divisionItems: DivisionItem[] = []
    preGenInvStatuses: any[] = []
    partBanks: PartBank[] = []
    isUserCashier = false
    cashPoints: CashPointSelect[] = []

    async getItemsByHead(head: number) {
        const result = await KanriInvoiceApi.getItemsByHead(head);
        return result
    }

    @busyIndication()
    async importKanri(id: number, payload: any) {
        try {
            const ret = await KanriInvoiceApi.importKanri(id, payload);
            return ret;
        } catch (err) {
            // TODO: notification
            console.error(err);
        }
    }

    @busyIndication()
    async importCarAgreement(objectId: string, id: number) {
        try {
            const ret = await KanriInvoiceApi.importCarAgreement(objectId, id);
            return ret;
        } catch (err) {
            // TODO: notification
            console.error(err);
        }
    }

    async createProformGeno(id: number, payload: any) {
        try {
            const ret = await KanriInvoiceApi.createProformGeno(id, payload);
            return ret;
        } catch (err) {
            // TODO: notification
            console.error(err);
        }
    }

    async refreshInvoice(id: number, type: string) {
        try {
            const ret = await KanriInvoiceApi.refreshInvoice(id, type);
            return ret;
        } catch (err) {
            // TODO: notification
            console.error(err);
        }
    }

    @busyIndication()
    async updateGenoHeadAndItems(head: GenoExperimentalInvoice, items: any[]) {
        try {
            return await KanriInvoiceApi.updateGenoHeadAndItems(head, items);
        } catch (err) {
            // TODO: notification
            console.error(err);
        }
    }

    @busyIndication()
    async updateStatus(id: number, payload: any) {
        try {
            return await KanriInvoiceApi.updateStatus(id, payload);
        } catch (err) {
            // TODO: notification
            console.error(err);
        }
    }

    @busyIndication()
    async deleteGeno(id: number) {
        try {
            await KanriInvoiceApi.deleteGeno(id);
        } catch (err) {
            // TODO: notification
            console.error(err);
        }
    }

    async loadCrystalReport(id: number) {
        return await KanriInvoiceApi.loadCrystalReport(id);
    }

    async pay(id: number) {
        return await KanriInvoiceApi.pay(id);
    }

    async payWithCashPoint(id: number, cashPoint: number) {
        return await KanriInvoiceApi.payWithCashPoint(id, cashPoint);
    }

    async setPaid(id: number) {
        return await KanriInvoiceApi.setPaid(id);
    }

    async setPaidStatus(id: number) {
        return await KanriInvoiceApi.setPaidStatus(id);
    }

    @busyIndication()
    async updateGenoItem(item: any) {
        try {
            await KanriInvoiceApi.updateGenoItem(item);
        } catch (err) {
            // TODO: notification
            console.error(err);
        }
    }

    //dropdowns
    async getPaymentModes() {
        this.paymentModes = await PaymentModeApi.getAll();
    }

    async getCurrencies() {
        this.currencies = await CashPointApi.getCurrencies();
    }

    async getIsCashier() {
        this.isUserCashier = await CashPointApi.getIsCashier();
    }

    async getCashPoints() {
        this.cashPoints = await CashPointApi.getCashPoints();
    }

    async getLanguages() {
        this.languages = await NoticeApi.getLanguages();
    }

    async getGenoTypes() {
        this.genoTypes = await NoticeApi.getNoticeGenoTypes();
    }

    async getPreInvoiceTypes() {
        this.preInvoiceTypes = await KanriInvoiceApi.getPreInvoiceTypes();
    }

    async getAccGroups() {
        this.accGroups = await KanriInvoiceApi.getAccGroups();
    }

    async getDivItems() {
        this.divisionItems = await InvoiceGroupApi.getDivisionItems();
    }

    async getPreGenInvStatuses() {
        this.preGenInvStatuses = await KanriInvoiceApi.getPreGenInvStatuses();
    }

    async getPartBanksByPartner(id: number) {
        this.partBanks = await BankRemitApi.getPartBanksByPartner(id);
    }

    async downSyncPartner(id: number) {
        try {
            await KanriInvoiceApi.downSyncPartner(id);
        } catch (err) {
            // TODO: notification
            console.error(err);
        }
    }
}

const store = new KanriInvoiceStore();
export default store;