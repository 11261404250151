import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import auth from "@/services/auth";
import configuration from "@/models/Configuration";

// #region - Components
import LoginPage from "@/modules/login/views/Login.vue";
import Home from "@/views/Home.vue";
import SettingsPage from "@/views/Settings/SettingsPage.vue";
import ChangePasswordPage from "@/views/Settings/Pages/ChangePasswordPage.vue";

import BanksPage from "@/modules/bank/views/BanksPage.vue";
import BankPage from "@/modules/bank/views/BankPage.vue";

import InvoiceTypesPage from "@/modules/invoiceType/views/InvoiceTypesPage.vue";
import InvoiceTypePage from "@/modules/invoiceType/views/InvoiceTypePage.vue";

import AccountActionSourcesPage from "@/modules/accountActionSource/views/AccountActionSourcesPage.vue";
import AccountActionSourcePage from "@/modules/accountActionSource/views/AccountActionSourcePage.vue";

import AccountTypePage from "@/modules/accountType/views/AccountTypePage.vue";
import AccountTypesPage from "@/modules/accountType/views/AccountTypesPage.vue";
import CashPointsPage from "@/modules/cashPoint/views/CashPointsPage.vue";
import CashPointPage from "@/modules/cashPoint/views/CashPointPage.vue";

import InvoiceGroupsPage from "@/modules/invoiceGroup/views/InvoiceGroupsPage.vue";
import InvoiceGroupPage from "@/modules/invoiceGroup/views/InvoiceGroupPage.vue";

import PaymentModesPage from "@/modules/paymentMode/views/PaymentModesPage.vue";
import PaymentModePage from "@/modules/paymentMode/views/PaymentModePage.vue";

import PartnerDetailsPage from "@/modules/partner/views/PartnerDetailsPage.vue";
import PartnerEditPage from "@/modules/partner/views/PartnerEditPage.vue";
import PartnerPage from "@/modules/partner/views/PartnerPage.vue";
import PartBankCreatePage from "@/modules/partner/views/PartBankCreatePage.vue";
import PartSupplierCreatePage from "@/modules/partner/views/PartSupplierCreatePage.vue";
import PartCustCreatePage from "@/modules/partner/views/PartCustCreatePage.vue";
import PartPersonCreatePage from "@/modules/partner/views/PartPersonCreatePage.vue";
import DocumentBrowser from "@/modules/document/views/DocumentBrowser.vue";
import DocumentCreatePage from "@/modules/document/views/DocumentCreatePage.vue";

import NoticesPage from "@/modules/notice/views/NoticesPage.vue";
import NoticePage from "@/modules/notice/views/NoticePage.vue";
import NoticePartnerPage from "@/modules/notice/views/PartnerSearch.vue";

import OutgoingInvoiceTypeEditModelsPage from "@/modules/outgoingInvoiceType/views/OutgoingInvoiceTypeEditModelsPage.vue";
import OutgoingInvoiceTypeEditModelPage from "@/modules/outgoingInvoiceType/views/OutgoingInvoiceTypeEditModelPage.vue";

import GeneralIngoingInvoicesPage from "@/modules/generalIngoingInvoice/views/GeneralIngoingInvoicesPage.vue";
import GeneralIngoingInvoicePartnerSearch from "@/modules/generalIngoingInvoice/views/PartnerEditorView.vue";
import InvoiceFlowPage from "@/modules/invoiceFlow/views/InvoiceFlowPage.vue";
import InvoiceFlowSettingsPage from "@/modules/invoiceFlow/views/InvoiceFlowSettingsPage.vue";
import ApprovalMatrixPage from "@/modules/approvalMatrix/views/ApprovalMatrixPage.vue";
import InvoiceEditAfterVatConfession from "@/modules/invoiceFlow/views/InvoiceEditVatConfession.vue"

import InvoiceBrowser from "@/modules/invoiceBrowser/InvoiceBrowser.vue";
import TechnicalInvoiceBrowser from "@/modules/technicalInvoice/views/TechnicalInvoiceBrowser.vue";

import CurrencyRatesPage from "@/modules/currencyRate/views/CurrencyRatesPage.vue";
import CurrencyRatePage from "@/modules/currencyRate/views/CurrencyRatePage.vue";

import IncomingInvoiceTypesPage from "@/modules/incomingInvoice/views/IncomingInvoiceTypesPage.vue";
import IncomingInvoiceTypePage from "@/modules/incomingInvoice/views/IncomingInvoiceTypePage.vue";

import AccountVoucherTypesPage from "@/modules/accountVoucherType/views/AccountVoucherTypesPage.vue";
import AccountVoucherTypePage from "@/modules/accountVoucherType/views/AccountVoucherTypePage.vue";

import CashStatementsApiSearchPage from "@/modules/cashStatement/views/CashStatementsApiSearchPage.vue";
import CashStatementPage from "@/modules/cashStatement/views/CashStatementPage.vue";
import CashDocumentPage from "@/modules/cashStatement/views/CashDocumentPage.vue";
import CashStatementCashPointSearch from "@/modules/cashStatement/components/CashPointSearch.vue";
import VIRIItemPage from "@/modules/cashStatement/viriItems/views/VIRIItemPage.vue";

import BankStatementCreatePage from "@/modules/bankStatement/views/BankStatementCreatePage.vue";
import BankStatementsApiSearchPage from "@/modules/bankStatement/views/BankStatementsApiSearchPage.vue";

import BankStatementItemsPage from "@/modules/bankStatement/views/BankStatementItemsPage.vue";

import CustomsInvoicePage from "@/modules/customsInvoice/views/CustomsInvoicePage.vue";
import CustomsInvoicesPage from "@/modules/customsInvoice/views/CustomsInvoicesPage.vue";

import AccountCodeEditModelsPage from "@/modules/accountCodeEditModel/views/AccountCodeEditModelsPage.vue";
import AccountCodeEditModelPage from "@/modules/accountCodeEditModel/views/AccountCodeEditModelPage.vue";
import AccountCodeEditList from "./modules/accountCodeEditModel/views/AccountCodeEditList.vue";

import SystemParametersPage from "@/modules/configuration/views/SystemParametersPage.vue";

import IncomingInvoicePage from "@/modules/generalIngoingInvoice/views/IncomingInvoicePage.vue";

import InvoiceFlowTaskPage from "@/modules/invoiceFlowTask/views/InvoiceFlowTaskPage.vue";

import UserAccountNosPage from "@/modules/userAccountNo/views/UserAccountNosPage.vue";
import UserAccountNoPage from "@/modules/userAccountNo/views/UserAccountNoPage.vue";

import Roles from "@/modules/roles/views/Roles.vue";
import TaxesPage from "@/modules/taxes/views/TaxesPage.vue";
import TaxPage from "@/modules/taxes/views/TaxPage.vue";

import DownPaymentsPage from "@/modules/downPayment/views/DownPaymentsPage.vue";
import DownPaymentSearchPage from "@/modules/downPayment/views/DownPaymentSearchPage.vue";
import DownPaymentRequestPage from "@/modules/downPayment/views/DownPaymentRequestPage.vue";

import GeneralLedgerPeriods from "@/modules/generalLedger/views/Periods.vue";
import GeneralLedgerTransactionGrid from "@/modules/generalLedger/views/GeneralLedgerTransactionsGrid.vue";
import GeneralLedgerNotActivatedInvests from "@/modules/generalLedger/notActivatedInvests/views/NotActivatedInvests.vue";

import DebitPaymentView from "@/modules/debitPayment/views/DebitPaymentView.vue";
import DebitPaymentEditPage from "@/modules/debitPayment/views/DebitPaymentEditPage.vue";
import DebitPaymentSearchPage from "@/modules/debitPayment/views/DebitPaymentSearchPage.vue";

import ActionLogPage from "@/modules/actionLog/views/ActionLogPage.vue";
import DimCodeTypesPage from "@/modules/dimCodeType/views/DimCodeTypesPage.vue";

import InvoiceParameterPage from "@/modules/invoiceParametering/views/invoiceParamGrid.vue";
import InvoiceParameterEditPage from "@/modules/invoiceParametering/views/invoiceParamEdit.vue";

import InvoiceDeadlinesPage from "@/modules/invoicePaymentSchedule/views/InvoiceDeadlinesPage.vue";

import UninvoicedStockCorrectionsPage from "@/modules/uninvoicedStockCorrection/views/UninvoicedStockCorrectionsPage.vue";
import UninvoicedStockCorrectionSearchPage from "@/modules/uninvoicedStockCorrection/views/UninvoicedStockCorrectionSearchPage.vue";
import UninvoicedStockCorrectionEditPage from "@/modules/uninvoicedStockCorrection/views/UninvoicedStockCorrectionEditPage.vue";

import BankRemitsPage from "@/modules/bankRemit/views/BankRemitsPage.vue";
import BankRemitCreatePage from "@/modules/bankRemit/views/BankRemitCreatePage.vue";
import BankRemitHeadView from "@/modules/bankRemit/views/BankRemitHeadView.vue";
import BankRemitItemView from "@/modules/bankRemit/views/BankRemitItemView.vue";
import BankRemitApprovalView from "@/modules/bankRemit/views/BankRemitApprovalView.vue";
import BankSelectView from "@/modules/bankRemit/views/BankSelectView.vue";
import BankRemitForwardingView from "@/modules/bankRemit/views/BankRemitForwardingView.vue";

import InvoiceAccounting from "./modules/invoiceFlow/views/InvoiceAccounting.vue";

import BankExtractImportsPage from "@/modules/bankExtract/views/BankExtractImportsPage.vue";
import BankExtractImportEditPage from "@/modules/bankExtract/views/BankExtractImportEditPage.vue";
import BankExtractImportCreatePage from "@/modules/bankExtract/views/BankExtractImportCreatePage.vue";

// #region reports
import ReportsPage from "@/modules/reports/views/ReportsPage.vue";
import PowerBIPage from "@/modules/powerBI/views/PowerBIPage.vue";
import BankTransferNotifyReportPage from "@/modules/reports/BankTransferNotify/views/BankTransferNotifyReportPage.vue";
import BankTransferRemitReportPage from "@/modules/reports/BankTransferRemit/views/BankTransferRemitReportPage.vue";
// #endregion

import BankExtractImportAccountTypeEdit from "@/modules/bankExtractImportAccountType/views/BankExtractImportAccountTypeEdit.vue";

import FixAssetGroupsPage from "@/modules/fixAssetGroup/views/FixAssetGroupsPage.vue";
import FixAssetGroupPage from "@/modules/fixAssetGroup/views/FixAssetGroupPage.vue";

import FullfilmentTolerancesPage from "@/modules/generalIngoingInvoice/views/FullfilmentTolerancesPage.vue";

import InvoiceMigrationPage from "@/modules/invoiceMigration/views/InvoiceMigrationPage.vue";
import BankStatementFlowPage from "@/modules/bankStatement/views/BankStatementFlowPage.vue";
import BankStatementApprovalPage from "@/modules/bankStatement/views/BankStatementApprovalPage.vue";
import CreditCardsPage from "@/modules/creditCard/views/CreditCardsPage.vue";
import CreditCardPage from "@/modules/creditCard/views/CreditCardPage.vue";

import LettersOfCreditPage from "@/modules/letterOfCredit/LettersOfCreditPage.vue";
import LetterOfCreditPage from "@/modules/letterOfCredit/LetterOfCreditPage.vue";

import InvoiceGroupApprovalsPage from "@/modules/invoiceGroupApproval/views/InvoiceGroupApprovalsPage.vue";

import AccComplaintsTable from "@/modules/accComplaints/views/AccComplaintsTable.vue";

import CurrentAccountPage from "@/modules/currentAccount/CurrentAccountsPage.vue";
import CurrentAccountArrangementPage from "@/modules/currentAccount/CurrentAccountArrangementPage.vue";
import WriteOffDebtPage from "@/modules/writeOffDebt/WriteOffDebtPage.vue";
import WriteOffDebtInvoicesPage from "@/modules/writeOffDebt/WriteOffDebtInvoicesPage.vue";
import WriteOffPage from "@/modules/writeOffDebt/WriteOffPage.vue";
// #endregion

import ProformToInvoiceView from "@/modules/generalOutgoingInvoice/proformToFinal/views/ProformToInvoiceView.vue";
import GeneralOutgoingInvoiceLayout from "@/modules/generalOutgoingInvoice/views/GeneralOutgoingInvoiceLayout.vue";
import PayOpenInvoicesView from "@/modules/generalOutgoingInvoice/openInvoices/views/PayOpenInvoicesView.vue";
import VIRIPage from "@/modules/cashStatement/viriItems/views/VIRIPage.vue";

import OpenPayoffDownpaymentsView from "@/modules/cashPoint/openPayoffDownpayments/views/OpenPayoffDownpaymentsView.vue";
import InvoiceLedgerSubmissionView from "@/modules/generalLedger/invoiceSubmission/views/InvoiceLedgerSubmissionView.vue";
import InvoiceImportPage from "@/modules/generalIngoingInvoice/views/InvoiceImportPage.vue"
import InvoiceItemsImportPage from "@/modules/generalIngoingInvoice/views/InvoiceItemsImportPage.vue"
import InvoiceImportViewPage from "@/modules/generalIngoingInvoice/views/InvoiceImportViewPage.vue"

import StornoSummaryBrowser from "@/modules/stornoSummary/views/StornoSummaryBrowser.vue";
import OverSummaryBrowser from "@/modules/overSummary/views/OverSummaryBrowser.vue";
import OverSummaryView from "@/modules/overSummary/views/OverSummaryView.vue";
import CaroStornoPage from "@/modules/caroStorno/views/CaroStornoPage.vue";

import Transactions from "@/modules/transactions/views/Transactions.vue";
import TransactionsEditPage from "@/modules/transactions/views/TransactionsEditPage.vue";

import KanriInvoicesPage from "@/modules/kanri/views/KanriInvoicesPage.vue";
import GenoExperimentalInvoicePage from "@/modules/kanri/views/GenoExperimentalInvoicePage.vue";
import GenoExperimentalInvoiceEditPage from "@/modules/kanri/views/GenoExperimentalInvoiceEditPage.vue";
import KanriRedirectPage from "@/modules/kanri/views/KanriRedirectPage.vue";
import KanriCarAgreementInvoicesPage from "@/modules/kanri/views/KanriCarAgreementInvoicesPage.vue";

import GpartsListView from "./modules/gpartsEdit/views/GpartsListView.vue";
import GpartEditor from "./modules/gpartsEdit/views/GpartEditor.vue";

import links from "./models/Links";

import DownloadableInvoices from "@/modules/downloadInvoices/views/DownloadableInvoices.vue";

Vue.use(VueRouter);

const MainComponent = configuration.operel ? {} : SettingsPage;

const settingsChildren: RouteConfig[] = [
    {
        path: "banks",
        name: "Banks",
        component: BanksPage,
    },
    {
        path: "account/action-source",
        name: "AccountActionSources",
        component: AccountActionSourcesPage,
    },
    {
        path: "account/account-type",
        name: "AccountTypes",
        component: AccountTypesPage,
    },
    {
        path: "invoice-group",
        name: "InvoiceGroups",
        component: InvoiceGroupsPage,
    },
    {
        path: "invoice-group-approvals",
        name: "InvoiceGroupApprovals",
        component: InvoiceGroupApprovalsPage,
    },
    {
        path: "payment-mode",
        name: "PaymentModes",
        component: PaymentModesPage,
    },
    {
        path: "cash-points",
        name: "CashPoints",
        component: CashPointsPage,
    },
    {
        path: "/account/action-source/:accountActionSourceId",
        name: "accountActionSourceEdit",
        component: AccountActionSourcePage,
    },
    {
        path: "/account/account-type/:accountTypeId",
        name: "AccountTypeEdit",
        component: AccountTypePage,
    },
    {
        path: "/invoice-group/create",
        name: "InvoiceGroup",
        component: InvoiceGroupPage,
    },
    {
        path: "/tax",
        name: "TaxesPage",
        component: TaxesPage,
    },
    {
        path: "/tax/create",
        name: "TaxCreate",
        component: TaxPage,
    },
    {
        path: "/tax/:taxId/edit",
        name: "TaxEdit",
        component: TaxPage,
    },
    {
        path: "/invoice-group/:invoiceGroupId/edit",
        name: "InvoiceGroupEdit",
        component: InvoiceGroupPage,
    },
    {
        path: "/payment-mode/create",
        name: "PaymentModeCreate",
        component: PaymentModePage,
    },
    {
        path: "/payment-mode/:paymentModeId/edit",
        name: "PaymentModeEdit",
        component: PaymentModePage,
    },
    {
        path: "/cash-points/:cashPointId",
        name: "cashPointEdit",
        component: CashPointPage,
    },
    {
        path: "/cash-points/create",
        name: "cashPointCreate",
        component: CashPointPage,
    },
    {
        path: "/banks/create",
        name: "BankCreate",
        component: BankPage,
    },
    {
        path: "/bank/:bankId/edit",
        name: "BankEdit",
        component: BankPage,
    },
    {
        path: "/currency-rates",
        name: "CurrencyRates",
        component: CurrencyRatesPage,
    },
    {
        path: "/currency-rates/create",
        name: "CurrencyRatesCreate",
        component: CurrencyRatePage,
    },
    {
        path: "/document-templates",
        name: "InvoiceFlowSettings",
        component: InvoiceFlowSettingsPage,
    },
    {
        path: "/approval-matrix",
        name: "ApprovalMatrixSettings",
        component: ApprovalMatrixPage,
    },
    {
        path: "/incoming-invoice-type",
        name: "IncomingInvoiceTypes",
        component: IncomingInvoiceTypesPage,
    },
    {
        path: "/incoming-invoice-type/edit/:typeId",
        name: "IncomingInvoiceType",
        component: IncomingInvoiceTypePage,
    },
    {
        path: "/invoice-types",
        name: "InvoiceTypes",
        component: InvoiceTypesPage,
    },
    {
        path: "/invoice-types/create",
        name: "InvoiceTypeCreate",
        component: InvoiceTypePage,
    },
    {
        path: "/invoice-types/:invoiceTypeId/edit",
        name: "InvoiceTypeEdit",
        component: InvoiceTypePage,
    },
    {
        path: "/outgoing-invoice/type",
        name: "OutgoingInvoiceTypeEditModels",
        component: OutgoingInvoiceTypeEditModelsPage,
    },
    {
        path: "/outgoing-invoice/type/:outgoingInvoiceTypeId/edit",
        name: "OutgoingInvoiceTypeEditModelEdit",
        component: OutgoingInvoiceTypeEditModelPage,
    },
    {
        path: "/outgoing-invoice/type/create",
        name: "OutgoingInvoiceTypeEditModelCreate",
        component: OutgoingInvoiceTypeEditModelPage,
    },
    {
        path: "/account-voucher-types",
        name: "AccountVoucherTypes",
        component: AccountVoucherTypesPage,
    },
    {
        path: "/account-voucher-types/:accountVoucherTypeId",
        name: "AccountVoucherTypeEdit",
        component: AccountVoucherTypePage,
    },
    {
        path: "/account-voucher-types/create",
        name: "AccountVoucherTypeCreate",
        component: AccountVoucherTypePage,
    },
    {
        path: "/account-codes/editmodels",
        name: "AccountCodeEditmodels",
        component: AccountCodeEditModelsPage,
    },
    {
        path: "/account-codes/list",
        name: "AccountCodeEditList",
        component: AccountCodeEditList,
    },
    {
        path: "/account-codes/editmodels/:accountCodeId/:columnDictionaryId",
        name: "AccountCodeEditModelEdit",
        component: AccountCodeEditModelPage,
    },
    {
        path: "/parameters",
        name: "SystemParameters",
        component: SystemParametersPage,
    },
    {
        path: "/user-acc-no",
        name: "UserAccountNos",
        component: UserAccountNosPage,
    },
    {
        path: "/user-acc-no/:accountId/edit",
        name: "UserAccountNo",
        component: UserAccountNoPage,
    },
    {
        path: "/user-acc-no/create",
        name: "UserAccountNoCreate",
        component: UserAccountNoPage,
    },
    {
        path: "/roles",
        name: "Roles",
        component: Roles,
    },
    {
        path: "/generalLedgerPeriods",
        name: "GeneralLedgerPeriods",
        component: GeneralLedgerPeriods,
    },
    {
        path: "/dim-code-types",
        name: "DimCodeTypes",
        component: DimCodeTypesPage,
    },
    {
        path: "/invoice-parameter",
        name: "InvoiceParameter",
        component: InvoiceParameterPage,
    },
    {
        path: "/invoice-parameter/:InvoiceParamsId",
        name: "InvoiceParameterEdit",
        component: InvoiceParameterEditPage,
    },
    {
        path: "/bank-extract-import-account-type",
        name: "BankExtractImportAccountTypeEdit",
        component: BankExtractImportAccountTypeEdit,
    },
    {
        path: "/fix-asset/groups",
        name: "FixAssetGroupsPage",
        component: FixAssetGroupsPage,
    },
    {
        path: "/fix-asset/groups/:fixAssetGroupId",
        name: "FixAssetGroupPage",
        component: FixAssetGroupPage,
    },
    {
        path: "/invoice/migration",
        name: "InvoiceMigrationPage",
        component: InvoiceMigrationPage,
    },
];

function filtered(settingsChildren: RouteConfig[]) {
    return settingsChildren.filter((x) => {
        if (!x.name) return false;
        return [
            "Banks",
            "BankCreate",
            "BankEdit",
            "ApprovalMatrixSettings",
            "InvoiceFlowSettings",
            "UserAccountNos",
            "UserAccountNo",
            "UserAccountNoCreate",
            "SystemParameters",
            "Roles",
            "TaxesPage",
            "TaxCreate",
            "TaxEdit",
            "AccountCodeEditmodels",
            "AccountCodeEditModelEdit",
            "DimCodeTypes",
            "InvoiceParameter",
            "CreditCardPage",
        ].includes(x.name);
    });
}

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "LandingPage",
        component: Home,
    },
    {
        path: "/settings",
        name: "Settings",
        component: MainComponent,
        children: process.env.VUE_APP_SETTINGS_HIDDEN === "true" ? filtered(settingsChildren) : settingsChildren,
    },
    {
        path: "/partners",
        name: "PartnerDetails",
        component: PartnerDetailsPage,
    },
    {
        path: "/partners/create/:source?",
        name: "PartnerCreate",
        component: PartnerPage,
    },
    {
        path: "/partners/:partnerId/:source?",
        name: "PartnerEdit",
        component: PartnerEditPage,
    },
    {
        path: "/partners/:partnerId/:source?/create-part-bank",
        name: "PartBankCreate",
        component: PartBankCreatePage,
    },
    {
        path: "/partners/:partnerId/:source?/create-part-supplier",
        name: "PartSupplierCreate",
        component: PartSupplierCreatePage,
    },
    {
        path: "/partners/:partnerId/:source?/create-part-customer",
        name: "PartCustCreate",
        component: PartCustCreatePage,
    },
    {
        path: "/partners/:partnerId/:source?/create-part-person",
        name: "PartPersonCreate",
        component: PartPersonCreatePage,
    },
    //// általános dokumentum böngésző, feltöltő, még nincs kész TODO
    // {
    //   path: "/documents",
    //   name: "DocumentBrowser",
    //   component: DocumentBrowser,
    // },
    // {
    //   path: "/documents/create",
    //   name: "DocumentCreatePage",
    //   component: DocumentCreatePage,
    // },
    {
        path: "/login",
        name: "Login",
        component: LoginPage,
    },
    {
        path: "/general-incoming-invoice",
        name: "GeneralIngoingInvoices",
        component: GeneralIngoingInvoicesPage,
    },
    {
        path: "/general-incoming-invoice/create",
        name: "GeneralIngoingInvoicePartnerSearch",
        component: GeneralIngoingInvoicePartnerSearch,
        meta: {
            isPictureRequired: true,
        }
    },
    {
        path: "/settings",
        name: "Settings",
        component: MainComponent,
        children: process.env.VUE_APP_SETTINGS_HIDDEN === "true" ? filtered(settingsChildren) : settingsChildren,
    },
    {
        path: "/partners",
        name: "PartnerDetails",
        component: PartnerDetailsPage,
    },
    {
        path: "/partners/create/:source?",
        name: "PartnerCreate",
        component: PartnerPage,
    },
    {
        path: "/partners/:partnerId/:source?",
        name: "PartnerEdit",
        component: PartnerEditPage,
    },
    {
        path: "/partners/:partnerId/:source?/create-part-bank",
        name: "PartBankCreate",
        component: PartBankCreatePage,
    },
    {
        path: "/partners/:partnerId/:source?/create-part-supplier",
        name: "PartSupplierCreate",
        component: PartSupplierCreatePage,
    },
    {
        path: "/partners/:partnerId/:source?/create-part-customer",
        name: "PartCustCreate",
        component: PartCustCreatePage,
    },
    {
        path: "/partners/:partnerId/:source?/create-part-person",
        name: "PartPersonCreate",
        component: PartPersonCreatePage,
    },
    //// általános dokumentum böngésző, feltöltő, még nincs kész TODO
    // {
    //   path: "/documents",
    //   name: "DocumentBrowser",
    //   component: DocumentBrowser,
    // },
    // {
    //   path: "/documents/create",
    //   name: "DocumentCreatePage",
    //   component: DocumentCreatePage,
    // },
    {
        path: "/login",
        name: "Login",
        component: LoginPage,
    },
    {
        path: "/general-incoming-invoice",
        name: "GeneralIngoingInvoices",
        component: GeneralIngoingInvoicesPage,
    },
    {
        path: "/general-incoming-invoice/create",
        name: "GeneralIngoingInvoicePartnerSearch",
        component: GeneralIngoingInvoicePartnerSearch,
        meta: {
            isPictureRequired: true,
        },
    },
    {
        path: "/general-incoming-invoice/create/diff-vat",
        name: "GeneralIngoingInvoicePartnerSearch",
        component: GeneralIngoingInvoicePartnerSearch,
        meta: {
            isPictureRequired: true,
        },
    },
    {
        path: "/general-incoming-invoice/flow/create/:partnerId",
        name: "InvoiceFlowCreate",
        component: InvoiceFlowPage,
        meta: {
            isPictureRequired: true,
        },
    },
    {
        path: "/general-incoming-invoice/flow/create/diff-vat/:partnerId",
        name: "InvoiceFlowCreate",
        component: InvoiceFlowPage,
        meta: {
            isPictureRequired: true,
        },
    },
    {
        path: "/general-incoming-invoice/flow/edit/:invoiceId",
        name: "InvoiceFlowEdit",
        component: InvoiceFlowPage,
        meta: {
            isPictureRequired: true,
        },
    },
    {
        path: "/general-incoming-invoice/flow/edit/:invoiceId/:step/:partnerId?",
        name: "InvoiceFlowEditInStep",
        component: InvoiceFlowPage,
        meta: {
            isPictureRequired: true,
        },
    },
    {
        path: "/general-incoming-invoice/flow/edit-simple/:invoiceId",
        name: "InvoiceEditAfterVatConfession",
        component: InvoiceEditAfterVatConfession,
    },
    {
        path: "/general-incoming-invoice/create/:partnerId",
        name: "GeneralIngoingInvoiceCreate",
        component: IncomingInvoicePage,
        meta: {
            isPictureRequired: true,
        },
    },
    {
        path: "/general-incoming-invoice/edit/:partnerId/:invoiceId",
        name: "GeneralIngoingInvoiceEdit",
        component: IncomingInvoicePage,
        meta: {
            isPictureRequired: true,
        },
    },
    {
        path: "/invoice-browser/technical/:codeFilter",
        name: "TechnicalInvoiceBrowser",
        component: TechnicalInvoiceBrowser,
    },
    {
        path: "/invoice-browser/technical-invoice/:codeFilter",
        name: "PartnerSelectView",
        component: () => import("@/modules/technicalInvoice/views/PartnerSelectView.vue"),
    },
    {
        path: "/invoice-browser/technical-invoice/:codeFilter/create",
        name: "TechnicalInvoicePageCreate",
        component: () => import("@/modules/technicalInvoice/views/TechnicalInvoicePage.vue"),
    },
    {
        path: "/invoice-browser/technical-invoice/:codeFilter/edit/:invoiceId",
        name: "TechnicalInvoicePageEdit",
        component: () => import("@/modules/technicalInvoice/views/TechnicalInvoicePage.vue"),
    },
    {
        path: "/invoice-browser",
        name: "InvoiceBrowser",
        component: InvoiceBrowser,
        children: [
            {
                path: ":typeFilter",
                name: "InvoiceBrowser",
                component: InvoiceBrowser,
            }
        ],
    },
    {
        path: "/invoice-flow-task",
        name: "InvoiceFlowTaskPage",
        component: InvoiceFlowTaskPage,
    },
    {
        path: "/cash-statement",
        name: "CashStatementsApiSearchPage",
        component: CashStatementsApiSearchPage,
    },
    {
        path: "/current-account",
        name: "CurrentAccount",
        component: CurrentAccountPage,
    },
    {
        path: "/current-account/arrangement",
        name: "CurrentAccountArrangement",
        component: CurrentAccountArrangementPage,
    },
    {
        path: "/write-off-debt",
        name: "WriteOffDebt",
        component: WriteOffDebtPage,
        props: true,
        children: [
            {
                path: "invoices",
                name: "WriteOffDebtInvoices",
                component: WriteOffDebtInvoicesPage,
            },
            {
                path: "write-off",
                name: "WriteOff",
                component: WriteOffPage,
            },
        ],
    },
    {
        path: "/cash-statement/create",
        name: "CashStatementCashPointSearchPage",
        component: CashStatementCashPointSearch,
    },
    {
        path: "/cash-statement/create/:cashPointId",
        name: "CashStatementCreatePage",
        component: CashStatementPage,
    },
    {
        path: "/cash-statement/edit/:cashStatementId",
        name: "CashStatementEditPage",
        component: CashStatementPage,
    },
    {
        path: "/cash-statement/edit/:cashStatementId/:cashDocumentId",
        name: "CashDocumentEditPage",
        component: CashDocumentPage,
    },
    {
        path: "/viri-item/create",
        name: "VIRIItemPage",
        component: VIRIItemPage,
    },
    {
        path: "/viri-item/edit/:invoiceId",
        name: "VIRIItemPage",
        component: VIRIItemPage,
    },
    {
        path: "/customs-invoice/edit/:invoiceId",
        name: "CustomsInvoicePage",
        component: CustomsInvoicePage,
    },
    {
        path: "/customs-invoice",
        name: "CustomsInvoicesPage",
        component: CustomsInvoicesPage,
    },
    {
        path: "/bank-statements",
        name: "BankStatementsApiSearchPage",
        component: BankStatementsApiSearchPage,
    },
    {
        path: "/bank-statement/create/:id",
        name: "BankStatementFlowPage",
        component: BankStatementFlowPage,
        children: [
            {
                path: "head",
                name: "BankStatementCreatePage",
                component: BankStatementCreatePage,
            },
            {
                path: "item",
                name: "BankStatementItemsPage",
                component: BankStatementItemsPage,
            },
            {
                path: "approval",
                name: "BankStatementApprovalPage",
                component: BankStatementApprovalPage,
            },
        ],
    },
    {
        path: "/down-payment",
        name: "DownPaymentsPage",
        component: DownPaymentsPage,
    },
    {
        path: "/down-payment/search",
        name: "DownPaymentSearchPage",
        component: DownPaymentSearchPage,
    },
    {
        path: "/down-payment/create/:partnerId",
        name: "DownPaymentCreatePage",
        component: DownPaymentRequestPage,
    },
    {
        path: "/down-payment/edit/:invoiceId",
        name: "DownPaymentEditPage",
        component: DownPaymentRequestPage,
    },
    {
        path: "/debit-payment",
        name: "DebitPaymentsView",
        component: DebitPaymentView,
    },
    {
        path: "/debit-payment/edit/:partnerId/:invoiceId",
        name: "DebitPaymentEditPage",
        component: DebitPaymentEditPage,
    },
    {
        path: "/debit-payment/create/:partnerId",
        name: "DebitPaymentCreatePage",
        component: DebitPaymentEditPage,
    },
    {
        path: "/debit-payment/search",
        name: "DebitPaymentSearchPage",
        component: DebitPaymentSearchPage,
    },
    {
        path: "/action-log",
        name: "ActionLogPage",
        component: ActionLogPage,
    },
    {
        path: "/invoice-parameter",
        name: "InvoiceParameterPage",
        component: InvoiceParameterPage,
    },
    {
        path: "/invoice-deadlines",
        name: "InvoiceDeadlines",
        component: InvoiceDeadlinesPage,
    },
    {
        path: "/uninvoiced-stock-correction",
        name: "UninvoicedStockCorrections",
        component: UninvoicedStockCorrectionsPage,
    },
    {
        path: "/uninvoiced-stock-correction/create",
        name: "UninvoicedStockCorrectionPartnerSearch",
        component: UninvoicedStockCorrectionSearchPage,
    },
    {
        path: "/uninvoiced-stock-correction/create/:partnerId",
        name: "UninvoicedStockCorrectionCreate",
        component: UninvoicedStockCorrectionEditPage,
    },
    {
        path: "/uninvoiced-stock-correction/edit/:invoiceId",
        name: "UninvoicedStockCorrectionEdit",
        component: UninvoicedStockCorrectionEditPage,
    },
    {
        path: "/bank-remits",
        name: "BankRemitsPage",
        component: BankRemitsPage,
    },
    {
        path: "/bank-remits-account",
        name: "BankSelectView",
        component: BankSelectView,
    },
    {
        path: "/bank-remits/create/:id",
        name: "BankRemitCreatePage",
        component: BankRemitCreatePage,
        children: [
            {
                path: "head",
                name: "BankRemitHeadView",
                component: BankRemitHeadView,
            },
            {
                path: "item",
                name: "BankRemitItemView",
                component: BankRemitItemView,
            },
            {
                path: "approval",
                name: "BankRemitApprovalView",
                component: BankRemitApprovalView,
            },
            {
                path: "forward",
                name: "BankRemitForwarding",
                component: BankRemitForwardingView,
            },
        ],
    },
    {
        path: "/incoming-invoice/:invoiceId/accounting",
        name: "InvoiceAccounting",
        component: InvoiceAccounting,
    },
    {
        path: "/bank-extract-import",
        name: "BankExtractImports",
        component: BankExtractImportsPage,
    },
    {
        path: "/bank-extract-import/edit/:headId",
        name: "BankExtractImportEdit",
        component: BankExtractImportEditPage,
    },
    {
        path: "/bank-extract-import/create",
        name: "BankExtractImportCreate",
        component: BankExtractImportCreatePage,
    },
    {
        path: "/power-bi/:id",
        name: "PowerBIPage",
        component: PowerBIPage,
    },
    {
        path: "transfer-notify",
        name: "BankTransferNotifyReportPage",
        component: BankTransferNotifyReportPage,
    },
    {
        path: "transfer-remit",
        name: "BankTransferRemitReportPage",
        component: BankTransferRemitReportPage,
    },
    {
        path: "/accounting-queries/transaction-items",
        name: "GeneralLedgerTransactionGrid",
        component: GeneralLedgerTransactionGrid,
    },
    {
        path: "/accounting-queries/fullfilment-tolerance-level",
        name: "FullfilmentTolerancesPage",
        component: FullfilmentTolerancesPage,
    },
    {
        path: "/credit-cards",
        name: "CreditCardsPage",
        component: CreditCardsPage,
    },
    {
        path: "/credit-card/:id",
        name: "CreditCardPage",
        component: CreditCardPage,
    },
    {
        path: "/letters-of-credit",
        name: "LettersOfCreditPage",
        component: LettersOfCreditPage,
    },
    {
        path: "/letters-of-credit/:id",
        name: "LetterOfCreditPage",
        component: LetterOfCreditPage,
    },
    {
        path: "/change-password",
        name: "ChangePasswordPage",
        component: ChangePasswordPage,
    },
    {
        path: "/acc-complaints",
        name: "AccComplaintsTable",
        component: AccComplaintsTable,
    },
    {
        path: "/general-outgoing-invoices",
        component: GeneralOutgoingInvoiceLayout,
        name: "GeneralOutgoingInvoiceLayout",
        children: [
            {
                path: "proform-to-final",
                component: ProformToInvoiceView,
                name: "ProformToInvoiceView",
            },
            {
                path: "pay-open-invoices",
                component: PayOpenInvoicesView,
                name: "PayOpenInvoicesView",
            },
            {
                path: "pay-open-invoices/:prefix/:invNo",
                component: PayOpenInvoicesView,
                name: "PayOpenInvoicesView",
            },
            {
                path: "viri-invoices",
                component: VIRIPage,
                name: "VIRIPage",
            },
        ],
    },
    {
        path: "/open-payoff-downpayments",
        component: OpenPayoffDownpaymentsView,
        name: "OpenPayoffDownpaymentsView",
    },
    {
        path: "/ledger-submits",
        component: InvoiceLedgerSubmissionView,
        name: "InvoiceLedgerSubmissionView",
    },
    {
        path: "/general-incoming-invoice/kanri/:objectId",
        name: "KanriRedirectPageForGeni",
        component: KanriRedirectPage,
    },
    {
        path: "/geno-experimental-invoices/kanri/:objectId",
        name: "KanriRedirectPageForGenoAndIntr",
        component: KanriRedirectPage,
    },
    {
        path: "/noto/kanri/:objectId",
        name: "KanriRedirectPageForNoto",
        component: KanriRedirectPage,
    },
    {
        path: "/kanri-invoices/purchase",
        name: "KanriInvoicesPurchase",
        component: KanriInvoicesPage,
    },
    {
        path: "/kanri-invoices/purchase/car-agreements",
        name: "KanriCarAgreementInvoicess",
        component: KanriCarAgreementInvoicesPage,
    },
    {
        path: "/kanri-invoices/sale",
        name: "KanriInvoicesSale",
        component: KanriInvoicesPage,
    },
    {
        path: "/kanri-invoices/:id",
        name: "KanriInvoices",
        component: KanriInvoicesPage,
    },
    {
        path: "/geno-experimental-invoices",
        name: "GenoExperimentalInvoicePage",
        component: GenoExperimentalInvoicePage,
    },
    {
        path: "/geno-experimental-invoices/:id",
        name: "GenoExperimentalInvoiceEditPage",
        component: GenoExperimentalInvoiceEditPage,
    },
    {
        path: "/notice",
        name: "Notices",
        component: NoticesPage,
    },
    {
        path: "/notice/create",
        name: "NoticePartnerSearch",
        component: NoticePartnerPage,
    },
    {
        path: "/notice/create/0",
        name: "NoticeCreate",
        component: NoticePage,
    },
    {
        path: "/noto/:noticeId",
        name: "NoticeEdit",
        component: NoticePage,
    },
    {
        path: "/caro",
        name: "CaroStornoPage",
        component: CaroStornoPage,
    },
    {
        path: "/invoice-import",
        name: "InvoiceImportPage",
        component: InvoiceImportPage,
    },
    {
        path: "/invoice-import/items",
        name: "InvoiceItemsImportPage",
        component: InvoiceItemsImportPage,
        props: true,
    },
    {
        path: "/download-invoices",
        name: "DownloadableInvoices",
        component: DownloadableInvoices,
    },
    {
        path: "/invoice-import-view",
        name: "InvoiceImportViewPage",
        component: InvoiceImportViewPage,
    },
    {
        path: "/transactions",
        name: "Transactions",
        component: Transactions,
    },
    {
        path: "/transactions/create/1",
        name: "TransactionsEditPage",
        component: TransactionsEditPage,
    },
    {
        path: "/transactions/edit/:id",
        name: "TransactionsEditPage",
        component: TransactionsEditPage,
    },
    {
        path: "/storno-summary",
        component: StornoSummaryBrowser,
        name: "StornoSummaryBrowser",
    },
    // {
    //     path: "/over-summary",
    //     component: OverSummaryBrowser,
    // name: "OverSummaryBrowser",
    // },
    {
        path: "/over-summary",
        component: OverSummaryView,
        name: "OverSummaryView",
    },
    {
        path: "/gparts/edit-list",
        component: GpartsListView,
        name: "GpartsListView",
    },
    {
        path: "/gparts/edit/0",
        component: GpartEditor,
        name: "GpartEditor1",
    },
    {
        path: "/gparts/edit/:partsID",
        component: GpartEditor,
        name: "GpartEditor2",
    },
];

const router = new VueRouter({
    mode: process.env.VUE_APP_ROUTER_HASH_MODE ? "hash" : "history",
    base: process.env.VUE_APP_BASE_URL ?? "",
    routes: routes,
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    if (!auth.isLoggedIn && to.name != "Login") {
        next({ name: "Login", query: { redirect: to.fullPath } });
    }
    if (auth.isLoggedIn && auth.passwordChangeRequired && to.name != "ChangePasswordPage") {
        next({ name: "ChangePasswordPage" });
    } else {
        next();
    }
});

links.activate.router = router;
export default router;
