





































































































import Vue from "vue";
import {
  CompositeFilterDescriptor,
  orderBy,
  filterBy,
  SortDescriptor,
} from "@progress/kendo-data-query";
import store, { CashStatementStore } from "../services/store";
import Pager from "../../../models/Pager";
import BankStatus, { bankStatusDefaultFilterProps } from "../models/BankStatus";
import AccountStatus, {
  accountStatusDefaultFilterProps,
} from "@/modules/notice/models/AccountStatus";
import CashStatementDisplay from "../models/CashStatementDisplay";
import createPagerText from "../../../utils/pagerText";

// Components
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import PageListView, { DEFAULT_FILTER } from "@/components/PageListView.vue";
import IconButton from "@/components/IconButton.vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import CashStatementDetails from "../components/CashStatementDetails.vue";
import ColspanFixer from "@/components/ColspanFixer.vue";
import CashStatement from "../models/CashStatement";
import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import { ApiSearchColumn, ColumnType } from "@/components/apiSearch/models/ApiSearch";

interface Data {
  store: CashStatementStore;
  pager: Pager;
  defaultBankStatus: BankStatus;
  defaultAccStatus: AccountStatus;
}

interface Methods {
  showDetails(dataItem: CashStatementDisplay): void;
  edit(item: CashStatementDisplay): void;
  getStatusName(status: number): string;
  getAccStatusName(accStatus: number): string;
  create(): void;
}

interface Computed {
  statements: CashStatementDisplay[];
  columns: object;
  footerText: string;
  url: string;
}

export default Vue.extend<Data, Methods, Computed, {}>({
  components: {
    CashStatementDetails,
    ApiSearchPage,
    IconButton,
    MoneyDisplay,
    ColspanFixer,
  },

  data: () => ({
    store,
    pager: new Pager(),
    defaultBankStatus: { ...bankStatusDefaultFilterProps },
    defaultAccStatus: { ...accountStatusDefaultFilterProps },
  }),

  created() {
    this.pager = new Pager({ list: this.statements });
  },

  async beforeMount() {
    await this.store.loadBankStatuses();
    await this.store.loadAccountStatuses();
    await this.store.loadCashStatements();

    if (
      this.store.selectedBankStatus &&
      this.store.selectedBankStatus?.id < 0
    ) {
      this.store.selectedBankStatus = this.store.bankStatuses.find(
        (x) => x.id == 2
      );
    }

    if (this.store.selectedAccStatus?.id && this.store.selectedAccStatus?.id < 0) {
      this.store.selectedAccStatus = this.defaultAccStatus;
    }
  },

  methods: {
    create(){
        this.$router.push("/cash-statement/create")
    },

    getStatusName(status){
        return store.bankStatuses.find(x=>x.id == status)?.name ?? "-"
    },

    getAccStatusName(accStatus){
        return store.accountStatuses.find(x=>x.id == accStatus)?.name ?? "-"
    },

    async showDetails(dataItem: CashStatementDisplay) {
      if (!dataItem.selected) {
        await this.store.loadCashDocumentsByStatementId(dataItem.id);
        dataItem.cashDocuments = this.store.cashDocuments;

        await this.store.loadCashStatement(dataItem.id);
        dataItem.statement = this.store.cashStatement;
        this.store.cashStatement = new CashStatement({});

        console.warn(
          dataItem.id,
          "Statement showDetails(): dataItem:",
          JSON.parse(JSON.stringify(dataItem))
        );
      }
      dataItem.selected = !dataItem.selected;
    },

    edit(item: CashStatementDisplay) {
      this.$router.push(`/cash-statement/edit/${item.id}`);
    },
  },

  computed: {
    url() {
      return "cash-statement/search";
    },

    statements() {
      return orderBy(
        filterBy(this.store.cashStatements, this.store.statementsFilter)
          .filter(
            (x) =>
              (this.store.selectedBankStatus?.id == 0 ?? true) ||
              x.status == this.store.selectedBankStatus?.name
          )
          .filter(
            (x) =>
              (this.store.selectedAccStatus?.id == 0 ?? true) ||
              x.accStatus == this.store.selectedAccStatus?.name
          ),
        this.store.statementsSort
      );
    },

    columns: (): ApiSearchColumn[] => [
      { cell: "statementDetailsButtonTemplate", filterable: false },
      { field: "cashPoint", title: "Pénztár" },
      { field: "bankNumber", title: "Bankszámlaszám" },
      {
        field: "extractDate",
        title: "Kivonat dátuma",
        type: ColumnType.Date,
        fieldClass: "center_text adjust_right",
        headerClass: "center_text adjust_right",
      },
      {
        field: "status",
        title: "Státusz",
        cell: "status",
        multiselect: true,
        multiselectField: "selectedStatuses",
        multiselectOptions: store.bankStatuses.map((x) => ({
          text: x.name,
          value: x.id,
        })),
      },
      {
        field: "accStatus",
        title: "Könyvelési státusz",
        cell: "accStatus",
        multiselect: true,
        multiselectField: "selectedAccStatuses",
        multiselectOptions: store.accountStatuses.map((x) => ({
          text: x.name,
          value: x.id,
        })),
      },
      {
        field: "openBal",
        title: "Nyitó egyenleg",
        fieldClass: "right_text adjust_right",
        headerClass: "right_text adjust_right",
        cell: "openBalTemplate",
      },
      {
        field: "closeBal",
        title: "Záró egyenleg",
        fieldClass: "right_text adjust_right",
        headerClass: "right_text adjust_right",
        cell: "closeBalTemplate",
      },
    ],

    footerText() {
      return createPagerText(
        this.statements.length,
        this.pager.currentPage,
        this.pager.pageSize
      );
    },
  },
});
