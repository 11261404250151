

























































































































































































































































































































import Page from "@/components/Page.vue";
import VStepper from "../components/VStepper.vue";
import VStepperStep from "../components/VStepperStep.vue";
import FlowInvoiceRecordingPage from "./FlowInvoiceRecordingPage.vue";
import CombinedFlowInvoiceRecordingPage from "./CombinedFlowInvoiceRecordingPage.vue";
import FlowFinancialPage from "./FlowFinancialPage.vue";
import FlowStockRelationsPage from "./FlowStockRelationsPage.vue";
import FlowReviewPage from "./FlowReviewPage.vue";
import FlowAccountingPage from "./FlowAccountingPage.vue";
import FlowInvoiceDetailsView from "./FlowInvoiceDetailsView.vue";
import InvoiceWizard from "../components/InvoiceWizard.vue";
import InvoiceRemark from "./InvoiceRemark.vue";
import FlowRemark from "./FlowRemark.vue";
import HeaderItem from "@/components/HeaderItem.vue";
import EditSessionsOverlay from "@/components/EditSessionsOverlay.vue";
import InvoiceRejectionForm from "../components/InvoiceRejectionForm.vue";
import Resizer from "../components/Resizer.vue";

import Vue from "vue";
import dayjs from "dayjs";
import eventBus from "@/services/eventBus";
import baseURL from "../../../services/http";
import store, { FlowStatus, InvoiceFlowStore } from "../services/InvoiceFlowStore";
import permStore, { PermissionStore } from "@/services/permissions";
import wizardStore, { InvoiceWizardStore } from "@/modules/invoiceFlowTask/services/InvoiceTaskWizard";
import Attachment, { attachmentDefaultProps } from "@/modules/generalIngoingInvoice/models/Attachment";
import { PermitStatuses } from "@/modules/generalIngoingInvoice/models/PermitStatus";
import { specialTaxTechProps } from "@/modules/partner/models/SpecialTax";
import { generalIngoingInvoiceDefaultProps } from "@/modules/generalIngoingInvoice/models/GeneralIngoinInvoice";
import { formatDate, formatDateISO, newDate } from "@/utils/formatDates";
import { formatMoney } from "@/filters/money-format";
import * as ActionLogApi from "../../../modules/actionLog/services/api";
import configuration, { AppConfiguration, loadConfiguration } from "@/models/Configuration";
import itemStore from "@/modules/invoiceItems/services/store";
import PartnerDocumentBrowser from "./PartnerDocumentBrowser.vue";
import { Route } from "vue-router";

interface Data {
  wizardStore: InvoiceWizardStore;
  permStore: PermissionStore;
  store: InvoiceFlowStore;
  partnerId: number;
  invoiceRejectionVisible: boolean;
  templateLog: string;
  leftWidth: string;

  configuration: AppConfiguration;
  saving: boolean;
}

function createFlowPath(geni: number, step: number) {
  return `/general-incoming-invoice/flow/edit/${geni}/${step}`;
}

export default Vue.extend({
  components: {
    Page,
    VStepper,
    VStepperStep,
    FlowInvoiceRecordingPage,
    CombinedFlowInvoiceRecordingPage,
    FlowFinancialPage,
    FlowStockRelationsPage,
    FlowReviewPage,
    FlowAccountingPage,
    FlowInvoiceDetailsView,
    InvoiceWizard,
    InvoiceRemark,
    FlowRemark,
    HeaderItem,
    EditSessionsOverlay,
    InvoiceRejectionForm,
    Resizer,
    PartnerDocumentBrowser,
  },

  data: (): Data => ({
    wizardStore,
    permStore,
    store,
    partnerId: 0,
    invoiceRejectionVisible: false,
    templateLog: "",
    leftWidth: "70%",

    configuration,
    saving: false,
  }),

  async beforeMount() {
    // console.log(this.$router.)
    if (!this.store.newPartnerNewInvoice) {
      this.resetStoreValues();

      const invoiceId = Number(this.$route.params.invoiceId) || 0;
      await this.store.loadInvoice(invoiceId);

      const partnerParam = Number(this.$route.params.partnerId);
      this.partnerId = partnerParam > 1 ? partnerParam : this.store.invoice.partner;

      const queries = [
        this.store.loadInvoiceDetails(this.partnerId),
        this.store.loadInvoiceDisplay(invoiceId),
        this.store.loadFlowStatusRelations(invoiceId),
        this.store.loadApprovalMatrix(),
        this.store.loadCurrencies(),
        this.store.loadAllTaxes(),
        this.store.loadSpecialTaxTypes(),
        this.store.loadPaymentModes(),
      ];

      if (invoiceId > 1) queries.unshift(this.permStore.getFlowPermissions(invoiceId));
      await Promise.all(queries);

      await this.store.loadAcceptState(invoiceId);

      if (!this.store.specTaxes.some((x) => x.id == 1)) {
        this.store.specTaxes = [{ ...specialTaxTechProps }, ...this.store.specTaxes];
      }
      this.store.setSpecTax(true);

      if (this.store.invoice.id == 0) {
        this.store.invoice.partner = this.partnerId;
        const today = new Date();
        this.store.invoice.invoiceDate = formatDateISO(today);
        this.store.invoice.payTerm = dayjs(this.store.invoiceDetails.partnerPaymentDeadlineDate).format("YYYY-MM-DD");
        this.store.invoiceDetails;
        this.store.invoice.shipDate = formatDateISO(today);
        this.store.invoice.regDate = formatDateISO(today);
        this.store.invoice.arrivDate = formatDateISO(today);
        this.store.invoice.accDate = formatDateISO(today);
        this.store.invoice.insertDate = formatDateISO(today);
        this.store.invoice.insertTime = newDate(today);
        this.store.invoice.vatDate = formatDateISO(today);

        this.store.invoice.invCurrency = this.store.invoiceDetails.partnerCurrency;
        //await this.store.currencyChanged();
      }

      this.store.setSelectedCurrency();

      this.store.setIsEditingEnabled();

      await this.store.flowUsers.load();
      await this.store.loadUsersWithTechRecord();
      await ActionLogApi.get(Number(this.$route.params.invoiceId)).then((result) => {
        this.templateLog = result.changes[0].newValue;
      });

      await loadConfiguration();
    }
  },

  mounted() {
    const step = Number(this.$route.params.step);
    this.store.step = step > 1 ? step : FlowStatus.Recording;
  },

  created() {
    eventBus.$on("toggle-invoice-rejection", this.toggleInvoiceRejection);
    eventBus.$on("invoice-items:saving", (e) => {
      this.saving = e;
    });
    eventBus.$on("invoice-accounting:saving", (e) => {
      this.saving = e;
    });
  },

  beforeDestroy() {
    eventBus.$off("toggle-invoice-rejection", this.toggleInvoiceRejection);
    this.resetStoreValues();
  },

  beforeRouteLeave(to, from, next) {
    // az jelenlegi és következő route-tól függ a newPartnerNewInvoice
    from.fullPath.startsWith("/general-incoming-invoice/flow/create/") &&
    to.fullPath.startsWith("/partners/create/undefined")
      ? (this.store.newPartnerNewInvoice = true)
      : (this.store.newPartnerNewInvoice = false);
    next();
  },

  watch: {
    // invoked by moving in browser history
    "$route.params.step"(newStep, oldStep) {
      if (newStep == oldStep || this.store.step == newStep) return;
      this.store.step = newStep > 1 ? newStep : FlowStatus.Recording;
    },
  },

  methods: {
    isEmpty(value: string) {
      if (value == null || value == undefined || value.trim() == "") {
        return true;
      }
      return false;
    },

    resetStoreValues() {
      this.store.invoice = { ...generalIngoingInvoiceDefaultProps };
      this.store.selectedCurrency = undefined;
      this.store.attachments = [];
      this.store.attachment = { ...attachmentDefaultProps };
      this.store.accountItems = [];
      this.store.accCodes = [];
      this.store.accGroups = [];
      this.store.dpaymInvoices = [];
      this.store.invoices = [];
      this.store.items = [];
      this.store.invItemMins = [];
      itemStore.itemList.items = [];
      itemStore.trumesServices = [];
    },

    attachmentChanged(attachment: Attachment): void {
      this.store.attachment = attachment;
    },

    setStep(step: number): void {
      if (this.store.step != step) {
        this.store.step = step;
        const geni = Number(this.$route.params.invoiceId) || this.store.invoice.id;
        const path = createFlowPath(geni, step);
        const isPictureRequired = this.$route.meta.isPictureRequired;
        this.$router.push(path);
        this.$route.meta.isPictureRequired = isPictureRequired;
      }
    },

    isComplete(statusId: number): boolean {
      return this.store.flowStatusRelations?.find((x) => x.statusId == statusId && x.id > 1) != null ?? false;
    },

    async update(): Promise<void> {
      const invoiceId = Number(this.$route.params.invoiceId);
      const flowStatusRelation = this.store.flowStatusRelations.find((x) => x.statusId == this.store.step);
      if (flowStatusRelation) {
        await this.store.updateFlowStatusRelation(flowStatusRelation);
      }
      else {
        if (this.store.step != 4) {
          await this.store.createFlowStatusRelation(this.store.step, invoiceId);
        }
      }
      await this.store.loadFlowStatusRelations(invoiceId);
      await this.store.loadFlowActionLogs(invoiceId);
      this.store.setIsEditingEnabled();
    },

    toggleInvoiceRejection(): void {
      this.invoiceRejectionVisible = !this.invoiceRejectionVisible;
    },

    hideResizer() {
      if (this.store.showResizer) {
        this.store.showResizer = false;
        this.leftWidth = "100%";
      } else {
        this.store.showResizer = true;
        this.leftWidth = "70%";
      }
    },

    resizerReleased(leftWidth: string): void {
      this.leftWidth = leftWidth;
    },
  },

  computed: {
    canMoveToNextSteps(): boolean {
      return Number(this.$route.params.invoiceId) > 1;
    },

    sessionID(): string {
      return `InvoiceFlow/${this.store.invoice?.id ?? 0}`;
    },

    partnerName(): string {
      return this.store.invoiceDetails?.partnerName ?? this.store.invoiceDisplay?.partnerName;
    },

    regDate(): string {
      return formatDate(this.store.invoice.regDate);
    },

    payTerm(): string {
      return formatDate(this.store.invoice.payTerm);
    },

    isPayable(): boolean {
      return this.store.invoice.permitStatus == PermitStatuses.Payable;
    },

    netto(): string {
      return (
        formatMoney(
          this.store.invoice.currNetto,
          this.store.invoice.invCurrency,
          configuration.currencyDigits.filter((x) => x.currency === store.invoice.invCurrency).length > 0
            ? configuration.currencyDigits.filter((x) => x.currency === store.invoice.invCurrency)[0].geniHeadDigits
            : 0
        ) +
        " " +
        this.store.invoice.invCurrency
      );
    },

    brutto(): string {
      return (
        formatMoney(
          this.store.invoice.currTotal,
          this.store.invoice.invCurrency,
          configuration.currencyDigits.filter((x) => x.currency === store.invoice.invCurrency).length > 0
            ? configuration.currencyDigits.filter((x) => x.currency === store.invoice.invCurrency)[0].geniHeadDigits
            : 0
        ) +
        " " +
        this.store.invoice.invCurrency
      );
    },

    type(): string {
      switch (this.store.invoice.type) {
      case 1:
        return "";
      case 2:
        return "Új gépjármű beszerzés";
      case 3:
        return "Használt gépjármű beszerzés";
      case 4:
        return "Közvetített szolgáltatás";
      case 7:
        return "Új gépjárműhöz tartozó egyéb";
      case 8:
        return "Használt gépjárműhöz tartozó egyéb";
      case 9:
        return "Gyári alkatrészbeszerzés";
      default:
        return "Egyéb tételes";
      }
    },

    exchangeRate(): string {
      return formatMoney(this.store.invoice.exchangeRate, "", 2);
    },
  },
});
