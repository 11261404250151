
























































































































































































































































































































































































































































































































































































import Vue from 'vue';
import store, { KanriInvoiceStore } from "../services/KanriInvoiceStore";
import ApiResponse from '@/models/ApiResponse';
import http, { handleApiResponse } from '@/services/http';
import Partner from '@/modules/partner/models/Partner';
import PartnerSearch from "../../partner/views/PartnerSearch.vue";
import DateInput from "../../../components/DateInput.vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import MoneyInput from "@/components/MoneyInput.vue";
import GenoExperimentalInvoice, { genoExperimentalInvoiceDefaultProps } from "../models/GenoExperimentalInvoice";
import PartBank from '@/modules/cashPoint/models/PartBank';
import BankAccountPicker from "../../invoiceFlow/components/BankAccountPicker.vue";
import HeaderItem from "@/components/HeaderItem.vue";
import IconButton from "@/components/IconButton.vue";
import { rules } from "../models/GenoExperimentalInvoice";
import { ValidationRules } from '@/models/Validation';
import eventBus from '@/services/eventBus';
import PdfViewer from '@/components/PdfViewer.vue';
import base64toUInt8Array from '@/services/fileConvert';
import configuration, { AppConfiguration } from '@/models/Configuration';
import EmailPickerForPreGeno from "@/modules/kanri/components/EmailPickerForPreGeno.vue";
import CashPointSelect from '@/modules/cashPoint/models/CashPointSelect';
import { ConfirmationMixin } from "@/mixins/confirmation";
import Confirmation from "../../../components/Confirmation.vue";

const base64Arraybuffer = async (data: Uint8Array) => {
    const base64url = await new Promise<string>((r) => {
        const reader = new FileReader()
        reader.onload = () => r(reader.result?.toString()??"")
        reader.readAsDataURL(new Blob([data]))
    })
    return base64url.substring(base64url.indexOf(',')+1)
}

interface Data {
    configuration: AppConfiguration;
    store: KanriInvoiceStore;
    genoExperimentalInvoice: GenoExperimentalInvoice;
    genoExpreimentalInvoiceItems: any[];
    getPrePayExpreimentalInvoiceItems: any[];
    inProgress: boolean;

    isEinvoice: boolean;
    shipDate: string | Date;
    remark: string;
    rules: ValidationRules;
    expReportSrc: Uint8Array;
    finalReportSrc: Uint8Array;

    payInProgress: boolean;
    isLoaded: boolean;
    columns: any;
    isCashPointChoose: boolean;
    selectedCashPoint: CashPointSelect;
}

interface Methods {
    loadPreGeno(): void;
    deleteItem(item: any): void;
    saveItem(item: any): void;
    bankAccountSelected(partBank: PartBank): void;
    onPartnerChange(partner: Partner): void;
    cancelUpdateStatus(): void;
    updateStatus(): void;
    saveEdit(refresh: boolean): void;
    cancelEdit(): void;
    createFinalGeno(): void;
    loadReports(): void;
    deleteGeno(): void;
    pay(): void;
    discountHandler(): void;
    payCard(): void;
    payCheck(): void;
    loadCrystal(): void;
}

export default ConfirmationMixin.extend<Data, Methods, {}, {}>({
    components: {
        PartnerSearch,
        DateInput,
        MoneyDisplay,
        MoneyInput,
        BankAccountPicker,
        HeaderItem,
        IconButton,
        PdfViewer,
        EmailPickerForPreGeno,
        Confirmation
    },

    mixins: [ConfirmationMixin],

    data() {
        return {
            configuration,
            store,
            isCashPointChoose: false,
            selectedCashPoint: new CashPointSelect(),
            genoExperimentalInvoice: { ...genoExperimentalInvoiceDefaultProps },
            genoExpreimentalInvoiceItems: [],
            getPrePayExpreimentalInvoiceItems: [],
            inProgress: false,
            isEinvoice: false,
            shipDate: "",
            remark: "",
            rules,
            payInProgress: false,

            expReportSrc: new Uint8Array(),
            finalReportSrc: new Uint8Array(),
            isLoaded: false,

            columns: [
                {
                    field: "name",
                    title: "Megnevezés",
                    cell: "nameTemplate",
                },
                {
                    field: "partsName",
                    title: "Cikk",
                    className: "left-table-cell"
                },
                {
                    field: "gpartsName",
                    title: "Szolgáltatás",
                    width: 100,
                    className: "left-table-cell"
                },
                {
                    field: "car",
                    title: "Gépjármű",
                    width: 80,
                    className: "left-table-cell"
                },
                {
                    field: "quantity",
                    title: "Mennyiség",
                    cell: "quantityTemplate",
                    headerClassName: "right_text",
                    width: 100,
                },
                {   
                    field: "discountPrice",
                    title: "Kedvezményes eá.",
                    cell: "discountPriceTemplate",
                    headerClassName: "right_text",
                    width: 125,
                },
                {
                    field: "vat",
                    title: "ÁFA",
                    cell: "vatTemplate",
                    headerClassName: "right_text",
                    width: 110,
                },
                {
                    field: "brutto",
                    title: "Bruttó",
                    cell: "bruttoTemplate",
                    headerClassName: "right_text",
                    width: 120,
                },
                {
                    field: "accGroupName",
                    title: "Költséghely",
                    cell: "accGroupNameTemplate",
                    className: "left_text"
                },
                {
                    field: "supplierName",
                    title: "Alvállalkozó",
                    className: "left-table-cell"
                },
            ]
        }
    },

    async beforeMount() {
        await this.loadPreGeno();

        if (this.genoExperimentalInvoice.isRabatVisible) {
            this.columns.splice(5, 0, 
                {
                    field: "unitPrice",
                    title: "Egységár",
                    cell: "unitPriceTemplate",
                    headerClassName: "right_text",
                    width: 115,
                },
            );
        }
        else {
            this.columns = this.columns.filter((x: any) => x.field != "unitPrice");
        }

        await Promise.all([
            this.store.getPaymentModes(),
            this.store.getCurrencies(),
            this.store.getLanguages(),
            this.store.getGenoTypes(),
            this.store.getPreInvoiceTypes(),
            this.store.getAccGroups(),
            this.store.getDivItems(),
            this.store.getPreGenInvStatuses(),
            this.store.getPartBanksByPartner(this.configuration.sysPartnerId),
            this.store.getIsCashier(),
            this.store.getCashPoints(),
        ])
        this.isLoaded = true;

        //this.loadCrystal();
    },

    methods: {
        async loadCrystal() {
            const crystal = await this.store.loadCrystalReport(this.genoExperimentalInvoice.id);
            if (crystal) {
                const pdf = base64toUInt8Array(crystal);
                const b64encoded = await base64Arraybuffer(pdf);

                const pdfWindow = window.open("");
                if (pdfWindow) {
                    pdfWindow.document.write(
                    "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                        encodeURI(b64encoded) +
                        "'></iframe>"
                    );
                }
            }
        },

        discountHandler() {
            this.genoExperimentalInvoice.isRabatVisible = !this.genoExperimentalInvoice.isRabatVisible;
            if (this.genoExperimentalInvoice.isRabatVisible) {
                this.columns.splice(5, 0,
                    {
                        field: "unitPrice",
                        title: "Egységár",
                        cell: "unitPriceTemplate",
                        headerClassName: "right_text",
                        width: 115,
                    },
                );
            }
            else {
                this.columns = this.columns.filter((x: any) => x.field != "unitPrice");
            }
        },

        payCheck(){
            if (this.store.cashPoints.length <= 1){
                this.pay();
            } else {
                this.isCashPointChoose = true;
            }
        },

        pay(){

            if (!this.payInProgress){
                this.payInProgress = true;

                if (this.isCashPointChoose){

                    this.store.payWithCashPoint(this.genoExperimentalInvoice.finalGenoId, this.selectedCashPoint.value).then(result => {
                    this.$router.push(`/cash-statement/edit/${result.cashStatementID}/${result.cashDocumentID}`);
                    }).finally(() => {
                        this.payInProgress = false;
                        this.isCashPointChoose = false;
                    });

                } else {

                    this.store.pay(this.genoExperimentalInvoice.finalGenoId).then(result => {
                    this.$router.push(`/cash-statement/edit/${result.cashStatementID}/${result.cashDocumentID}`);
                    }).finally(() => {
                        this.payInProgress = false;
                        this.isCashPointChoose = false;
                    });
                }
            }
        },
        
        payCard(){
            this.$router.push('/general-outgoing-invoices/pay-open-invoices/geno/' + this.genoExperimentalInvoice.finalGenoId.toString());
        },

        async deleteGeno() {
            const confirmed = await this.confirm("Biztosan törli?");
            if (!confirmed) {
                return;
            }

            this.inProgress = true;
            await this.store.deleteGeno(this.genoExperimentalInvoice.id);
            this.inProgress = false;
            this.$router.push("/geno-experimental-invoices");
        },

        async loadReports() {
            if (this.genoExperimentalInvoice.proformId == 1) {
                //expReportSrc
                console.log("expReportSrc");
                const ret = await http.get<ApiResponse<string>>(`experimental-invoices/report/final-invoice/geno/${this.genoExperimentalInvoice.finalGenoId}/GENO/${true}`).then(handleApiResponse);
                this.expReportSrc = base64toUInt8Array(ret);
            } else if (this.genoExperimentalInvoice.finalGenoId > 1) {
                //finalReportSrc
                console.log("finalReportSrc");
                const ret = await http.get<ApiResponse<string>>(`experimental-invoices/report/final-invoice/geno/${this.genoExperimentalInvoice.finalGenoId}/GENO/${this.genoExperimentalInvoice.isEinvoice}`).then(handleApiResponse);
                this.finalReportSrc = base64toUInt8Array(ret);
            }
            return;
        },

        async createFinalGeno(){
            await this.saveEdit(false);
            this.shipDate = this.genoExperimentalInvoice.invDate;
        },

        async loadPreGeno(){
            const id = Number(this.$route.params.id);
            const response = await http.get(`geno/experimental-invoices/${id}`).then(handleApiResponse);

            console.log(response);
            this.genoExperimentalInvoice = { ...(response as any).head };
            this.genoExpreimentalInvoiceItems = [ ...(response as any).items.filter((x: any) => x.positionType != 'AP') ];
            this.getPrePayExpreimentalInvoiceItems = [ ...(response as any).items.filter((x: any) => x.positionType == 'AP') ];

            await this.loadReports();
        },

        deleteItem(item: any){
            console.log(item);
        },

        async saveItem(item: any){
            await this.store.updateGenoItem(item);
        },

        bankAccountSelected(partBank: PartBank) {
            this.genoExperimentalInvoice.bankAccountObject = { ...partBank };
            this.genoExperimentalInvoice.bankAccountName = partBank.accountNo;
            this.genoExperimentalInvoice.bankAccount = partBank.id;
        },

        async onPartnerChange(partner: Partner) {
            this.genoExperimentalInvoice.partner = partner.id;
            this.genoExperimentalInvoice.partnerName = partner.name;
        },

        cancelUpdateStatus(){
            this.isEinvoice = false;
            this.shipDate = "";
            this.remark = "";
        },

        async updateStatus(){
            await this.saveEdit(false);
            this.inProgress = true;
            const payload = {
                isEinvoice: this.genoExperimentalInvoice.isEinvoice,
                shipDate: this.genoExperimentalInvoice.invDate,
                remark: this.remark,
            };
            const ret = await this.store.updateStatus(this.genoExperimentalInvoice.id, payload);

            if (ret > 1) {
                try {
                    const code = await http.post<ApiResponse<string>>(`geno/experimental-invoices/final-invoice/${ret}`).then(handleApiResponse);
                    if (code != "") {
                        eventBus.$emit("success", `Sikeres végszámla generálás: ${code}`);

                        if (this.genoExperimentalInvoice.isIntr){
                            await this.loadPreGeno();
                            await this.store.setPaidStatus(this.genoExperimentalInvoice.finalGenoId);
                        }

                        await this.loadCrystal();
                    }
                    else {
                        eventBus.$emit("success", `Nem sikerült a végszámla kiállítás!`);
                    }
                } catch (ex) {
                    console.log(ex);
                }
            }
            else {
               eventBus.$emit("error", `Nem sikerült az előjegyzés státusz állítás!`);
            }

            this.inProgress = false;
            this.isEinvoice = false;
            this.shipDate = "";
            this.remark = "";

            await this.loadPreGeno();
            //this.$router.push("/geno-experimental-invoices");
        },

        async saveEdit(refresh: boolean){
            this.genoExperimentalInvoice.invDate = this.genoExperimentalInvoice.invDate.toLocaleString();
            const ret = (await this.store.updateGenoHeadAndItems(this.genoExperimentalInvoice, this.genoExpreimentalInvoiceItems.concat(this.getPrePayExpreimentalInvoiceItems)));
            if (ret) {
                eventBus.$emit("success", "Sikeres mentés!");
                if (refresh) {
                    await this.loadPreGeno();
                }
            }
        },

        cancelEdit(){
            this.$router.push("/geno-experimental-invoices");
        }
    },

    computed: {
        validations(): boolean {
            if (this.genoExperimentalInvoice.divItemName.length < 2 || this.genoExperimentalInvoice.accGroupName.length < 2 || this.genoExperimentalInvoice.pmodeName.length < 2 || this.genoExperimentalInvoice.preTypeName.length < 2 || this.genoExperimentalInvoice.invTypeName.length < 2 || this.genoExperimentalInvoice.bankAccountName.length < 2) {
                return false;
            }
            return true;
        },

        valid(): boolean {
            if (!this.shipDate) {
                return true;
            }
            return false;
        },

        isPartsInvoice(): boolean {
            return this.genoExpreimentalInvoiceItems.some((x) => x.parts > 1);
        },

        isServVoucAmount(): boolean {
            return this.genoExperimentalInvoice.currTotal >= this.configuration.servVoucAmount;
        }
    },
})
