import http, { handleApiResponse, checkResponse } from "@/services/http";
import Partner, { PartnerResponse } from "@/modules/partner/models/Partner";
import ApiResponse from "@/models/ApiResponse";
import User from "@/modules/partner/models/User";
import PartnerType from "../models/PartnerType";
import PartnerStatus from "../models/PartnerStatus";
import SpecialTax from "../models/SpecialTax";
import PaymentMode from "@/modules/paymentMode/models/PaymentMode";
import CompanyForm from "../models/CompanyForm";
import PayClass from "../models/PayClass";
import PartnerGroup1 from "../models/PartnerGroup1";
import PartnerGroup2 from "../models/PartnerGroup2";
import PartBank, { partBankTechProps } from "../../cashPoint/models/PartBank";
import PartSupplier from "../models/PartSupplier";
import PartEmployee from "../models/PartEmployee";
import PartCust from "../models/PartCust";
import SuplType from "../models/SuplType";
import PartPerson from "../models/PartPerson";
import PartnerEuTax from "../models/PartnerEuTax";
import BisnodePartner from "../models/BisnodePartner";
import PrePartnerName from "../models/PrePartnerName";
import StreetType from "../models/StreetType";
import PartnerCustoms from "../models/PartnerCustoms";
import PartnerPriSupl from "../models/PartnerPriSupl";
import City from "@/modules/city/models/City";
import Country from "@/modules/cashPoint/models/Country";
import { count } from "@progress/kendo-data-query/dist/npm/array.operators";
import PartnerWorkflow from "../models/PartnerWorkflow";
import SysParam from "../models/SysParam";
import BalanceStatementEmail from "../models/BalanceStatementEmail";

export function splitAccountNumber(accountNo: string) {
  if (accountNo.includes("-") || accountNo.length > 24) return accountNo;
  const first = accountNo.substring(0, 8);
  const second = accountNo.substring(8, 16) ? accountNo.substring(8, 16) : "";
  const third = accountNo.substring(16, 24) ? accountNo.substring(16, 24) : "";
  return [first, second, third].filter((x) => !!x).join("-");
}

export default class PartnerApi {
  static getOwnPartnerIdFromSysFile(): Promise<number> {
    return http.get<ApiResponse<number>>(`partners/ownid`).then(handleApiResponse);
  }

  static getAll(pageNumber: number, pageSize: number, isCustoms: boolean): Promise<PartnerResponse> {
    return http
      .get<ApiResponse<PartnerResponse>>(`partners/${pageNumber}/${pageSize}/${isCustoms}`)
      .then(handleApiResponse);
  }

  static getAllByName(
    partnerName: string,
    pageNumber: number,
    pageSize: number,
    isCustoms: boolean
  ): Promise<PartnerResponse> {
    return http
      .get<ApiResponse<PartnerResponse>>(`partners/${partnerName}/${pageNumber}/${pageSize}/${isCustoms}`)
      .then(handleApiResponse)
      .then((x) => {
        return x;
      });
  }

  static getAllGeneral(pageNumber: number, pageSize: number, isCustoms: boolean): Promise<PartnerResponse> {
    return http
      .get<ApiResponse<PartnerResponse>>(`partners/general/${pageNumber}/${pageSize}/${isCustoms}`)
      .then(handleApiResponse);
  }

  static getAllByNameGeneral(
    partnerName: string,
    pageNumber: number,
    pageSize: number,
    isCustoms: boolean
  ): Promise<PartnerResponse> {
    return http
      .get<ApiResponse<PartnerResponse>>(`partners/general/${partnerName}/${pageNumber}/${pageSize}/${isCustoms}`)
      .then(handleApiResponse);
  }

  static getAllByNameOverSummary(
    partnerName: string,
    pageNumber: number,
    pageSize: number,
    isCustoms: boolean
  ): Promise<PartnerResponse> {
    return http
      .get<ApiResponse<PartnerResponse>>(`partners/general/over-summary/${partnerName}/${pageNumber}/${pageSize}/${isCustoms}`)
      .then(handleApiResponse);
  }

  static getAllOverSummary(
    pageNumber: number,
    pageSize: number,
    isCustoms: boolean
  ): Promise<PartnerResponse> {
    return http
      .get<ApiResponse<PartnerResponse>>(`partners/general/over-summary/${pageNumber}/${pageSize}/${isCustoms}`)
      .then(handleApiResponse);
  }

  static getAllWithoutPage(): Promise<Partner[]> {
    return http.get<ApiResponse<Partner[]>>(`partners`).then(handleApiResponse);
  }

  static getAllMinWithoutPage(): Promise<Partner[]> {
    return http.get<ApiResponse<Partner[]>>(`partners/min`).then(handleApiResponse);
  }

  static get(id: number): Promise<Partner> {
    return http.get<ApiResponse<Partner>>(`partners/${id}`).then(handleApiResponse);
  }

  static getByIds(ids: number[]): Promise<Partner[]> {
    const params = new URLSearchParams();
    ids.forEach((item) => {
      params.append("partners", item.toString());
    });
    const queryString = params.toString();

    return http
      .get<ApiResponse<Partner[]>>(`partners/getAllByIds?${queryString}`)
      .then(handleApiResponse);
  }
  static update(partner: Partner): Promise<boolean> {
    return http.put(`partners/${partner.id}`, partner).then(checkResponse);
  }

  static create(partner: Partner): Promise<number> {
    return http.post<ApiResponse<number>>(`partners`, partner).then(handleApiResponse);
  }

  static sendBisnode(partnerName: string): Promise<BisnodePartner> {
    return http
      .get<ApiResponse<BisnodePartner>>(`bisnode`, {
        params: {
          q: partnerName,
        },
      })
      .then(handleApiResponse);
  }

  static getUsers(): Promise<User[]> {
    return http.get<ApiResponse<User[]>>(`users`).then(handleApiResponse);
  }

  static getPartnerTypes(): Promise<PartnerType[]> {
    return http.get<ApiResponse<PartnerType[]>>(`partner-type`).then(handleApiResponse);
  }

  static getPartnerStatuses(): Promise<PartnerStatus[]> {
    return http.get<ApiResponse<PartnerType[]>>(`partner-status`).then(handleApiResponse);
  }

  static getSpecialTaxes(): Promise<SpecialTax[]> {
    return http.get<ApiResponse<SpecialTax[]>>(`special-tax`).then(handleApiResponse);
  }

  static getPayModes(): Promise<PaymentMode[]> {
    return http.get<ApiResponse<PaymentMode[]>>(`payment-mode`).then(handleApiResponse);
  }

  static getCompanyForms(): Promise<CompanyForm[]> {
    return http.get<ApiResponse<CompanyForm[]>>(`company-form`).then(handleApiResponse);
  }

  static getPayClasses(): Promise<PayClass[]> {
    return http.get<ApiResponse<PayClass[]>>(`pay-class`).then(handleApiResponse);
  }

  static getPartnerGroups1(): Promise<PartnerGroup1[]> {
    return http.get<ApiResponse<PartnerGroup1[]>>(`partner-group1`).then(handleApiResponse);
  }

  static getPartnerGroups2(): Promise<PartnerGroup2[]> {
    return http.get<ApiResponse<PartnerGroup2[]>>(`partner-group2`).then(handleApiResponse);
  }

  static getPartnerEuTaxesByPartnerId(partnerId: number): Promise<PartnerEuTax[]> {
    return http.get<ApiResponse<PartnerEuTax[]>>(`partner-eu-tax/${partnerId}`).then(handleApiResponse);
  }

  static getAllEuTaxesByPartnerIdParam(partnerId: number): Promise<PartnerEuTax[]> {
    return http.get<ApiResponse<PartnerEuTax[]>>(`partner-eu-tax/all/${partnerId}`).then(handleApiResponse);
  }

  /* eslint-disable */
  static createPartnerEuTaxes(newEuTaxes: PartnerEuTax[]): Promise<any> {
    return http.post(`partner-eu-tax/taxes`, newEuTaxes).then(handleApiResponse);
  }

  static deleteTax(taxId: number) {
    return http.delete(`partner-eu-tax/delete/${taxId}`).then(checkResponse);
  }

  // partBank
  static getPartBanks(partnerId: number): Promise<PartBank[]> {
    return http.get<ApiResponse<PartBank[]>>(`partBank/edit/${partnerId}`).then(handleApiResponse);
  }

  static getPartBanksWithTech(partnerId: number): Promise<PartBank[]> {
    return http
      .get<ApiResponse<PartBank[]>>(`partBank/edit/${partnerId}`)
      .then(handleApiResponse)
      .then((result) => {
        if (!result.some((x) => x.id == 1)) result = [partBankTechProps, ...result];

        return result.map((x) => ({
          ...x,
          accountNoSplit: splitAccountNumber(x.accountNo),
        }));
      });
  }

  static sendBalanceEmails(): Promise<boolean> {
    return http.post(`emails/balance`).then(checkResponse);
  }

  static updatePartBank(item: PartBank): Promise<boolean> {
    return http.put(`partBank/${item.id}`, { partBank: item }).then(checkResponse);
  }

  static createPartBank(partBank: PartBank): Promise<any> {
    return http.put(`partBank/1`, { partBank: partBank }).then(checkResponse);
  }

  //partSupplier
  static getPartSuppliers(partnerId: number): Promise<PartSupplier[]> {
    return http.get<ApiResponse<PartSupplier[]>>(`partSupplier/edit/${partnerId}`).then(handleApiResponse);
  }

  static updatePartSupplier(item: PartSupplier): Promise<boolean> {
    return http.put(`partSupplier/${item.id}`, item).then(checkResponse);
  }

  static getSuplTypes(): Promise<SuplType[]> {
    return http.get<ApiResponse<SuplType[]>>(`supl-type`).then(handleApiResponse);
  }

  static createPartSupplier(partSupplier: PartSupplier): Promise<any> {
    return http.post(`partSupplier`, partSupplier).then(checkResponse);
  }

  //partnerPriSupl
  static getPartnerPriSupl(partnerId: number): Promise<PartnerPriSupl> {
    return http.get<ApiResponse<PartnerPriSupl>>(`part-pri-supl/${partnerId}`).then(handleApiResponse);
  }

  static createPartnerPriSupl(partPriSupl: PartnerPriSupl): Promise<any> {
    return http.post(`part-pri-supl`, partPriSupl).then(handleApiResponse);
  }

  static updatePartnerPriSupl(partPriSupl: PartnerPriSupl): Promise<any> {
    return http.put(`part-pri-supl/${partPriSupl.id}`, partPriSupl).then(checkResponse);
  }

  //partEmployee
  static getPartEmployee(partnerId: number): Promise<PartEmployee> {
    return http.get<ApiResponse<PartEmployee>>(`partEmployee/${partnerId}`).then(handleApiResponse);
  }

  static createPartEmployee(partEmp: PartEmployee): Promise<any> {
    return http.post(`partEmployee`, partEmp).then(handleApiResponse);
  }

  static updatePartEmployee(partEmp: PartEmployee): Promise<boolean> {
    return http.put(`partEmployee/${partEmp.id}`, partEmp).then(checkResponse);
  }

  //partCust
  static getPartCust(partnerId: number): Promise<PartCust> {
    return http.get<ApiResponse<PartCust>>(`partCust/${partnerId}`).then(handleApiResponse);
  }

  static updatePartCust(item: PartCust): Promise<boolean> {
    return http.put(`partCust/${item.id}`, item).then(checkResponse);
  }

  static createPartCust(partCust: PartCust): Promise<any> {
    return http.post(`partCust`, partCust).then(checkResponse);
  }

  static getBalanceStatementEmail(partnerId: number): Promise<BalanceStatementEmail[]> {
    return http.get<ApiResponse<BalanceStatementEmail[]>>(`partners/balstatementemail/${partnerId}`).then(handleApiResponse);
  }

  static createBalanceStatementEmail(partnerId: number): Promise<BalanceStatementEmail> {
    return http.post<ApiResponse<BalanceStatementEmail>>(`partners/balstatementemail/create/${partnerId}`).then(handleApiResponse);
  }

  static modifyBalanceStatementEmail(entity: BalanceStatementEmail): Promise<boolean> {
    return http.post<ApiResponse<boolean>>(`partners/balstatementemail/modify`, entity).then(checkResponse);
  }

  static deleteBalanceStatementEmail(entity: BalanceStatementEmail): Promise<boolean> {
    return http.post<ApiResponse<boolean>>(`partners/balstatementemail/delete`, entity).then(checkResponse);
  }

  //partPerson
  static getPartPersons(partnerId: number): Promise<PartPerson[]> {
    return http.get<ApiResponse<PartPerson[]>>(`partPerson/edit/${partnerId}`).then(handleApiResponse);
  }

  static updatePartPerson(item: PartPerson): Promise<boolean> {
    return http.put(`partPerson/${item.id}`, item).then(checkResponse);
  }

  static createPartPerson(partPerson: PartPerson): Promise<any> {
    return http.post(`partPerson`, partPerson).then(checkResponse);
  }

  //extendedPartnerInfo
  static getAllPrePartnerNames(): Promise<PrePartnerName[]> {
    return http
      .get<ApiResponse<PrePartnerName[]>>(`partners/extended-partner-info/pre-partner-name`)
      .then(handleApiResponse);
  }

  static getAllStreetTypes(): Promise<StreetType[]> {
    return http.get<ApiResponse<StreetType[]>>(`partners/extended-partner-info/street-type`).then(handleApiResponse);
  }

  static updateExtendedPartnerInfo(item: Partner): Promise<boolean> {
    return http.put(`partners/extended/update`, item).then(checkResponse);
  }

  static createExtendedPartnerInfo(item: Partner): Promise<boolean> {
    return http.post(`partners/extended/create`, item).then(checkResponse);
  }

  //customs
  static loadPartnerCustoms(partner: number): Promise<PartnerCustoms[]> {
    return http.get<ApiResponse<PartnerCustoms[]>>(`partners/customs/${partner}`).then(handleApiResponse);
  }

  static deletePartnerCustoms(id: number) {
    return http.delete(`partners/customs/delete/${id}`).then(checkResponse);
  }

  static updatePartnerCustoms(item: PartnerCustoms): Promise<boolean> {
    return http.put(`partners/customs/update/${item.id}`, item).then(checkResponse);
  }

  static createPartnerCustoms(item: PartnerCustoms): Promise<number> {
    return http.post<ApiResponse<number>>(`partners/customs/create`, item).then(handleApiResponse);
  }

  static async loadWorkflows(): Promise<PartnerWorkflow[]> {
    return http.get<ApiResponse<PartnerWorkflow[]>>("partner-workflow").then(handleApiResponse);
  }

  static async setWorkflowIsActive(sysParamKey: string): Promise<SysParam> {
    return http.get<ApiResponse<SysParam>>(`configuration/sys-param/${sysParamKey}`).then(handleApiResponse);
  }

  static async getCountry(postCode: string): Promise<Country> {
    const city: City = await http.get<ApiResponse<City>>(`city/postcode/${postCode}`).then(handleApiResponse);
    const country: Country = await http.get<ApiResponse<Country>>(`country/${city.country}`).then(handleApiResponse);
    return country;
  }

  static kanriSave(id: number): Promise<any> {
    return http.patch<ApiResponse<any>>(`partners/kanri/upsync/${id}`).then(handleApiResponse);
  }
}
