
































































































































































































































































































































































































































































































































































































































































































































































































import Page from "@/components/Page.vue";
import { formatDateISO, newDate, formatDate } from "@/utils/formatDates";
import Vue from "vue";
import store, { CashStatementStore } from "../services/store";
import pStore, { PartnerStore } from "../../partner/services/PartnerStore";
import payStore, {
  PaymentModeStore,
} from "@/modules/paymentMode/services/PaymentModeStore";
import Country, {
  countryDefaultProps,
} from "@/modules/cashPoint/models/Country";
import Partner from "@/modules/partner/models/Partner";
import CashDocumentStatus, {
  cashDocumentStatusDefaultProps,
  cashDocumentStatusEnum,
} from "../models/CashDocumentStatus";
import User, { userDefaultProps } from "@/modules/invoiceGroup/models/User";
import PartnerSearch from "../../partner/views/PartnerSearch.vue";
import eventBus from "@/services/eventBus";
import cStore, {
  CurrencyRateStore,
} from "@/modules/currencyRate/services/CurrencyRateStore";
import CashDocItemDisplay, {
  cashDocItemDisplayDefaultProps,
} from "../models/CashDocItemDisplay";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import CashProcType from "../models/CashProcType";
import { cashDocItemDefaultProps } from "../models/CashDocItem";
import InterCashMovement from "./InterCashMovement.vue";
import BankInViewApiSearchPage from "../../bankExtract/components/BankInViewApiSearchPage.vue";
import BankOutViewApiSearchPage from "@/modules/bankExtract/components/BankOutViewApiSearchPage.vue";
import debounce from "debounce";
import BankOutView from "@/modules/bankOutView/models/BankOutView";
import BankInView from "@/modules/bankInView/models/BankInView";
import CashDocumentCreate from "./CashDocumentCreatePage.vue";
import dayjs from "dayjs";
import PdfViewer from "@/components/PdfViewer.vue";
import CashSubType, { cashSubTypeDefaultProps } from "../models/CashSubType";
import http, { handleApiResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import MoneyInput from "@/components/MoneyInput.vue";
import CashInViewApiSearchPage from "@/modules/cashStatement/cashInView/views/CashInViewApiSearchPage.vue";
import CashOutViewApiSearchPage from "@/modules/cashStatement/cashOutView/views/CashOutViewApiSearchPage.vue";
import DownpaymentHandler from "@/modules/cashPoint/components/DownpaymentHandler.vue";
import BackpaymentHandler from "@/modules/cashPoint/components/BackpaymentHandler.vue";
import NotoPdfViewer from "../components/NotoPdfViewer.vue";
import ValidationError from "@/modules/downPayment/components/ValidationError.vue";
import CurrencyRateApi from "@/modules/currencyRate/services/CurrencyRateApi";

interface Data {
  importingInvoice: boolean;
  importingOverpayInvoice: boolean;
  store: CashStatementStore;
  pStore: PartnerStore;
  cStore: CurrencyRateStore;
  payStore: PaymentModeStore;
  selectedStatus: CashDocumentStatus | undefined;
  selectedCashier: User | undefined;
  selectedCurrency: Country | undefined;
  isDifferentCurrency: boolean;
  closeDate: string;
  rateDate: string;
  minCloseDate: string;
  isPartnerReadOnly: boolean;
  isPartnerNameReadOnly: boolean;
  isDocumentDateReadOnly: boolean;
  isFailedReadOnly: boolean;
  isInterCashMovementReadOnly: boolean;
  showInterCashMovement: boolean;
  isDocumentStatusNotWIP: boolean;
  isPrinting: boolean;
  creatingDocument: boolean;
  loading: boolean;
  procTypeChange: boolean;
  showDownpayment: boolean;
  showBackpayment: boolean;
  maxCloseDate: string;
}

interface Methods {
  documentCheckBeforeSave(): boolean;
  docItemCheckBeforeSave(): boolean;
  scrollIf(x: boolean): void;
  importInvoice(): void;
  importOverpayInvoice(): void;
  reload(): void;
  setReadOnly(): void;
  changeStatus(status: number): Promise<void>;
  checkBeforePrint(): Promise<boolean>;
  print(needToDownload: boolean): Promise<boolean>;
  printedStatus(): void;
  paidStatus(): void;
  failedStatus(): void;
  update(back?: boolean, emitMessage?: boolean): Promise<void>;
  cancel(): void;
  interCashMovement(e: any): void;
  addNewDocItem(): void;
  createDocItem(props?: object, displayProps?: object): void;
  selectedCashProcTypeChanged(item: CashDocItemDisplay): void;
  selectedCashSubTypeChanged(item: CashDocItemDisplay): void;
  itemAmountChanged(item: CashDocItemDisplay): void;
  setDeleting(item: CashDocItemDisplay): void;
  confirmDelete(item: CashDocItemDisplay): void;
  cancelDelete(item: CashDocItemDisplay): void;
  deleteDocItem(item: CashDocItemDisplay): void;
  documentStatusIncludes(statuses?: number[]): boolean;
  isDifferentCurrencyCheck(): boolean;
  selectedPartnerChanged(partner: Partner): void;
  selectedCashierChanged(): void;
  selectedCurrencyChanged(newValue: Country): void;
  getCurrencyRate(mnb?: boolean): Promise<number>;
  addBankOutsSelected(items: BankOutView[]): void;
  addBankInsSelected(items: BankInView[]): void;
  creatingDocumentChanged(value: number | void): void;
  flowTypeChangeHandler(): void;
  isDifferentCurrencyChanged(): void;
  focusElsewhere(): void;
  getNumberAsText(): void;
  eventScroll(e: any): void;
  pdfViewerExit(): void;
  isMonthOrDayCloseCheck(): void;
  incomeTypeChanged(newValue: boolean): void;
  voucherDateChanged(value: string): void;
}

interface Computed {
  columns: object;
  items: CashDocItemDisplay[];
  currAmount: string;
  amount: string;
  exchangeRate: string;
  cashProcTypes: CashProcType[];
  newCashProcTypes: CashProcType[];
  editable: boolean;
  cashDocId: number;
  cashDocItems: CashDocItemDisplay[];
  amountText: string;
  isNew: boolean;
  validation: string;
}

const DEFAULT_MIN_DATE = "2000-01-01";

export default Vue.extend<Data, Methods, Computed, {}>({
  components: {
    CashDocumentCreate,
    Page,
    PartnerSearch,
    NumericTextBox,
    InterCashMovement,
    CashInViewApiSearchPage,
    MoneyInput,
    CashOutViewApiSearchPage,
    DownpaymentHandler,
    BackpaymentHandler,
    NotoPdfViewer,
    ValidationError
  },

  data(): Data {
    return {
      store,
      pStore,
      cStore,
      payStore,
      importingInvoice: false,
      importingOverpayInvoice: false,
      selectedStatus: { ...cashDocumentStatusDefaultProps },
      selectedCashier: { ...userDefaultProps },
      selectedCurrency: { ...countryDefaultProps },
      isDifferentCurrency: false,
      closeDate: "",
      rateDate: "",
      minCloseDate: DEFAULT_MIN_DATE,
      maxCloseDate: "",
      isPartnerReadOnly: true,
      isPartnerNameReadOnly: true,
      isDocumentDateReadOnly: true,
      isFailedReadOnly: true,
      isInterCashMovementReadOnly: true,
      showInterCashMovement: false,
      isDocumentStatusNotWIP: true,
      isPrinting: false,
      creatingDocument: false,
      loading: false,
      procTypeChange: false,
      showDownpayment: false,
      showBackpayment: false,
    };
  },

  async beforeMount() {
    this.store.cashDocItems = [];

    if (this.payStore.paymentModes.length < 2) {
      this.payStore.loadPaymentModesWithoutBusy();
    }

    const cashStatementId = Number(this.$route.params.cashStatementId) || 0;
    const cashDocumentId = Number(this.$route.params.cashDocumentId) || 0;

      await this.store.loadCashStatement(cashStatementId);

    await Promise.all([
      this.store.loadCashPointShort(this.store.cashStatement.cashPoint)
    ]);

    await Promise.all([
      // this.store.loadPartEmployee(this.store.cashDocument.partner),
      this.store.loadCashDocumentStatuses(),
      this.pStore.loadCurrencies(),
      this.store.loadCashProcTypes(),
      this.store.loadCashSubTypes(),
    ]);
    
    if (cashDocumentId > 1) {
      await this.store.loadCashDocument(cashDocumentId);
    } else {
        this.selectedCurrency = this.pStore.currencies.find(
          (x) => x.currency === this.store.cashPoint.currency
        );
        if (!this.selectedCurrency && this.pStore.currencies.length > 0) {
          this.selectedCurrency = this.pStore.currencies[0];
        }

        this.store.cashDocument.initialize(
          this.store.cashPoint,
          cashStatementId,
          this.selectedCurrency
        );

        this.selectedCashier = this.store.currentUser;
        this.selectedCashierChanged();

        const today = new Date();
        this.closeDate = formatDateISO(today);
        this.isMonthOrDayCloseCheck();
        this.maxCloseDate = formatDateISO(today);

        this.incomeTypeChanged(this.store.cashDocument.income);
      }

    this.store.cashProcTypes.forEach((x) => {
        x.cashSubTypes = this.store.cashSubTypes.filter(
          (y) => y.cashProcType === x.id
        );
      });


    if (cashDocumentId > 1){
      await this.store.loadCashDocItemsByDocumentId(cashDocumentId);
    }

    this.selectedStatus = this.store.cashDocumentStatuses.find((x) => {
      return x.id === this.store.cashDocument?.status ?? 2;
    });
    if (!this.selectedStatus && this.store.cashDocumentStatuses.length > 0) {
      this.selectedStatus = this.store.cashDocumentStatuses[0];
    }

    this.selectedCurrency = this.pStore.currencies.find((x) => {
      return x.currency === this.store.cashDocument.invCurrency;
    });
    if (!this.selectedCurrency && this.pStore.currencies.length > 0) {
      this.selectedCurrency = this.pStore.currencies[0];
    }

    if (this.store.cashDocument.invCurrency.toUpperCase() !== "HUF") {
      this.isDifferentCurrency = true;
    }

    this.setReadOnly();

    this.closeDate = formatDateISO(new Date(this.store.cashDocument.closeDate));
    this.rateDate = formatDateISO(new Date(this.store.cashDocument.rateDate));

    if (this.store.cashDocument.userID > 0) {
      await this.store.loadCashier(this.store.cashDocument.userID);
      this.selectedCashier = this.store.cashier;
    }
  },

  created() {
    this.scrollIf = debounce(this.scrollIf, 250);
    this.getNumberAsText = debounce(this.getNumberAsText, 400);
  },

  mounted(){
    const cashDocumentId = Number(this.$route.params.cashDocumentId) || 0;

    const input = (document.getElementById("partnerSearch") as any).children[0].children[0].children[0];

    if (cashDocumentId < 2) {
      input.focus()
    }

    const dateInput = document.getElementById("closeDate");
    const inputEventListener = (e: any) => {     
      if (e.which == 9){
        dateInput?.focus();
      }
    };
    input.addEventListener( 'keyup', inputEventListener);

    const currencyPicker = document.getElementById("currency");
    const dateEventListener = (e: any) => {      
      if (e.which == 9){
        currencyPicker?.focus();
      }
    };
    if (dateInput){
      dateInput.addEventListener( 'focus', () => {
        dateInput.removeEventListener('keyup', dateEventListener);
        setTimeout(() => {
          dateInput.addEventListener( 'keyup', dateEventListener);
        }, 1000);
      });
    }
  },

  methods: {
    async voucherDateChanged(value) {
      if (
        this.store.cashDocuments.some(
          (x) => dayjs(x.closeDate).unix() > dayjs(value).unix() && x.status !== "Rontott"
        )
      ) {
        this.closeDate = dayjs(Date.now()).format("YYYY-MM-DD");
        eventBus.$emit(
          "error",
          "A bizonylat dátuma nem lehet régebbi, mint az előző bizonylaté!"
        );
        return;
      }

      if (dayjs(Date.now()).unix() < dayjs(value).unix()) {
        this.closeDate = dayjs(Date.now()).format("YYYY-MM-DD");
        eventBus.$emit(
          "error",
          "A bizonylat dátuma nem lehet a mai napnál későbbi!"
        );
        return;
      }

      try{await http
        .post(`cash-document/check-date`, { value })
        .then(handleApiResponse);

        this.closeDate = value;
        this.store.cashDocument.rateDate = value;
      }
      catch(ex){
        console.log(ex)
        this.closeDate = dayjs(Date.now()).format("YYYY-MM-DD");
        return;
      }

      this.closeDate = value;
      this.store.cashDocument.rateDate = value;
    },

    incomeTypeChanged(newValue) {
      this.store.cashDocument.uniqueNo = newValue
        ? this.store.cashPoint.inDocNo + 1
        : this.store.cashPoint.outDocNo + 1;

      this.isMonthOrDayCloseCheck();
    },

    isMonthOrDayCloseCheck() {
      if (this.store.cashPoint.isDay) {
        this.minCloseDate = this.closeDate = formatDateISO(
          this.store.cashStatement.extractDate
        );
        this.isDocumentDateReadOnly = true;
      } else if (this.store.cashPoint.isMonth) {
        const lastDate = formatDateISO(this.store.cashStatement.extractDate);
        let date = "";
        if (this.store.cashDocument.income) {
          date = formatDateISO(this.store.cashStatement.lastIncome?.closeDate);
        } else {
          date = formatDateISO(this.store.cashStatement.lastExpense?.closeDate);
        }
        this.minCloseDate = date != "" ? date : lastDate;
        this.isDocumentDateReadOnly = false;
      } else {
        const dates = this.store.cashDocuments
          .filter((x) => x.document.status == 4)
          .map((x) => x.closeDate)
          .sort();
        this.minCloseDate =
          dates.length > 0 ? formatDateISO(dates[0]) : DEFAULT_MIN_DATE;
      }
    },

    pdfViewerExit(){
      this.store.showPrintedCashDocument = false;
      this.store.printedCashDocumentUInt8Array = new Uint8Array();
      this.reload();
    },
    
    eventScroll(e) {
      console.log(e);
    },

    async getNumberAsText() {
      this.store.cashDocument.amoutText = await http
        .get<ApiResponse<string>>(
          `currency/text/${this.store.cashDocument.amount}/${this.store.cashStatement.currency}`
        )
        .then(handleApiResponse);
    },

    focusElsewhere() {
      document.getElementById("closeDate")?.focus();
    },

    async creatingDocumentChanged(value) {
      this.creatingDocument = false;
      if (value <= 0 || !value) return;

      const cashStatementId = Number(this.$route.params.cashStatementId);
      await this.store.loadCashDocumentsByStatementId(cashStatementId);
      this.store.setIsOwnIdentifierReadOnly();
      this.store.setIsOpenCashPointReadOnly();

      this.$router.push(`${value}`);
    },

    async addBankInsSelected(items) {
      await this.store
        .addBankInViewSelectedItems(
          Number(this.$route.params["cashDocumentId"]),
          items
        )
        .then(async () => {
          await this.store.loadCashDocItemsByDocumentId(
            Number(this.$route.params["cashDocumentId"])
          );
          await this.store.loadCashDocument(
            Number(this.$route.params["cashDocumentId"])
          );
          eventBus.$emit("CashDocumentPage:BankInsAdded");
        });
    },

    async addBankOutsSelected(items) {
      await this.store
        .addBankOutViewSelectedItems(
          Number(this.$route.params["cashDocumentId"]),
          items
        )
        .then(async () => {
          await this.store.loadCashDocItemsByDocumentId(
            Number(this.$route.params["cashDocumentId"])
          );
          await this.store.loadCashDocument(
            Number(this.$route.params["cashDocumentId"])
          );
          eventBus.$emit("CashDocumentPage:BankOutsAdded");
        });
    },

    documentCheckBeforeSave() {
      if (this.store?.cashDocument?.partnerName?.length < 0) {
        eventBus.$emit(
          "error",
          "Kötelező az Átvevő / Befizető nevének megadása."
        );
        return false;
      }
      if (
        this.store.cashDocument.partner == 0 &&
        this.store.cashDocument.partnerName.trim() === ""
      ) {
        eventBus.$emit(
          "error",
          "Partner vagy befizető/átvevő megadása közelező."
        );
        return false;
      }
      if (this.store.cashDocument.exchangeRate == 0) {
        eventBus.$emit("error", "Az árfolyam nem lehet 0.");
        return false;
      }
      if (!this.selectedCurrency?.id) {
        eventBus.$emit("error", "Hibás a megadott a Pénznem.");
        return false;
      }
      return true;
    },

    docItemCheckBeforeSave() {
      if (
        this.store.cashDocItems.some(
          (x) => x.docItem.amount == 0 || x.docItem.currAmount == 0
        )
      ) {
        eventBus.$emit("error", "A fizetett összeg nem lehet nulla.");
        return false;
      }
      if (
        this.store.cashDocItems.some(
          (x) => x.id == 0 && x.docItem.cashProcType == 1
        )
      ) {
        eventBus.$emit("error", "Helytelenül lett kiválasztva a Mozgás típus.");
        return false;
      }
      if (
        this.store.cashDocItems.some(
          (x) =>
            x.id == 0 && x.cashSubTypes.length > 1 && x.docItem.cashSubType == 1
        )
      ) {
        eventBus.$emit(
          "error",
          "Helytelenül lett kiválasztva a Mozgás altípus."
        );
        return false;
      }

      if (this.store.cashDocItems.some(x => x.invoiceToPayment > 0 && x.invoiceToPayment > x.currAmount && ((x.amount % 5) != 0)))
      {
        eventBus.$emit("error", "Számla részfizetésnél 5-re kerekített fizetési értéket lehet megadni!");
        return false;
      }

      return true;
    },

    scrollIf(x: boolean) {
      if (x) this.$vuetify.goTo(document.body.scrollHeight, { duration: 1000 });
      else this.$vuetify.goTo(0);
    },

    importInvoice() {
      this.showDownpayment = false;
      this.importingInvoice = !this.importingInvoice;
      this.showInterCashMovement = false;
      this.importingOverpayInvoice = false;
      this.flowTypeChangeHandler();
      this.scrollIf(this.importingInvoice);
    },

    importOverpayInvoice() {
      this.showDownpayment = false;
      this.importingOverpayInvoice = !this.importingOverpayInvoice;
      this.showInterCashMovement = false;
      this.importingInvoice = false;
      this.scrollIf(this.importingOverpayInvoice);
    },

    async reload() {
      const cashDocumentId = Number(this.$route.params.cashDocumentId) || 0;
      await this.store.loadCashDocument(cashDocumentId);
      await this.store.loadCashDocItemsByDocumentId(cashDocumentId);

      this.setReadOnly();
    },

    setReadOnly() {
      this.isDocumentStatusNotWIP = this.store.cashDocument.status != 2;

      this.isPartnerReadOnly =
        this.isDocumentStatusNotWIP || this.store.cashDocItems.length != 0;

      this.isPartnerNameReadOnly =
        this.isDocumentStatusNotWIP ||
        this.store.cashDocItems.filter((x) => x.id != 0).length != 0;

      this.isInterCashMovementReadOnly =
        this.isDocumentStatusNotWIP ||
        this.store.cashDocItems.length != 0 ||
        this.store.cashDocument.noCuracc ||
        this.store.cashDocument.income;
    },

    async checkBeforePrint() {
      if (
        this.store.cashDocItems.some(
          (x) =>
            x.amount != x.docItem.amount ||
            x.currAmount != x.docItem.currAmount ||
            this.store.cashDocItems.filter((x) => x.id != 0).length == 0
        )
      ) {
        await this.update(false,false);
        if (
          this.store.cashDocItems.some(
            (x) =>
              x.amount != x.docItem.amount ||
              x.currAmount != x.docItem.currAmount ||
              this.store.cashDocItems.filter((x) => x.id != 0).length == 0
          )
        ) {
          eventBus.$emit("warn", "A bizonylat tételek nem lettek elmentve!");
          return false;
        }
      }
      return true;
    },

    async print(needToDownload = false) {
      let pdfLength = 0;
      try {
        this.isPrinting = true;
        const pdfWindow = window.open();

        // TODO: nyomtatás
        const pdf = await this.store
          .printCashDocumentRiport()
          .then((result) => {
            /*if (needToDownload) {
              const linkSource = `data:application/pdf;base64,${result}`;
              const downloadLink = document.createElement("a");
              const fileName =
                "bizonylat_" +
                this.store.cashDocument.uniqueNo +
                "_" +
                this.store.cashDocument.annexNo +
                ".pdf";
              downloadLink.href = linkSource;
              downloadLink.download = fileName;
              downloadLink.click();
            }*/
            
            if (pdfWindow) {
              pdfWindow.document.write(
                "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                  encodeURI(this.store.printedCashDocumentBase64) +
                  "'></iframe>"
              );

              pdfWindow.focus();
            }

            return result;
          });

        pdfLength = pdf.length;
      } catch (ex) {
        console.log(ex);
      } finally {
        this.isPrinting = false;
      }
      return pdfLength > 0;
    },

    async printedStatus() {
      if (this.documentStatusIncludes([3, 4, 5])) {
        this.print(true);
        return;
      }

      const k = this.store.cashDocItems.reduce((x, y) => x + y.currAmount, 0);
      const f = Math.pow(10, 2);
      const res = Math.round(k * f) / f;

      const k2 = this.store.cashDocItems.reduce((x, y) => x + y.amount, 0);
      const p2 = k2 / this.store.cashDocument.exchangeRate;
      const res2 = Math.round(p2 * f) / f;

      if (
        this.items.length > 0 &&
        this.store.cashDocument.currAmount != res &&
        this.store.cashDocument.currAmount != res2
      ) {
        eventBus.$emit(
          "warn",
          "A tételek összege nem egyezik meg a kivonat összegével!"
        );

        return;
      }

      const oldStatus = this.store.cashDocument.status;
      const oldSelectedStatus = this.selectedStatus;
      try {
        this.isPrinting = true;
        if (!this.documentCheckBeforeSave()) return;
        if (!this.docItemCheckBeforeSave()) return;

        this.store.cashDocument.invCurrency =
          this.selectedCurrency?.currency ?? this.store.cashDocument.invCurrency;
        this.store.cashDocument.partnerName = this.store.cashDocument.partnerName.toUpperCase();

        await this.store.updateCashDocument(false);
        await this.store.updateCashDocItems(false);

        if (await this.checkBeforePrint()) {
          try {
            await this.changeStatus(Number(cashDocumentStatusEnum.Printed));
            await this.store.setCashDocumentPrinted();
            await this.print(false);
            this.importingInvoice = false;
            this.showInterCashMovement = false;
          } catch (e) {
            this.store.cashDocument.status = oldStatus;
            this.selectedStatus = oldSelectedStatus;
          }
        }
        this.reload();
      } catch (ex) {
        console.log(ex);
        this.store.cashDocument.status = oldStatus;
        this.selectedStatus = oldSelectedStatus;
        throw ex;
      } finally {
        this.isPrinting = false;
      }
    },

    async paidStatus() {
      try {
        eventBus.$emit("busy-message", true);
        await this.update(false,false);
        if (!this.documentCheckBeforeSave()) return;
        if (!this.docItemCheckBeforeSave()) return;
        if ([2, 3].includes(this.store.cashDocument.status)) {
          await this.changeStatus(4);
          await this.store.setCashDocumentPaid(
            this.store.cashStatement.cashPoint
          );
        }

        if (!this.store.cashDocItems.some((x) => x.invPrefix === "NOTO"))
          this.reload();
      } catch (ex) {
        console.log(ex);
      } finally {
        eventBus.$emit("busy-message", false);
      }
    },

    async failedStatus() {
      const oldStatus = this.store.cashDocument.status;
      try {
        const generatedFinalInvoice = this.store.cashDocItems.filter(
          (x) => x.invPrefix === "NOTO"
        )[0];
        if (generatedFinalInvoice && this.store.cashDocument.status === 4) {
          throw `Nem lehet rontottra állítani a bizonylatot, mert a ${generatedFinalInvoice.invNo} számlából végszámla generálódott!`;
        }
        await this.changeStatus(5);
        await this.store.updateCashDocument(false);
        this.setReadOnly();
      } catch (ex) {
        this.store.cashDocument.status = oldStatus;
        throw ex;
      }
    },

    async changeStatus(status: number) {
      if (!status || status <= 0) return;
      if (
        this.store.cashDocItems.filter((item) => item.id == 0).length > 0 ||
        this.store.cashDocItems.some((i) => {
          i.currAmount != i.docItem.currAmount || i.note != i.docItem.note;
        })
      ) {
        eventBus.$emit("warn", "A bizonylatnak még van nem elmentett tétele!");
        return;
      }

      const canBeChanged = await this.store.getCashDocumentStatusCanBeChanged(
        this.store.cashDocument.id,
        status
      );
      if (!canBeChanged) {
        throw "Nem lehetett átállítani a státuszt!";
      }

      this.store.cashDocument.status = status;
      console.log("bizonylat státusz: " + this.store.cashDocument.status);
      this.selectedStatus = this.store.cashDocumentStatuses.find((x) => {
        return x.id === status;
      });
    },

    async update(back = false, emitMessage= false) {
      if (!this.documentCheckBeforeSave()) return;
      if (!this.docItemCheckBeforeSave()) return;

      this.store.cashDocument.invCurrency =
        this.selectedCurrency?.currency ?? this.store.cashDocument.invCurrency;
      this.store.cashDocument.partnerName = this.store.cashDocument.partnerName.toUpperCase();

      if (this.isNew){
        const createdID = await this.store.createCashDocument(this.store.cashDocument);
        
        this.store.cashDocument.income
          ? this.store.cashPoint.inDocNo++
          : this.store.cashPoint.outDocNo++;

        const cashStatementId = Number(this.$route.params.cashStatementId) || 0;
        this.$router.push(`/cash-statement/edit/${cashStatementId}/${createdID}`);
      } else {
        await this.store.updateCashDocument(emitMessage);
        await this.store.updateCashDocItems(emitMessage);

        if (back) this.cancel();
      }
      
      /* else this.reload(); */
    },

    cancel() {
      const cashStatementId = Number(this.$route.params.cashStatementId);
      this.$router.push(`/cash-statement/edit/${cashStatementId}`);
    },

    async interCashMovement(e: any) {
      await this.store.startInterCashMovement(e).then(() => {
        this.showInterCashMovement = false;
        this.reload();
      });
    },

    addNewDocItem() {
      const cashDocumentId = Number(this.$route.params.cashDocumentId) || 0;
      this.createDocItem(
        {
          document: cashDocumentId,
          partner: this.store.cashDocument.partner,
          dimCode1: this.store.cashPoint.dimCode1,
          invCurrency:
            this.selectedCurrency?.currency ??
            this.store.cashDocument.invCurrency,
          rateDate: store.cashDocument.rateDate,
          exchangeRate: store.cashDocument.exchangeRate,
        },
        {
          selectedCashProcType: this.newCashProcTypes.find((x) =>
            this.store.cashDocument.income ? x.defaultIn : x.defaultOut
          ),
        }
      );
    },

    flowTypeChangeHandler() {
      const tempItems = this.store.cashDocItems.filter((x) => x.id < 2);
      if (tempItems.length > 0) {
        tempItems.forEach((x) =>
          this.store.cashDocItems.splice(
            this.store.cashDocItems.findIndex((y) => y.id == x.id)
          )
        );
      }
    },

    createDocItem(props?: object, displayProps?: object) {
      const newDocItem: CashDocItemDisplay = {
        ...cashDocItemDisplayDefaultProps,
        ...displayProps,
        docItem: { ...cashDocItemDefaultProps, ...props },
      };
      this.selectedCashProcTypeChanged(newDocItem);
      this.store.cashDocItems.unshift(newDocItem);
    },

    selectedCashProcTypeChanged(item: CashDocItemDisplay) {
      this.procTypeChange = true;
      if ([16, 24, 25].includes(Number(item.selectedCashProcType?.id ?? 1))) {
        eventBus.$emit("error", "Ezt a mozgás típust nem választhatja!");
        item.selectedCashProcType = this.cashProcTypes[0];
        this.procTypeChange = false;
        return;
      }

      item.docItem.cashProcType = item.selectedCashProcType?.id ?? 1;

      item.cashSubTypes = this.store.cashSubTypes.filter(
        (y) => y.cashProcType == item.selectedCashProcType?.id || y.id === 1
      );

      const inc = item.cashSubTypes.find(
        (x) => x.id === item.selectedCashSubType?.id
      );
      if (!inc) {
        item.selectedCashSubType = item.cashSubTypes[0];
      }

      /* item.selectedCashProcType = this.store.cashProcTypes.filter(
        (x) => x.id === item.docItem.cashProcType
      )[0]; */

      /* const def = cashSubTypeDefaultProps;
      def.cashProcType = item.selectedCashProcType?.id ?? 1;
      if (!item.cashSubTypes.some((x) => x.id === 1)) {
        item.cashSubTypes.unshift(def);

      } */
      this.procTypeChange = false;
      item.selectedCashSubType = item.cashSubTypes.filter(
          (x) => x.id == item.docItem.cashSubType
        )[0];
      this.selectedCashSubTypeChanged(item);
    },

    selectedCashSubTypeChanged(item: CashDocItemDisplay) {
      item.docItem.cashSubType = item.selectedCashSubType?.id ?? 1;
      item.docItem.extDimcode1 =
        item.selectedCashSubType?.extDimcode1 ??
        item.selectedCashProcType?.extDimcode1 ??
        "";
      item.docItem.extDimcode2 =
        item.selectedCashSubType?.extDimcode2 ??
        item.selectedCashProcType?.extDimcode2 ??
        "";
      item.docItem.extDimcode3 =
        item.selectedCashSubType?.extDimcode3 ??
        item.selectedCashProcType?.extDimcode3 ??
        "";
    },

    itemAmountChanged(item: CashDocItemDisplay) {
      if (item.invCurrency === store.cashStatement.currency) {
        item.docItem.amount = item.docItem.currAmount;
      } else
        item.docItem.amount =
          item.docItem.currAmount * store.cashDocument.exchangeRate;

      item.amount = item.docItem.amount;

      this.store.cashDocument.amount = this.items.reduce(
        (x, y) => x + y.docItem.amount,
        0
      );

      if (this.store.cashDocument.exchangeRate > 1) {
        const k =
          this.store.cashDocument.amount / this.store.cashDocument.exchangeRate;
        const f = Math.pow(10, 2);
        const res = Math.round(k * f) / f;

        this.store.cashDocument.currAmount = res;
      } else {
        this.store.cashDocument.currAmount = this.store.cashDocument.amount;
      }

      this.getNumberAsText();
    },

    setDeleting(item: CashDocItemDisplay) {
      if (item.id == 0) {
        this.deleteDocItem(item);
        return;
      }
      item.deleting = !item.deleting;
    },

    confirmDelete(item: CashDocItemDisplay) {
      item.deleting = false;
      this.deleteDocItem(item);
      if (this.store.cashDocument.income) {
        eventBus.$emit("CashDocumentPage:BankInsAdded");
      } else eventBus.$emit("CashDocumentPage:BankOutsAdded");
      this.update(false,false);
    },

    cancelDelete(item: CashDocItemDisplay) {
      item.deleting = false;
    },

    deleteDocItem(item: CashDocItemDisplay) {
      const index = this.store.cashDocItems.indexOf(item);
      if (index > -1) this.store.cashDocItems.splice(index, 1);
      this.setReadOnly();
    },

    documentStatusIncludes(
      statuses = [
        cashDocumentStatusEnum.UnderProcess,
        cashDocumentStatusEnum.Paid,
        cashDocumentStatusEnum.MessedUp,
      ]
    ) {
      if (statuses.includes(this.store.cashDocument.status)) return true;
      else return false;
    },

    isDifferentCurrencyCheck() {
      if (this.store.cashDocument.status === 5) return true;
      else return false;
    },

    selectedPartnerChanged(partner: Partner) {
      if (this.isPartnerReadOnly) return;

      this.store.cashDocument.partner = partner.id;
      this.store.cashDocument.partnerName = partner.name;
    },

    selectedCashierChanged() {
      this.store.cashDocument.userID = this.selectedCashier?.id ?? 1;
    },

    isDifferentCurrencyChanged() {
      if (!this.isDifferentCurrency) {
        store.cashDocument.currency = this.store.cashStatement.currency;
        this.selectedCurrency = pStore.currencies.filter(
          (x) => x.currency === this.store.cashDocument.currency
        )[0];
        store.cashDocument.exchangeRate = 1;
        this.rateDate = dayjs(this.store.cashDocument.closeDate).format(
          "YYYY-MM-DD"
        );
        this.store.cashDocument.currAmount = this.store.cashDocument.amount;
        /* store.cashDocItems.forEach((element) => {
          element.docItem.currAmount = Number(
            Math.round(
              element.docItem.currAmount * element.docItem.exchangeRate
            ).toFixed(0)
          );
          element.docItem.invCurrency = this.store.cashDocument.invCurrency;
          element.docItem.exchangeRate = 1;
          element.docItem.rateDate = dayjs(
            this.store.cashDocument.closeDate
          ).format("YYYY-MM-DD");
        }); */
      }
    },

    async selectedCurrencyChanged(newValue: Country) {
      //Ha nincs valamiért ID-je a kiválasztott pénznemnek
      if (!this.selectedCurrency?.id || this.selectedCurrency.id < 1) {
        eventBus.$emit("error", "Hibás a megadott a Pénznem.");
        return;
      }

      if (newValue?.id > 0) {
        if (this.selectedCurrency?.currency.toUpperCase().trim() === "HUF") {
          this.store.cashDocument.currency = this.selectedCurrency.currency;
          this.store.cashDocument.exchangeRate = 1;
          this.store.cashDocument.rateDate = formatDateISO(new Date());
          this.store.cashDocItems.forEach(
            (x) =>
              (x.invCurrency = this.selectedCurrency?.currency ?? x.invCurrency)
          );
          return;
        }

        this.store.cashDocument.currency =
          this.selectedCurrency.currency.toUpperCase().trim() ??
          this.store.cashStatement.currency;

        await this.getCurrencyRate();

        if (this.store.cashDocument.exchangeRate === 1) {
          eventBus.$emit("error", "Nem sikerült lekérni az árfolyamot!");
          if (!(this.cStore.currencyRates.length > 0))
            await this.cStore.loadCurrencyRates();

          this.cStore.currencyRates.sort(
            (a, b) =>
              new Date(b.rateDate).getTime() - new Date(a.rateDate).getTime()
          );

          this.selectedCurrency = this.pStore.currencies[0];
          this.store.cashDocument.currency = this.pStore.currencies[0].currency;

          const rate = this.cStore.currencyRates.find(
            (x) => x.currency === this.selectedCurrency?.currency
          );
          if (rate) this.cStore.currencyRate = rate;
          else this.cStore.currencyRate = this.cStore.currencyRates[0];
          await this.getCurrencyRate(false);
        }

        if (
          this.store.cashDocItems.every(
            (x) =>
              x.invCurrency !== this.selectedCurrency?.currency ??
              this.store.cashDocument.invCurrency
          )
        )
          this.store.cashDocument.currAmount = Number(
            (
              this.store.cashDocument.amount /
              this.store.cashDocument.exchangeRate
            ).toFixed(2)
          );
        else {
          this.store.cashDocument.currAmount = this.store.cashDocItems.reduce(
            (x, y) => x + y.currAmount,
            0
          );
        }

        /* this.store.cashDocItems.forEach((element) => {
          element.docItem.exchangeRate = this.store.cashDocument.exchangeRate;
          element.docItem.currAmount = Number(
            (element.docItem.amount / element.docItem.exchangeRate).toFixed(2)
          );
          element.docItem.invCurrency =
            this.selectedCurrency?.currency ??
            this.store.cashDocument.invCurrency;

          element.docItem.rateDate = this.store.cashDocument.rateDate;
          console.log(element);
        }); */
        return;
      }
    },

    async getCurrencyRate(mnb = true): Promise<number> {
      if (!this.selectedCurrency?.currency) return -2;
      if (!(this.selectedCurrency.currency.length > 0)) {
        const errorMsg =
          "A középárfolyam lekérdezéséhez kötelező a listában szereplő elemek egyikének kiválasztása.";
        eventBus.$emit("error", errorMsg);
        console.error(errorMsg);
        return -1;
      }

      if (!(this.cStore.currencyRates.length > 0))
        await this.cStore.loadCurrencyRates();

      await this.cStore.loadCurrencyRate(this.selectedCurrency.currency);
      const rateType = this.cStore.currencyRate.mnbCentralRate;

      this.cStore.currencyRate.unit = ["JPY", "IDR", "KRW"].includes(
        this.cStore.currencyRate.localCurrency
      )
        ? 100
        : 1;

      this.store.cashDocument.exchangeRate =
        rateType ??
        1 /
          this.cStore.currencyRates.filter(
            (x) => x.currency === this.selectedCurrency?.currency
          )[0].unit;
      this.store.cashDocument.rateDate = formatDateISO(
        new Date(this.cStore.currencyRate.rateDate)
      );

      return 1;
    },
  },

  watch: {
    "store.cashDocItems.length"(newValue) {
      if (newValue > 0) this.isPartnerReadOnly = true;
      else this.isPartnerReadOnly = false;

      this.store.cashDocument.amount = this.store.cashDocItems.reduce(
        (x, y) => x + y.docItem.amount,
        0
      );

      if (this.store.cashDocument.exchangeRate > 1) {
        const k =
          this.store.cashDocument.amount / this.store.cashDocument.exchangeRate;
        const f = Math.pow(10, 2);
        const res = Math.round(k * f) / f;

        this.store.cashDocument.currAmount = res;
      }

      this.getNumberAsText();
    },

    cashDocId() {
      window.location.reload();
    },

    cashDocItems(){
      this.store.cashDocItems.forEach((x) => {
        x.selectedCashProcType = this.store.cashProcTypes.find(
          (y) => y.name == x.cashProcType
        );

        this.selectedCashProcTypeChanged(x);

        const cashProcTypes = [...this.cashProcTypes];
        if (x.selectedCashProcType && !(cashProcTypes.find(y => y.id == x.selectedCashProcType?.id))){
          cashProcTypes.push(x.selectedCashProcType);
        }

        x.cashProcTypes = cashProcTypes;
      });
    },

    async closeDate(newValue) {
      this.store.cashDocument.closeDate = newDate(newValue);
      this.store.cashDocument.rateDate = newDate(newValue);
      if (!newValue || this.store.cashDocument.currency === "HUF") return;

      await CurrencyRateApi.getRateAndDate(
        this.store.cashDocument.currency,
        newValue
      ).then((result) => {
        this.store.cashDocument.exchangeRate = result.centralRate / result.unit;
        this.store.cashDocument.rateDate = formatDateISO(newValue);
      });
    },
  },

  computed: {
    validation() {
      if (!this.isNew){
        return '';
      }

      if (this.store.cashDocument.cashAccount == 0) {
        return "Pénztár megadása közelező.";
      }
      if (
        this.store.cashDocument.partner == 0 &&
        this.store.cashDocument.partnerName.trim() === ""
      ) {
        return "Partner vagy befizető/átvevő megadása közelező.";
      }
      if (!(this.store.cashDocument.partnerName.length > 0)) {
        return "Átvevő / Befizető nevének megadása kötelező.";
      }
      if (!this.store.cashDocument.closeDate) {
        return "Hibás a megadott Bizonylat dátum.";
      }
      if (this.store.cashDocument.closeDate < this.minCloseDate) {
        return `A Bizonylat dátuma nem lehet az előző bizonylat dátuma előtt: ${formatDate(
          new Date(this.minCloseDate)
        )}`;
      }
      if (formatDateISO(this.store.cashDocument.closeDate) > this.maxCloseDate) {
        return "A Bizonylat dátuma nem lehet jövőbeli.";
      }
      if (this.store.cashDocument.exchangeRate == 0) {
        return "Az árfolyam nem lehet 0.";
      }
      return "";
    },

    isNew(){
      return this.store.cashDocument.id < 2;
    },

    amountText(){
      return store.cashDocument.amoutText.slice(0, -3);
    },

    cashDocItems(){
      return this.store.cashDocItems;
    },

    cashDocId() {
      return Number(this.$route.params.cashDocumentId);
    },

    editable() {
      return store.cashDocument.status < cashDocumentStatusEnum.Printed;
    },

    columns: () => [
      {
        field: "docItem.cashProcType",
        title: "Mozgás típus",
        cell: "cashProcTypeTemplate",
      },
      {
        field: "docItem.cashSubType",
        title: "Mozgás altípus",
        cell: "cashSubTypeTemplate",
      },
      { field: "docItem.docNo", title: "Bizonylatszám", cell: "docNoTemplate" },
      //{ field: "docItem.invNo", title: "Számlaszám", cell: "invNoTemplate" },
      {
        field: "docItem.currAmount",
        title: "Fizetett összeg (devizában)",
        format: "{0:n}",
        className: "right_text",
        headerClassName: "right_text",
        cell: "currAmountTemplate",
      },      
      {
        field: "docItem.exchangeRate",
        title: "Árfolyam (végleges)",
        format: "{0:n}",
        className: "right_text",
        headerClassName: "right_text",
      },
      {
        field: "docItem.amount",
        title: "Összeg",
        format: "{0:n}",
        className: "right_text",
        headerClassName: "right_text",
      },
      { field: "docItem.note", title: "Megjegyzés", cell: "noteTemplate" },
      { cell: "commandTemplate", width: 60 },
      { width: 0 },
    ],

    items() {
      return this.store.cashDocItems;
    },

    currAmount: {
      get() {
        return this.store.cashDocument.currAmount.toLocaleString();
      },
      set(value) {
        this.store.cashDocument.currAmount = parseFloat(
          value
            .trim()
            .replace(",", ".")
            .replace(" ", "")
        );
      },
    },

    amount() {
      return this.store.cashDocument.amount.toLocaleString();
    },

    exchangeRate() {
      return this.store.cashDocument.exchangeRate.toLocaleString();
    },

    cashProcTypes() {
      return this.store.cashProcTypes
        .filter((x) => x.income == this.store.cashDocument.income)
        .filter((x) => x.id !== 1);
    },

    newCashProcTypes() {
      const idList = [4, 5, 15, 19, 7, 2, 3, 16, 24, 25];
      // const idList = [1, 10, 11, 12, 17, 20, 21, 22, 23, 26];
      // if (this.store.partEmployee) idList.push(18);
      return this.store.cashProcTypes
        .filter((x) => !x.invJoin)
        .filter((x) => x.income == this.store.cashDocument.income)
        .filter((x) => idList.includes(x.id));
      // .filter((x) => !idList.includes(x.id)) // ha a másik listábol megy
    },
  },
});
