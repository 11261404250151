



















































































































































































































































































































































































































































































import { ConfirmationMixin } from "@/mixins/confirmation";

import ValidationError from "../components/ValidationError.vue";
import DownPaymentCreatePage from "./DownPaymentCreatePage.vue";
import MoneyInput from "@/components/MoneyInput.vue";
import IconButton from "@/components/IconButton.vue";
import BankAccountPicker from "@/modules/invoiceFlow/components/BankAccountPicker.vue";
import InvoiceWizard from "@/modules/invoiceFlow/components/InvoiceWizard.vue";
import DownPaymentRelatedExtOrderTable from "../components/DownPaymentRelatedExtOrderTable.vue";
import SetIncorrectInvoice from "@/modules/invoiceFlow/components/SetIncorrectInvoice.vue";
import YellowConfirmation from "@/components/YellowConfirmation.vue";
import StatusChip from "@/components/StatusChip.vue";

import Vue from "vue";
import eventBus from "@/services/eventBus";
import store, {
  DownPaymentStore,
  DownPaymentValidator,
} from "../services/store";
import permStore, { PermissionStore } from "@/services/permissions";
import GeneralIngoingInvoiceEditModelApi from "@/modules/generalIngoingInvoiceEditModel/services/GeneralIngoingInvoiceEditModelApi";
import GeneralIngoingInvoiceApi from "@/modules/generalIngoingInvoice/services/GeneralIngoingInvoiceApi";
import PartnerApi from "@/modules/partner/services/PartnerApi";
import { PermitStatuses } from "@/modules/generalIngoingInvoice/models/PermitStatus";
import { DownPayment, DownPaymentItem } from "../models";
import PaymentModeDisplay from "@/modules/paymentMode/models/PaymentModeDisplay";
import PartBank from "@/modules/cashPoint/models/PartBank";
import Partner from "@/modules/partner/models/Partner";
import DownPaymentRelatedItems from "../components/DownPaymentRelatedItems.vue";
import DownPaymentOtherItemSearchTab from "../components/DownPaymentOtherItemSearchTab.vue";
import ExpandableSection from "@/components/ExpandableSection.vue";
import remitStore, {
  BankRemitStore,
} from "@/modules/bankRemit/services/BankRemitStore";
import DownPaymentAcceptState from "./DownPaymentAcceptState.vue";
import DownPaymentAcceptors from "./DownPaymentAcceptors.vue";
import auth from "@/services/auth";
import DownPaymentDocumentAttachments from "../components/DownPaymentDocumentAttachments.vue";
import configuration, { loadConfiguration } from "@/models/Configuration";
import Attachment, {
  attachmentDefaultProps,
} from "@/modules/generalIngoingInvoice/models/Attachment";
import moneyFormat from "@/filters/money-format";
import taxStore, { TaxStore } from "@/modules/taxes/services/TaxStore";
import { PrecisionType, roundMoney } from "@/services/prices";
import TaxAndVatItem, { TaxAndVatItemItemDefaultProps } from "@/modules/generalIngoingInvoice/models/TaxAndVatItem";

interface Data {
  store: DownPaymentStore;
  permStore: PermissionStore;
  remitStore: BankRemitStore;
  itemPickerVisible: boolean;
  partner: Partner | undefined;
  paymentModes: PaymentModeDisplay[];
  selectedBankAccount: PartBank | undefined;
  partBanks: PartBank[];
  pdpLoaded: boolean;
  inProcess: boolean;
  approvedJustNow: boolean;
  permittedJustNow: boolean;
  taxStore: TaxStore;
  showSecondPart: boolean;
}

interface Computed {
  getPrecision: number;
  vatDiffersFromTaxDegree: string;
  errorMessage: string;
  warningMessage: string;
  disabled: boolean;
  form: any;
  partnerName: string;
  partnerNative: boolean;
  fullSizeSwitchTooltip: string;
}

export default ConfirmationMixin.extend<Data, {}, Computed, {}>({
  components: {
    DownPaymentCreatePage,
    MoneyInput,
    ValidationError,
    BankAccountPicker,
    IconButton,
    InvoiceWizard,
    DownPaymentRelatedExtOrderTable,
    SetIncorrectInvoice,
    StatusChip,
    YellowConfirmation,
    DownPaymentRelatedItems,
    DownPaymentOtherItemSearchTab,
    ExpandableSection,
    DownPaymentAcceptState,
    DownPaymentAcceptors,
    DownPaymentDocumentAttachments,
  },

  data: (): Data => ({
    store,
    permStore,
    remitStore,
    taxStore,
    itemPickerVisible: false,
    partner: undefined,
    paymentModes: [],
    selectedBankAccount: undefined,
    partBanks: [],
    pdpLoaded: false,
    inProcess: false,
    approvedJustNow: false,
    permittedJustNow: false,
    showSecondPart: true,
  }),

  mixins: [ConfirmationMixin],

  async beforeMount() {
    const invoiceID = Number(this.$route.params.invoiceId) || 0;

    this.store.init();

    if (invoiceID > 0) await this.store.loadDownPayment(invoiceID);
    else {
      this.store.attachments = [];
      this.store.attachment = attachmentDefaultProps;
    }
    /* this.itemPickerVisible = invoiceID <= 1; */

    const partnerID =
      Number(this.$route.params.partnerId) || this.store.downPayment.partner;

    await Promise.all([
      PartnerApi.get(partnerID).then((data) => {
        this.partner = data;
        if (invoiceID < 1 && !!data.supplierDefaultCurrency){
            this.store.downPayment.currency = data.supplierDefaultCurrency;
        }
      }),
      GeneralIngoingInvoiceApi.getPaymentModes().then(
        (data) => (this.paymentModes = data)
      ),
      PartnerApi.getPartBanksWithTech(partnerID).then(
        (data) => (this.partBanks = data)
      ),
      this.store.loadTaxes(),
      this.store.loadTaxAndVatItems().then(() => {
        this.store.downPayment.tax = this.store.taxAndVatItems.find(x => x.id == this.store.downPayment.taxID 
          && x.vatItemID == this.store.downPayment.vatItem) || {...TaxAndVatItemItemDefaultProps};

        this.store.downPayment.invoiceItems.forEach(x => {
          x.taxAndVatItem = this.store.taxAndVatItems.find(y => y.id == x.tax?.id 
          && y.vatItemID == x.vatItem) || {...TaxAndVatItemItemDefaultProps};
        });

        console.log(this.store.downPayment.invoiceItems);
      }),
      this.store.loadUnits(),
      this.remitStore.loadCurrencies(),
      this.store
        .loadExtOrdersData(partnerID, invoiceID)
        .then(() => (this.pdpLoaded = true)),
    ]).then((x) => {
      return x;
    });

    await this.taxStore.loadTaxes();
    await this.store.loadAcceptorsList(invoiceID);

    if (this.store.downPayment.partner != partnerID) {
      this.store.downPayment = new DownPayment({
        ...this.store.downPayment,
        partner: partnerID,
      });
    }

    if (configuration.currencyDigits.length < 1) loadConfiguration();

    this.store.downPayment.paymentMode =
      this.store.downPayment.paymentMode > 1
        ? this.store.downPayment.paymentMode
        : this.paymentModes.find(
            (x) => x.id == this.store.downPayment.paymentMode
          )?.id ??
          this.paymentModes.find((x) => x.name.includes("Átutal"))?.id ??
          this.paymentModes[0]?.id ??
          1;

    this.selectedBankAccount = this.partBanks.find(
      (x) => x.id == this.store.downPayment.bankAccount
    );
    if (!this.selectedBankAccount && this.partBanks.length > 0) {
      this.selectedBankAccount = this.partBanks[0];
      this.store.downPayment.bankAccount = this.partBanks[0].id;
    }
  },

  mounted() {
    this.form.validate();
  },

  methods: {
    onExchangeRateChange(rate: number) {
      this.store.downPayment.exchangeRate = rate;
      this.store.nettoChanged();
    },

    switchFullPage() {
      if (!this.store.isFullPage) {
        this.showSecondPart = false;
        setTimeout(() => {
          this.store.isFullPage = true;
        }, 300);
      } else {
        this.store.isFullPage = false;
        setTimeout(() => {
          this.showSecondPart = true;
        }, 300);
      }
    },

    calculateTax(e: number) {
      this.store.downPayment.vat = e - this.store.downPayment.netto;
    },

    async save() {
      const existingId = Number(this.$route.params.invoiceId);
      const note = !existingId ? "rögzítés" : "mentés";

      if (this.store.downPayment.invoiceItems.length < 1) {
        this.store.downPayment.invoiceItems.push(
          new DownPaymentItem({
            invoiceID: this.store.downPayment.id,
            productID: 1,
            gpartsID: 1,
            isFreeWordItem: true,
            productName:
              "Előleg tétel " +
              (this.store.downPayment.invoiceItems.length + 1),
            serviceName:
              "Előleg tétel " +
              (this.store.downPayment.invoiceItems.length + 1),
            currency: this.store.downPayment.currency,
            exchangeRate: this.store.downPayment.exchangeRate,
            netto: this.store.downPayment.netto,
            brutto: this.store.downPayment.total,
            vat: this.store.downPayment.total - this.store.downPayment.netto,
            quantity: 1,
            tax: taxStore.taxes.find(
              (x) => x.id === this.store.downPayment.taxID
            ),
            unit: {id: 2, name: "DB"},
            vatItem: this.store.downPayment.vatItem,
            taxAndVatItem: this.store.downPayment.tax,
          })
        );
      }

      

      await this.store.createOrUpdate().then(async (invoiceID) => {
        if (!existingId) this.$router.push(`/down-payment/edit/${invoiceID}`);
        /* await this.store.loadDownPayment(Number(this.$route.params.invoiceID));
        await this.store.loadPreDownPayment(partnerID, Number(this.$route.params.invoiceID)); */
        eventBus.$emit("success", `Sikeres ${note}!`);
        this.itemPickerVisible = false;

        if (!existingId) {
          await this.store.uploadGENIPicture(this.store.downPayment.id);
          await this.store.loadGENIPicture(this.store.downPayment.id);
        }
      });
    },

    cancel() {
      this.$router.push(`/down-payment`);
    },

    async generatePayment() {
      await this.store.createOrUpdate().then(async () => {
        await this.store.generatePayment().then(async () => {
          await this.store.loadDownPayment(this.store.downPayment.id);
          await this.store.loadPreDownPayment(
            this.store.downPayment.partner,
            this.store.downPayment.id
          );
          eventBus.$emit("success", `Sikeres Fizetés generálás!`);
        });
      });
    },

    async setPayable() {
      if (!this.permStore.hasPermitting()) return;

      if (this.store.acceptorRequests.length > 0 && !this.store.acceptorRequests.every((x) => x.accepted)) {
        eventBus.$emit("error", "További jóváhagyás szükséges!");
        return;
      }

      this.inProcess = true;
      eventBus.$emit("busy-message", true);
      try {
        await this.store.createOrUpdate().then(async () => {
          await GeneralIngoingInvoiceEditModelApi.updatePermitStatuses(
            [this.store.downPayment.id],
            PermitStatuses.Payable
          ).then(() => {
            eventBus.$emit("success", "Sikeres Utalványozás státusz állítás!");
            this.store.downPayment.isPayable = true;
            this.store.downPayment.permitStatusID = PermitStatuses.Payable;
            this.store.downPayment.permitStatus = "Fizethető";
            this.$invoiceTaskUpdateHub.send("InvoiceTaskUpdated");
          });
        });
        await this.store.loadDownPayment(Number(this.$route.params.invoiceId));
        this.permittedJustNow = true;
      } catch (error) {
        eventBus.$emit("error", "Hiba történt utalványozás közben: " + error);
      } finally {
        eventBus.$emit("busy-message", false);
        this.inProcess = false;
      }
      await this.store.loadDownPayment(Number(this.$route.params.invoiceId));

      this.store.downPayment.tax = this.store.taxAndVatItems.find(x => x.id == this.store.downPayment.taxID 
                    && x.vatItemID == this.store.downPayment.vatItem) || {...TaxAndVatItemItemDefaultProps};
    },

    async setIncorrect() {
      const deletePreDownPayment =
        this.store.preDownPayment &&
        (await this.confirm(
          "A számlához Előlegbekérő értesítő előjegyzés tartozik, töröljük?",
          "delete-pre-confrimation"
        ));

      await this.store.setIncorrect(deletePreDownPayment).then(() => {
        eventBus.$emit("success", `Hibás rögzítés státusz frissítve.`);
        this.$router.push(`/down-payment`);
      });
    },

    async setApproved() {
      if (store.downPayment.isApproved) {
        eventBus.$emit("error", "Az előlegbekérő már jóváhagyott!");
        return;
      }
      if (store.downPayment.isIncorrect) {
        eventBus.$emit("error", "Az előlegbekérő már elutasított!");
        return;
      }

      try {
        this.inProcess = true;
        eventBus.$emit("busy-message", true);
        let acceptorUsers = "";
        store.acceptorUsers
          .map((x) => x.name)
          .forEach(
            (x) =>
              (acceptorUsers +=
                x +
                (acceptorUsers.indexOf(x) < acceptorUsers.length ? ", " : "!"))
          );

        if (store.acceptorUsers.map((x) => x.name).includes(auth.userName)) {
          await this.store.createOrUpdate().then(async () => {
            await this.store
              .approveDownPayment(Number(this.$route.params.invoiceId))
              .then(async () => {
                await this.store.loadDownPayment(this.store.downPayment.id);
                await this.store.loadPreDownPayment(
                  this.store.downPayment.partner,
                  this.store.downPayment.id
                );
                await this.store.loadAcceptorRequests(
                  Number(this.$route.params.invoiceId)
                );
                await this.store.loadAcceptorsList(
                  Number(this.$route.params.invoiceId)
                );
                if (this.store.acceptorRequests.some((x) => x.accepted)) {
                  await this.store.setApproved();
                }

                this.store.downPayment.tax = this.store.taxAndVatItems.find(x => x.id == this.store.downPayment.taxID 
                    && x.vatItemID == this.store.downPayment.vatItem) || {...TaxAndVatItemItemDefaultProps};

                eventBus.$emit("success", "Sikeres Jóváhagyás!");
                this.approvedJustNow = true;
              });
          });

          await this.store.loadDownPayment(this.store.downPayment.id);

          this.store.downPayment.tax = this.store.taxAndVatItems.find(x => x.id == this.store.downPayment.taxID 
                    && x.vatItemID == this.store.downPayment.vatItem) || {...TaxAndVatItemItemDefaultProps};
        } else
          eventBus.$emit(
            "error",
            "Ön nem hagyhatja jóvá az előlegbekérőt! Jóváhagyók: " +
              acceptorUsers
          );
      } catch (error) {
        console.log(error);
      } finally {
        this.inProcess = false;
        eventBus.$emit("busy-message", false);
      }
    },

    setSelectedBankAccount() {
      if (this.store.downPayment.paymentMode == 2) {
        this.selectedBankAccount = this.partBanks.find((x) => x.id == 1);
        this.store.downPayment.bankAccount = 1;
        return;
      } else if (this.store.downPayment.bankAccount > 1) {
        return;
      }
      const filtered = this.partBanks.filter((x) => x.id > 1);
      if (filtered.length > 0) {
        this.selectedBankAccount = filtered[0];
        this.store.downPayment.bankAccount = this.selectedBankAccount.id;
      }
    },

    onBankAccountChange(partBank: PartBank) {
      this.selectedBankAccount = partBank;
      this.store.downPayment.bankAccount = partBank.id;
    },

    bruttoChanged() {
      this.store.bruttoChanged();
    },

    nettoChanged() {
      this.store.nettoChanged();
    },

    taxChanged(){
      if (this.store.downPayment.tax && this.store.downPayment.tax.id > 1){
        this.store.downPayment.taxID = this.store.downPayment.tax.id;
        this.store.downPayment.vatItem = this.store.downPayment.tax.vatItemID;

        this.store.nettoChanged();
      }
    }
  },

  computed: {
    fullSizeSwitchTooltip() {
      return `Teljes képernyős mód ${
        !this.store.isFullPage ? "be" : "ki"
      }kapcsolása`;
    },

    getPrecision() {
      const curr = this.store.downPayment.currency;
      const digits = configuration.operel
        ? 2
        : configuration.getCurrDigits(curr)?.geniHeadDigits;
      return digits ?? 0;
    },

    vatDiffersFromTaxDegree() {
      const tax =
        this.store.taxes.find((x) => x.id == this.store.downPayment.taxID)
          ?.degree ?? 27;

      const def =
        roundMoney(
          this.store.downPayment.vat,
          this.store.downPayment.currency,
          undefined,
          PrecisionType.geniHead
        ) >
        roundMoney(
          this.store.downPayment.netto * (tax / 100),
          this.store.downPayment.currency,
          undefined,
          PrecisionType.geniHead
        )+0.9 || roundMoney(
          this.store.downPayment.vat,
          this.store.downPayment.currency,
          undefined,
          PrecisionType.geniHead
        ) <
        roundMoney(
          this.store.downPayment.netto * (tax / 100),
          this.store.downPayment.currency,
          undefined,
          PrecisionType.geniHead
        )-0.9;

      if (def) {
        return `A számla fej nettó érték felül írva kerekítési eltérés vagy a tételek eltérő áfakulcsa miatt`;
      } else return ``;
    },

    errorMessage(): string {
      console.log(this.store.downPayment);
      const validator = new DownPaymentValidator(
        this.store.downPayment,
        this.store.selectedPreDownPayments,
        this.taxStore.taxes
      );
      const msg = validator.error();
      return msg;
    },

    warningMessage(): string {
      const validator = new DownPaymentValidator(
        this.store.downPayment,
        this.store.selectedPreDownPayments,
        this.taxStore.taxes
      );
      const msg = validator.warning();
      return msg;
    },

    
    disabled(): boolean {
      return (
        this.errorMessage != "" ||
        (this.store.downPayment.hasPayment && this.store.downPayment.preStatusID != 9) || //Ha nem állították a státuszt vissza "Elfogadott - feldolgozás alatt" -ra.
        this.store.downPayment.isIncorrect ||
        this.inProcess
      );
    },

    // eslint-disable-next-line
    form(): Vue | any {
      return this.$refs.form as Vue;
    },

    partnerName(): string {
      return this.partner ? this.partner.name : "";
    },

    partnerNative(): boolean {
      return this.partner?.native ?? false;
    },
  },
});
