











































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import Partner, { partnerDefaultProps } from "../models/Partner";
import store, { PartnerStore } from "../services/PartnerStore";
import cStore, { CityStore } from "../../city/services/CityStore";
import dtStore, { DocumentTemplateStore } from "../../documentTemplate/services/DocumentTemplateStore";
import iStore, { InvoiceFlowStore } from "../../invoiceFlow/services/InvoiceFlowStore";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import PartnerEuTax from "../models/PartnerEuTax";
import configuration, { AppConfiguration, loadConfiguration } from "../../../models/Configuration";
import QuickSearchItem from "../models/QuickSearchItem";
import City, { cityDefaultProps } from "@/modules/city/models/City";
import { partnerPriSuplDefaultProps } from "../models/PartnerPriSupl";
import ConfirmationBox, { Confirmation } from "@/components/ConfirmationBox.vue";
import eventBus from "@/services/eventBus";
import SpecialTax from "../models/SpecialTax";

interface Data {
  store: PartnerStore;
  cStore: CityStore;
  dtStore: DocumentTemplateStore;
  iStore: InvoiceFlowStore;

  sameAddress: boolean;

  taxAccountFirst: string;
  taxAccountSecond: string;
  taxAccountThird: string;

  valid: boolean;

  euTaxCreating: boolean;
  newEuTax: string;
  partnerEuTaxes: PartnerEuTax[];
  partnerEuTaxesToDelete: number[];
  tempId: number;
  configuration: AppConfiguration;
  debounceTimer: any;

  bisnodeVisible: boolean;
  columns: any;

  validationError: string;
  formSize: number;

  confirmationVisible: boolean;
  confirmation: Confirmation | null; // egyelőre 1db confirmationra van szükség -> ha kell több is, akkor a notice modulban van rá példa
  navigateToInvoice: boolean;
}

interface Methods {
  specTaxHandler(): void;
  setExpanded(data: QuickSearchItem): void;
  saveAction(): void;
  performSave(): void;
  cancel(): void;

  loadPartnerData(data: QuickSearchItem): void;
  sendBisnode(): void;
  clickHandler(p: PartnerEuTax): void;
  setInvoiceCity(): void;
  setSelectedInvoiceCity(selectedCity: City): void;
  updateCountryName(): void;
  filterEuTaxHandler(item: PartnerEuTax): void;
  saveCreatePartnerTaxAccount(): void;
  checkNewEuTaxValid(): boolean;
  cancelCreatePartnerTaxAccount(): void;
  clearSearch(): void;
  jumpToNext(currItem: string): void;
  findCityByPostCodeHandler(): void;
  findInvoiceCityByNameHandler(event: any): void;
  findCorCityHandler(): void;
  sameAddressHandler(): void;

  validate(): void;
  acceptCB(): void;
  declineCB(): void;
  resetConfirmation(): void;
}

interface Computed {
  inCreateMode: boolean;
  inEditMode: boolean;
  items: QuickSearchItem[];
  filteredSpecTaxes: SpecialTax[];
}

export default Vue.extend<Data, Methods, Computed, {}>({
  components: {
    NumericTextBox,
    ConfirmationBox,
  },

  props: {
    editMode: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data(): Data {
    return {
      store,
      cStore,
      dtStore,
      iStore,

      sameAddress: false,

      taxAccountFirst: "",
      taxAccountSecond: "",
      taxAccountThird: "",

      valid: true,

      euTaxCreating: false,
      newEuTax: "",
      partnerEuTaxes: [],
      partnerEuTaxesToDelete: [],
      tempId: 0,
      configuration,
      debounceTimer: null,

      bisnodeVisible: false,
      columns: [
        {
          cell: "expandDetailTemplate",
          width: "60px",
        },
        { cell: "commandTemplate", width: "60px" },
        {
          cell: "nameTemplate",
          title: "Név",
          headerClassName: "center_text",
          // width: "250px",
        },
        {
          field: "status",
          title: "Állapot",
          className: "center_text",
          headerClassName: "center_text",
          width: "130px",
        },
        {
          width: 0, // kitolja az utolso elotti oszlopot teljesbe
        },
      ],

      validationError: "",

      formSize: 0,

      confirmationVisible: false,
      confirmation: null,
      navigateToInvoice: false,
    };
  },

  async beforeMount() {
    window.scrollTo(0, 0);

    await Promise.all([
      this.store.loadUsers(),
      this.store.loadPartnerTypes(),
      this.store.loadPartnerStatuses(),
      this.store.loadSpecialTaxes(),
      this.store.loadPayModes(),
      this.store.loadCompanyForms(),
      this.store.loadPayClasses(),
      this.store.loadPartnerGroups1(),
      this.store.loadPartnerGroups2(),
      this.store.loadCurrencies(),
      this.store.loadCountries(),
      this.store.setWorkflowIsActive(),
      this.store.loadWorkflows(),
      this.cStore.loadCities(),
      this.dtStore.loadDocumentTemplates(),
      this.iStore.loadPartnerTaxes(),
      loadConfiguration(),
    ]);

    this.partnerEuTaxes = [...this.store.partnerEuTaxes];

    /// csak új partner esetén
    if (this.inCreateMode) {
      this.formSize = 8;

      store.partner = { ...partnerDefaultProps };
      store.partnerPriSupl = { ...partnerPriSuplDefaultProps };

      //extendedPartnerInfo
      if (configuration.partner.isExtendedPartnerInfoEnabled) {
        await this.store.loadPrePartnerNames();
        await this.store.loadStreetTypes();
      }

      if (this.$route.params.source) {
        this.confirmation = new Confirmation("Az új partnert beállítja az iktatni kívánt bejövő számla szállítójának?");
      }
    }

    /// csak meglévő partner esetén
    if (this.inEditMode) {
      this.formSize = 12;
      this.taxAccountFirst = this.store.partner.taxAccount.substring(0, 8);
      this.taxAccountSecond = this.store.partner.taxAccount.substring(9, 10);
      this.taxAccountThird = this.store.partner.taxAccount.substring(11, 13);
      //extendedPartnerInfo
      if (configuration.partner.isExtendedPartnerInfoEnabled) {
        await this.store.loadPrePartnerNames();
        await this.store.loadStreetTypes();
      }
    }

    /// ha minden betöltött
    this.validate();
  },

  computed: {
    filteredSpecTaxes(){
      if (this.store.partner.native && this.store.partner.specTax) {
        return this.store.specialTaxes.filter((x: SpecialTax) => x.name == "Alanyi mentes")
      }
      return this.store.specialTaxes.filter((x: SpecialTax) => x.name == "Közösségen belüli" || x.name == "Közösségen kívüli")
    },  

    inCreateMode() {
      return this.$props.editMode ? false : true;
    },

    inEditMode() {
      return this.$props.editMode ? true : false;
    },

    items() {
      return this.store.quickSearchItems;
    },
  },

  methods: {
    specTaxHandler() {
      if (!this.store.partner.specTax) {
        this.store.partner.specTaxId = 1;
        this.store.partner.specTaxName = "-";
      }
    },

    setExpanded(data) {
      this.store.setExpanded(data);
    },

    saveAction() {
      if (this.confirmation) {
        this.confirmationVisible = true;
        return;
      }

      this.performSave();
    },

    loadPartnerData(data) {
      this.store.partner.name = data.name;
      this.store.partner.corName = data.name;
      this.store.partner.postCode = data.address.zip;
      this.store.partner.countryName = data.address.country;
      this.store.partner.invoiceCity = data.address.city;
      this.store.partner.invoiceAddress = data.address.streetNo;
      this.taxAccountFirst = data.taxNbr.slice(0, 8);
      this.taxAccountSecond = data.taxNbr.slice(8, 9);
      this.taxAccountThird = data.taxNbr.slice(9, 11);

      // if (!this.configuration.partner.isExtendedPartnerInfoEnabled) {
      // }
    },

    async sendBisnode() {
      await this.store.sendBisnode();
      this.bisnodeVisible = true;
    },

    clickHandler(p) {
      this.store.partner.euTaxAccount = p.taxAccount;
    },

    setInvoiceCity() {
      const foundCity = this.cStore.filteredCities.find((city: City) => city.name === this.store.partner.invoiceCity);

      if (foundCity !== undefined) {
        this.store.partner.invoiceCity = foundCity.name;

        if (foundCity.postCode.length > 0) {
          this.store.partner.postCode = foundCity.postCode;
        }
        this.cStore.filteredCities = [];
      }
    },

    setSelectedInvoiceCity(selectedCity) {
      this.store.partner.invoiceCity = selectedCity.name;

      const foundCity = this.cStore.filteredCities.find((city: City) => city.name === this.store.partner.invoiceCity);

      if (foundCity !== undefined) {
        this.store.partner.invoiceCity = foundCity.name;

        if (foundCity.postCode.length > 0) {
          this.store.partner.postCode = foundCity.postCode;
        }
        this.cStore.filteredCities = [];
      }
    },

    updateCountryName() {
      const foundCountry = this.cStore.filteredCities.find((city: City) => {
        return city.name === this.store.partner.invoiceCity;
      })?.country;

      if (foundCountry) {
        this.store.partner.countryName = foundCountry;
      } else {
        this.store.partner.countryName = "";
      }
    },

    filterEuTaxHandler(item) {
      this.partnerEuTaxes = this.partnerEuTaxes.filter((x) => x.taxAccount != item.taxAccount);
      this.partnerEuTaxes = [...this.partnerEuTaxes];
    },

    checkNewEuTaxValid(){
      const regex = /^[A-Z]{2}[0-9A-Z]{2,13}$/;
      return regex.test(this.newEuTax);
    },

    saveCreatePartnerTaxAccount() {
      if (this.newEuTax.length > 1) {
        if (!this.checkNewEuTaxValid()) {
          eventBus.$emit("error", "Hibás formátum!");
          return;
        }
        this.partnerEuTaxes.unshift({
          id: this.tempId++,
          partner: 1,
          taxAccount: this.newEuTax,
        });
        this.partnerEuTaxes = [...this.partnerEuTaxes];

        this.newEuTax = "";
        this.euTaxCreating = false;
      }
    },

    cancelCreatePartnerTaxAccount() {
      this.newEuTax = "";
      this.euTaxCreating = false;
    },

    clearSearch() {
      if (this.store.partner.docTemplate != "-") {
        this.store.partner.docTemplate = "-";
      }
    },

    jumpToNext(currItem) {
      if (currItem == "taxAccountFirst" && this.taxAccountFirst.length == 8) {
        (this.$refs["taxAccountSecond"] as any).focus();
      } else if (currItem == "taxAccountSecond" && this.taxAccountSecond.length == 1) {
        (this.$refs["taxAccountThird"] as any).focus();
      }
    },

    // async findCityByPostCodeHandler() {
    //   if (this.store.partner.postCode.length >= 4) {
    //     await this.cStore.loadCityByPostCode(+this.store.partner.postCode);
    //     this.store.partner.invoiceCity = this.cStore.city.name;
    //   }
    // },

    findCityByPostCodeHandler() {
      if (this.store.partner.postCode.length >= 4) {
        const found = this.cStore.cities.find((x) => x.postCode == this.store.partner.postCode);
        if (found) {
          this.store.partner.invoiceCity = found.name;
        }
      }
    },

    async findInvoiceCityByNameHandler(event) {
      this.store.partner.invoiceCity = event.target.value;

      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }

      if (this.store.partner.invoiceCity.length >= 3) {
        this.debounceTimer = setTimeout(async () => {
          await this.cStore.filterCitiesByName(this.store.partner.invoiceCity);
        }, 400);
      } else {
        this.cStore.filteredCities = [];
      }
    },

    async findCorCityHandler() {
      if (this.store.partner.corPostCode.length >= 4) {
        await this.cStore.loadCityByPostCode(+this.store.partner.corPostCode);
        this.store.partner.corCity = this.cStore.city.name;
      } else this.store.partner.corCity = "";
    },

    sameAddressHandler() {
      if (!this.configuration.partner.isExtendedPartnerInfoEnabled) {
        this.store.partner.corCity = this.store.partner.invoiceCity;
        this.store.partner.corPostCode = this.store.partner.postCode;
        this.store.partner.corAddress = this.store.partner.invoiceAddress;
      } else {
        this.store.partner.corCity = this.store.partner.invoiceCity;
        this.store.partner.corPostCode = this.store.partner.postCode;
        this.store.partner.corStreet = this.store.partner.invStreet;
        this.store.partner.corStreetTypeName = this.store.partner.invStreetTypeName;
        this.store.partner.corStreetNum = this.store.partner.invStreetNum;
        this.store.partner.corBuilding = this.store.partner.invBuilding;
        this.store.partner.corFloor = this.store.partner.invFloor;
        this.store.partner.corDoor = this.store.partner.invDoor;
      }
    },

    async performSave() {
      //új
      if (this.inCreateMode) {
        ///mentés, kapcsolatok beálítása
        const today = new Date();
        this.store.partner.insDate = today;
        this.store.partner.insTime = today;
        this.store.partner.taxAccount = this.taxAccountFirst + "-" + this.taxAccountSecond + "-" + this.taxAccountThird;

        const ret = (await this.store.createPartner()) ?? 0;

        if (this.configuration.partner.isExtendedPartnerInfoEnabled && ret > 0) {
          await this.store.createExtendedPartnerInfo();
        }

        if (ret > 0) {
          if (this.partnerEuTaxes != null) {
            this.partnerEuTaxes.forEach((element) => {
              element.partner = ret;
            });
            await this.store.createPartnerEuTaxes(this.partnerEuTaxes);
          }

          this.store.partnerPriSupl.partner = ret;
          await this.store.createPartnerPriSupl();
        }

        ///felületi módosítások
        this.sameAddress = false;

        //kanri hívása
        if (this.configuration.sysParams.isKanriEnabled) {
          await this.store.kanriSave(ret);
        }

        ///navigáció
        if (ret > 0) { 
          if (this.navigateToInvoice) {
            this.$router.push(`/general-incoming-invoice/flow/edit/${Number(this.$route.params.source)}/1/${ret}`);
          } else {
            this.$router.push(`/partners/${ret}`);
          }
        }
      }

      //meglévő
      if (this.inEditMode) {
        try {
          eventBus.$busy(true);
          ///frissítés

          this.store.partner.taxAccount =
            this.taxAccountFirst + "-" + this.taxAccountSecond + "-" + this.taxAccountThird;
          await this.store.updatePartner(this.store.partner);

          if (this.partnerEuTaxes) {
            await this.store.createPartnerEuTaxes(this.partnerEuTaxes);
          }
          if (this.partnerEuTaxesToDelete) {
            await this.store.deletePartnerEuTax(this.partnerEuTaxesToDelete);
          }

          if (this.configuration.partner.isExtendedPartnerInfoEnabled) {
            await this.store.updateExtendedPartnerInfo();
          }

          await this.store.savePartnerPriSupl(this.store.partnerPriSupl, +this.$route.params.partnerId);

          ///felületi módosítások
          this.partnerEuTaxesToDelete = [];
          this.partnerEuTaxes = [];

          this.store.partnersBackup.splice(0); // le kell frissíteni a backupot
          this.sameAddress = false;
          await this.store.loadPartnerPriSupl(+this.$route.params.partnerId);

          if (this.configuration.sysParams.isKanriEnabled) {
            await this.store.kanriSave(+this.$route.params.partnerId);
          }

          await Promise.all([
            this.store.loadPartner(+this.$route.params.partnerId),
            this.store.loadPartBanks(+this.$route.params.partnerId),
            this.store.loadPartSuppliers(+this.$route.params.partnerId),
            this.store.loadPartEmployee(+this.$route.params.partnerId),
            this.store.loadPartCust(+this.$route.params.partnerId),
            this.store.loadPartPersons(+this.$route.params.partnerId),
            this.store.loadPartnerPriSupl(+this.$route.params.partnerId),
            this.cStore.loadCities(),
          ]);
        } catch (error) {
          console.log(error);
        } finally {
          ///navigáció
          this.store.inEdit = false; // a partner szerkesztő felületen ez el is rejti a komponenst
          eventBus.$busy(false);
        }
      }
    },

    async cancel() {
      if (this.inCreateMode) {
        this.$router.go(-1);
      }

      if (this.inEditMode) {
        const foundPartner = this.store.partnersBackup.find((x) => x.id === this.store.partner.id);

        if (foundPartner) {
          this.store.partner = foundPartner;
        }

        this.store.partnersBackup = this.store.partnersBackup.filter((x) => x.id !== this.store.partner.id);

        // this.store.partnerEuTaxes = this.store.partnerEuTaxes.filter(
        //   (ar) => !this.partnerEuTaxes.find((rm) => rm.taxAccount == ar.taxAccount)
        // );
        this.partnerEuTaxes = [];
        this.euTaxCreating = false;
        await this.store.loadPartnerPriSupl(this.store.partner.id);
        this.sameAddress = false;
        this.$emit("cancel-partner-edit");
      }
    },

    validate() {
      if (this.store.partner.name.length === 0) {
        this.validationError = "A partner nevének kitöltése kötelező!";
        this.valid = false;
        return;
      }

      if (this.store.partner.corName.length === 0) {
        this.validationError = "A partner levelezési nevének kitöltése kötelező!";
        this.valid = false;
        return;
      }

      if (this.store.partner.native && this.store.partner.companyForm === "-") {
        this.validationError = "A vállalkozási forma kitöltése kötelező!";
        this.valid = false;
        return;
      }

      if (store.partner.specTax && this.store.partner.specTaxId <= 1) {
        this.validationError = "A speciális adózási típus kitöltése kötelező!";
        this.valid = false;
        return;
      }

      if (configuration.partner.isExtendedPartnerInfoEnabled) {
        if (
          [
            this.store.partner.postCode,
            this.store.partner.invoiceCity,
            this.store.partner.invStreet,
            this.store.partner.invStreetTypeName,
            this.store.partner.invStreetNum,
          ]
            .map((x) => x.length === 0)
            .includes(true)
        ) {
          this.validationError = "A számlázási cím kitöltése kötelező!";
          this.valid = false;
          return;
        }

        if (
          !this.sameAddress &&
          [
            this.store.partner.corPostCode,
            this.store.partner.corCity,
            this.store.partner.corStreet,
            this.store.partner.corStreetTypeName,
            this.store.partner.corStreetNum,
          ]
            .map((x) => x.length === 0)
            .includes(true)
        ) {
          this.validationError = "A levelezési cím kitöltése kötelező!";
          this.valid = false;
          return;
        }
      }

      if (!configuration.partner.isExtendedPartnerInfoEnabled) {
        if (
          [this.store.partner.postCode, this.store.partner.invoiceCity, this.store.partner.invoiceAddress]
            .map((x) => x.length === 0)
            .includes(true)
        ) {
          this.validationError = "A számlázási cím kitöltése kötelező!";
          this.valid = false;
          return;
        }

        if (
          !this.sameAddress &&
          [this.store.partner.corPostCode, this.store.partner.corCity, this.store.partner.corAddress]
            .map((x) => x.length === 0)
            .includes(true)
        ) {
          this.validationError = "A levelezési cím kitöltése kötelező!";
          this.valid = false;
          return;
        }
      }

      if (this.configuration.partner.isPartnerPhoneRequired) {
        if (this.store.partner.mobileNumber.length === 0) {
          this.validationError = "A mobilszám kitöltése kötelező!";
          this.valid = false;
          return;
        }
      }

      if (this.configuration.partner.isPartnerEmailRequired) {
        if (this.store.partner.email.length === 0) {
          this.validationError = "Az e-mail cím kitöltése kötelező!";
          this.valid = false;
          return;
        }
      }

      if (this.store.partner.status.length === 0) {
        this.validationError = "A státusz kitöltése kötelező!";
        this.valid = false;
        return;
      }

      if (this.store.partner.partnerType.length === 0) {
        this.validationError = "A típus kitöltése kötelező!";
        this.valid = false;
        return;
      }

      if (this.store.partner.payTerm === null || store.partner.payTerm < 0) {
        this.validationError = "Fizetési határidő kitöltése kötelező! (0 vagy nagyobb)";
        this.valid = false;
        return;
      }

      if (
        this.store.partner.native &&
        (this.taxAccountFirst.length !== 8 || this.taxAccountSecond.length !== 1 || this.taxAccountThird.length !== 2)
      ) {
        this.validationError = "A belföldi adószám kitöltése kötelező!";
        this.valid = false;
        return;
      }

      this.valid = true;
    },

    async acceptCB() {
      this.navigateToInvoice = true;
      this.performSave();
    },

    declineCB() {
      this.performSave();
    },

    resetConfirmation() {
      this.confirmationVisible = false;
      this.confirmation = null;
    },
  },

  beforeDestroy() {
    store.partner = { ...partnerDefaultProps };
    store.partnerPriSupl = { ...partnerPriSuplDefaultProps };
    this.resetConfirmation();
  },

  watch: {
    /// validate the form values
    "store.partner": {
      handler() {
        this.validate();
      },
      deep: true,
      immediate: true,
    },

    taxAccountFirst: function(newValue) {
      this.validate();
    },

    taxAccountSecond: function(newValue) {
      this.validate();
    },

    taxAccountThird: function(newValue) {
      this.validate();
    },

    "store.partnerPriSupl.deleted": function(newValue) {
      this.validate();
    },

    ///

    sameAddress(newSadress) {
      if (newSadress) {
        this.store.partner.corPostCode = this.store.partner.postCode;
        this.store.partner.corCity = this.store.partner.invoiceCity;
        this.store.partner.corAddress = this.store.partner.invoiceAddress;
        this.store.partner.corStreet = this.store.partner.invStreet;
        this.store.partner.corStreetTypeName = this.store.partner.invStreetTypeName;
        this.store.partner.corStreetNum = this.store.partner.invStreetNum;
        this.store.partner.corBuilding = this.store.partner.invBuilding;
        this.store.partner.corFloor = this.store.partner.invFloor;
        this.store.partner.corDoor = this.store.partner.invDoor;
      } else {
        this.store.partner.corPostCode = "";
        this.store.partner.corCity = "";
        this.store.partner.corAddress = "";
        this.store.partner.corStreet = "";
        this.store.partner.corStreetTypeName = "";
        this.store.partner.corStreetNum = "";
        this.store.partner.corBuilding = "";
        this.store.partner.corFloor = "";
        this.store.partner.corDoor = "";
      }
    },

    /// NOT extended config properties watchers
    "store.partner.invoiceCity": function(newInvC) {
      if (this.sameAddress) {
        this.store.partner.corCity = newInvC;
      }

      if (newInvC.length === 0) {
        this.store.partner.countryName = "MAGYARORSZÁG";
      }
    },

    "store.partner.postCode": async function(newPC) {
      if (this.sameAddress) {
        this.store.partner.corPostCode = newPC;
      }

      if (newPC.length >= 4) {
        try {
          await this.store.loadCountry(newPC);
        } catch (err) {
          // eventBus.$emit(
          //   "error",
          //   `Nincs hozzárendelve ország a megadott irányítószámhoz! (${this.store.partner.postCode})`
          // );
          console.error(err);
        }
      }
    },

    "store.partner.invoiceAddress": function(newIA) {
      if (this.sameAddress) {
        this.store.partner.corAddress = newIA;
      }
    },

    /// extended config properties watchers
    "store.partner.invStreet": function(newValue) {
      if (this.sameAddress) {
        this.store.partner.corStreet = newValue;
      }
    },

    "store.partner.invStreetTypeName": function(newValue) {
      if (this.sameAddress) {
        this.store.partner.corStreetTypeName = newValue;
      }
    },

    "store.partner.invStreetNum": function(newValue) {
      if (this.sameAddress) {
        this.store.partner.corStreetNum = newValue;
      }
    },

    "store.partner.invBuilding": function(newValue) {
      if (this.sameAddress) {
        this.store.partner.corBuilding = newValue;
      }
    },

    "store.partner.invFloor": function(newValue) {
      if (this.sameAddress) {
        this.store.partner.corFloor = newValue;
      }
    },

    "store.partner.invDoor": function(newValue) {
      if (this.sameAddress) {
        this.store.partner.corDoor = newValue;
      }
    },
  },
});
